<template>
  <div class="card hoverable minHeight shadow-sm h-100" @click="handleRouter">
    <div class="card-header text-start fw-bold">
      {{name}}
    </div>
    <div class="card-body">
      <p class="card-text text-start">{{ description }}</p>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
    }
  },
  props: {
    name: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    }
  },
  methods: {
    handleRouter() {
      this.$emit('route')
    }
  },
  
}
</script>
<style scoped>
.hoverable:hover {
  cursor: pointer;
  opacity: .7;
}

@media (max-width: 300px) {
  .minHeight {
    min-height: 195px;
  }
}

@media (min-width: 301px) {
  .minHeight {
    min-height: 100px;
  }
}
.card-header {
  background-color: transparent !important;
  border-bottom: none !important;
}
</style>