import { downloadFile, downloadFileQuestion } from '@/services/files/file.api.js';
import { getCustomTimestamp } from './utils';
// const typeDirectDownload = [
//   'application/msword',
//   'application/vnd.ms-excel',
//   'application/vnd.ms-powerpoint',
//   'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
//   'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
//   'application/vnd.openxmlformats-officedocument.presentationml.presentation',
//   'text/csv',
//   'audio/mpeg'
// ];

const handleDownload = async (fileName, fileUrl) => {
  const fileNameFormatted = fileName.trim().replaceAll(' ', '_');
  const fileLink = document.createElement('a');
  fileLink.href = fileUrl;
  fileLink.setAttribute('download', fileNameFormatted);
  document.body.appendChild(fileLink);
  fileLink.click();
}

const fileView = async (fileId) => {
  const { data } = await downloadFile(fileId);
  // const file = new Blob([data], { type: data.type });
  // const fileUrl = window.URL.createObjectURL(file);
  // if(!typeDirectDownload.includes(file.type)) return data;
  // handleDownload(fileName, fileUrl);
  return data;
};

const fileExamView = async (questionId) => {
  const { data } = await downloadFileQuestion(questionId);
  // const file = new Blob([data], { type: data.type });
  // const fileUrl = window.URL.createObjectURL(file);
  // if(!typeDirectDownload.includes(file.type)) return data;
  // handleDownload(fileName, fileUrl);
  return data;
}

const fileDownload = async (fileId, fileName) => {
  const { data } = await downloadFile(fileId);
  const file = new Blob([data], { type: data.type });
  const fileUrl = window.URL.createObjectURL(file);
  handleDownload(fileName, fileUrl);
}

const externalLinkRedirect = (resourceLink) => {
  const link = document.createElement('a');
  link.href = resourceLink;
  link.target = '_blank';
  document.body.appendChild(link);
  link.click();
}

const getMimetype = (signature) => {
  switch (signature) {
      case '89504E47':
          return ['image/png']
      case '47494638':
          return ['image/gif']
      case '25504446':
          return ['application/pdf']
      case 'FFD8FFDB':
      case 'FFD8FFE0':
          return ['image/jpeg']
      case 'D0CF11E0':
        return [
          'application/msword', 
          'application/vnd.ms-excel', 
          'application/vnd.ms-powerpoint'
        ]
      case '504B34':
        return [
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'application/vnd.openxmlformats-officedocument.presentationml.presentation',
          'application/zip'
      ]
      case '2A66696C':
        return ['text/plain']
      case '4944334':
        return ['audio/mpeg']
      case '00020':
        return ['video/mp4']
      case '75736572':
          return ['text/csv']
      default:
          return ['Unknown filetype']
  }
}

const downloadCSV = ({ data, filename = 'general_stats', showTimestamp = true }) => {
  if (!data) return;
  const blob = new Blob([data], { type: 'text/csv' });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  const timestamp = getCustomTimestamp();
  link.download = `${filename}${showTimestamp ? '_'+timestamp : ''}.csv`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export { fileView, fileDownload, externalLinkRedirect, getMimetype, downloadCSV, fileExamView };