import httpClient from "../httpClient";

const END_POINT = '/api/settings';

const config = { headers: {
  'Content-Type': 'multipart/form-data'
}}

const getSettings = () => httpClient.get(END_POINT);
const createNewSettings = (settings) => httpClient.post(END_POINT, settings, config);
const updateSettings = (id, settings) => httpClient.patch(`${END_POINT}/${id}`, settings, config);

export {
  getSettings,
  createNewSettings,
  updateSettings
}