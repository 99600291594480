import { encodeToBase64 } from '../../utils/utils';
import httpClient from '../httpClient';

const END_POINT = '/api/users';
const createUser = (user) => httpClient.post(END_POINT, user);
const createUserFromCode = (code) => httpClient.post(`${END_POINT}/authByCode`, { code }, {
  headers: {
    'message': encodeToBase64('Login')
  }
});
const userLogin = (credentials) => httpClient.post(`${END_POINT}/login`, credentials, {
  headers: {
    'user': encodeToBase64(credentials.username),
    'message': encodeToBase64('Login')
  }
});
const getUserById = (userId) => httpClient.get(`${END_POINT}/${userId}`);
const getTrainers = () => httpClient.get(`${END_POINT}/trainers`);
const getAllUsers = () => httpClient.get(END_POINT);
const getKeycloakLogoutURL = () => httpClient.get('/api/keycloak-logout-url');
const getShoulShowChat = () => httpClient.get('/api/should-show-chat');
const editUserById = (userId, user) => httpClient.patch(`${END_POINT}/${userId}`, user);
const deleteUserById = (userId) => httpClient.delete(`${END_POINT}/${userId}`);
const refreshToken = () => httpClient.get(`${END_POINT}/refreshToken`);
const revokeToken = (refreshToken) => httpClient.post(`${END_POINT}/logout`, { refreshToken }, {
  headers: {
    'message': encodeToBase64('Logout')
  }
});


export {
  createUser,
  userLogin,
  getUserById,
  getAllUsers,
  editUserById,
  deleteUserById,
  refreshToken,
  createUserFromCode,
  revokeToken,
  getTrainers,
  getKeycloakLogoutURL,
  getShoulShowChat
}