<template>
  <div class="d-flex flex-column justify-content-between vh-100">
    <div>
      <rd-navbar :title="$t('Settings (nav)')" />
      <div class="container bg-light my-3 p-3">
        <div class="row">
          <div class="col-12">
            <h2 class="ms-4 text-start">{{ $t('Add Language') }}</h2>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col-sm-12 col-md-6">
            <rd-input
              type="text"
              :placeholder="$t('Language name')" 
              :label="$t('Enter the language name')"
              required
              @input="(e) => this.languageName = e.target.value"
            />
          </div>
        </div>
        
        <div class="row mb-4">
          <div class="col-sm-12 col-md-6">
            <rd-input
              type="text"
              :placeholder="$t('Language abbreviation')" 
              :label="$t('Enter the language abbreviation')"
              required
              @input="(e) => this.languageAbbreviation = e.target.value"
            />
          </div>
        </div>

        <div class="row mb-4">
          <div class="col-sm-12 col-md-6">
            <table width="100%">
              <tr>
                <td>
                  <label class="labelText">
                    {{ $t('Language File') }}
                  </label>
                </td>
              </tr>
              <tr>
                <td>
                  <rd-input
                    type="file"
                    :label="$t('Choose a file')"
                    required
                    @input="handleFileSelect"
                  />
                </td>
              </tr>
            </table>
          </div>
        </div>

        <!-- Buttons -->
        <div class="my-3 row justify-content-end">
          <div class="col-lg-4 col-md-6 col-sm-6">
            <button class="btn btn-outline-primary me-3" @click="goBack">
              {{ $t('Dismiss') }}
            </button>
            <button
              :disabled="disableSaveButton"
              class="btn btn-primary" 
              @click="createThisLanguage"
            >
              <i data-bs-toggle="tooltip" data-bs-placement="right" title="Add Language" type="button" class="bi bi-plus-circle-fill"></i> {{ $t('Add Language (btn)') }}
            </button>
          </div>
        </div>

      </div>
    </div>
  <div>
    <rd-footer/>
  </div>
  </div>
</template>
<script>
import rdFooter from '../../components/rd-components/rd-footer.vue'
import RdNavbar from '../../components/rd-components/rd-navbar.vue'
import RdInput from '@/components/rd-components/rd-input.vue'
import router from '@/router'
import { createLanguage, validateLanguageAbbreviation } from '@/services/languages/language.api.js'

export default {
  data() {
    return {
      languageName: '',
      languageAbbreviation: '',
      languageResourceFile: null,
      disableSaveButton: true,
    }
  },
  mounted() {
    this.$watch(
      () => {
        return [this.languageName, this.languageAbbreviation, this.languageResourceFile]
      },
      ([languageName, languageAbbreviation, languageResourceFile]) => {
        if (languageName === '' || languageAbbreviation === '' || languageResourceFile === null) {
          this.disableSaveButton = true
        } else {
          this.disableSaveButton = false
        }
      }
    );
  },
  components: { rdFooter, RdNavbar, RdInput },
  methods: {
    goBack() {
      router.back()
    },
    async createThisLanguage() {
      try {
        const language = {
          languageName: this.languageName,
          languageAbbreviation: this.languageAbbreviation,
          languageResourceFile: this.languageResourceFile
        }
        let { data } = await validateLanguageAbbreviation(this.languageAbbreviation);
        if(!data) throw new Error('Language already exists');
        await createLanguage(language)
        this.$store.dispatch('dispatchNotification', {
          title: 'Success!',
          message: 'Language created.',
          type: 'success',
        });
        router.back()
      } catch (error) {
        this.$store.dispatch('dispatchNotification', {
          title: 'Error!',
          message: 'Language was not created.',
          type: 'error',
        });
        console.log(error)
      }
    },
    handleFileSelect(event) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = e => this.languageResourceFile = e.target.result;
      reader.readAsText(file);
    }
  },
  
}
</script>