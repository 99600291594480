import { createApp } from 'vue';
import DisableAutocomplete from 'vue-disable-autocomplete';
import Vuex from 'vuex';
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n';
import "bootstrap/dist/css/bootstrap.css";
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'bootstrap';
import '../scss/custom.scss'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import { quillEditor, Quill } from 'vue3-quill'
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
const fonts = Quill.import('formats/font');
fonts.whitelist.push('roboto');
Quill.register(fonts, true);
const app = createApp(App);
app.use(quillEditor);
app.use(VueSweetalert2);
app.use(i18n);
app.use(router);
app.use(store);
app.use(Vuex);
app.use(DisableAutocomplete);
app.mount('#app')