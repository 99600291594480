<template>
  <div class="paginator">
    <div class="paginator__controls" v-if="totalPages > 1">
      <button :disabled="currentPage === 1" @click="previousPage">
        &lt;
      </button>
      <span class="paginator__current-page" @click="currentPage = 1" v-if="currentPage !== 1">1</span>
      <span class="paginator__current-page" v-if="currentPage > 3" >...</span>
      <span class="paginator__current-page" @click="currentPage = currentPage - 1" v-if="currentPage > 2">{{ currentPage - 1 }}</span>
      <span class="paginator__current-page selected">{{ currentPage }}</span>
      <span class="paginator__current-page" @click="currentPage = currentPage  + 1" v-if="currentPage < totalPages - 1">{{ currentPage + 1 }}</span>
      <span class="paginator__current-page" v-if="currentPage < totalPages - 2">...</span>
      <span class="paginator__current-page" @click="currentPage = totalPages" v-if="currentPage !== totalPages">{{ totalPages }}</span>
      <button :disabled="currentPage === totalPages" @click="nextPage">
        &gt;
      </button>
    </div>
    <div class="paginator__placeholder" v-else>
    </div>
    <div class="paginator__select">
      <label for="items-per-page">Items per page:</label>
      <select id="items-per-page" v-model="itemsPerPage" @change="changeItemsPerPage">
        <option v-for="(option,index) in options" :value="option.value" :key="index">{{ option.text }}</option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  name: "rd-paginator",
  props: {
    items: {
      type: Array,
      required: true
    },
    forceRender: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      currentPage: 1,
      itemsPerPage: 20,
      options: [
        { text: "5", value: 5 },
        { text: "10", value: 10 },
        { text: "20", value: 20 },
        { text: "All", value: this.items.length }
      ]
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.items.length / this.itemsPerPage);
    },
    displayedItems() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.items.slice(startIndex, endIndex);
    }
  },
  mounted() {
    this.$emit("page-change", this.displayedItems);
  },
  watch: {
    items() {
      this.currentPage = this.forceRender? 1 : (this.totalPages < this.currentPage? this.totalPages : this.currentPage);
      this.$emit("page-change", this.displayedItems);
      this.options = [
        { text: "5", value: 5 },
        { text: "10", value: 10 },
        { text: "20", value: 20 },
        { text: "All", value: this.items.length }
      ]
    },
    displayedItems() {
      this.$emit("page-change", this.displayedItems);
    }
  },
  methods: {
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    changeItemsPerPage() {
      this.currentPage = 1;
    }
  }
};
</script>

<style>
.paginator__placeholder {
  height: 2rem;
}
.paginator {
  display: flex;
  align-items: baseline;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
  margin: 1rem 0;
  position: relative;
}
.paginator__controls {
  display: flex;
  gap: 0.1rem;
}
.paginator__controls button {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  background-color: #4a4a4a;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: all 0.3s ease;
}
.paginator__controls button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.paginator__controls button:hover:not(:disabled) {
  background-color: #333;
}
.paginator__current-page {
  padding: 0.5rem;
  font-size: 0.9rem;
  font-weight: bold;
  color: #4f23c9;
  cursor: pointer;
}
.selected {
  background-color: #cbbdf2;
}
.paginator__select label {
  margin-right: 0.5rem;
  font-size: 1rem;
}
.paginator__select select {
  padding: 0.5rem;
  font-size: 1rem;
}
.paginator__select {
  position: absolute;
  left: 0;
  padding-left: 1rem;
}
button {
  padding: 5px 10px;
  margin: 5px;
  border: none;
  background-color: #f2f2f2;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #ddd;
}

button:disabled {
  cursor: not-allowed;
}

button.active {
  background-color: #4caf50;
  color: white;
}
@media(max-width: 768px) {
  .paginator {
    justify-content: flex-start;
    flex-flow: column;
  }
  .paginator__select {
    position: unset;
  }
  .paginator__controls {
    margin: auto;
  }
}
</style>