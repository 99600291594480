<template>
  <div class="d-flex flex-column justify-content-between vh-100">
    <div>
      <rd-navbar :title="$t('Background')" />
      <div class="container bg-light my-3 p-3">
        <div class="row">
          <div class="col-12">
            <h2 class="ms-4 text-start">
              {{ $t('Background settings') }}
            </h2>
          </div>
        </div>
          
        <div class="row mb-4">
          <div class="col-sm-12 col-md-6">
            <rd-input
              type="text"
              :placeholder="$t('App title')" 
              :label="$t('Enter the app title')"
              required
              :value="title"
              @input="(e) => this.title = e.target.value"
            />
          </div>
        </div>

        <div class="row mb-4">
          <div class="col-sm-12 col-md-6">
            <table width="100%">
              <tr>
                <td>
                  <label class="labelText">
                    {{ $t('Logo') }}
                  </label>
                </td>
              </tr>
              <tr>
                <td>
                  <rd-input
                    type="file_image"
                    @input="(e) => this.logo = e.target.files[0]"
                  />
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col-sm-12 col-md-6">
            <table width="100%">
              <tr>
                <td>
                  <label class="labelText">
                    {{ $t('Background') }}
                  </label>
                </td>
              </tr>
              <tr>
                <td>
                  <rd-input
                    type="file_image"
                    @input="(e) => this.background = e.target.files[0]"
                  />
                </td>
              </tr>
            </table>
          </div>
        </div>

        <!-- buttons -->
        <div class="row my-3 justify-content-end">
          <div class="col-6 col-lg-2">
            <button 
              class="btn btn-outline-primary w-100" 
              @click="goBack"
            >
              {{ $t('Dismiss') }}
            </button>
          </div>
          <div class="col-6 col-lg-2">
            <button
              :disabled="false"
              class="btn btn-primary w-100" 
              @click="handleSave"
            >
              {{ !!settings?.id ? $t('Update') : $t('Save') }}
            </button>
          </div>
        </div>

      </div>
    </div>
    <div>
      <rd-footer/>
    </div>
  </div>
</template>
<script>
import RdFooter from '../../components/rd-components/rd-footer.vue'
import RdInput from '../../components/rd-components/rd-input.vue'
import RdNavbar from '../../components/rd-components/rd-navbar.vue'
import router from '@/router'
import { mapState } from 'vuex'
import { createNewSettings, updateSettings } from '../../services/settings/settings.api'

export default {
  data() {
    return {
      title: '', 
      logo: null,
      background: null,
    }
  },
  components: {
    RdFooter,
    RdNavbar,
    RdInput,
  },
  mounted() {
    if (this.settings) {
      this.title = this.settings.title;
    }
  },
  computed: {
    ...mapState(['settings'])
  },
  methods: {
    async handleSave() {
      try {
        const formData = new FormData();
        if (this.title) formData.append('title', this.title);
        if (this.logo) formData.append('logo', this.logo);
        if (this.background) formData.append('background', this.background);
        let response;
        if (this.settings && this.settings.id) {
          const {data} = await updateSettings(this.settings.id, formData)
          response = data
          this.$store.dispatch('handleSettings', response)
        } else {
          const {data} = await createNewSettings(formData)
          response = data
          this.$store.dispatch('handleSettings', response)
        }

        this.$store.dispatch('dispatchNotification', {
          title: 'Success!',
          message: 'Settings saved.',
          type: 'success',
        });
      } catch (error) {
        this.$store.dispatch('dispatchNotification', {
          title: 'Error!',
          message: 'Something went wrong.',
          type: 'error',
        });
        console.log(error)
      }
    },
    goBack() {
      router.back()
    },
  }
}
</script>