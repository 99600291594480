import httpClient from "../httpClient";

const EMPLOYEES_END_POINT = "/api/users/smarthire";
const STATUS_END_POINT = "/api/smarthire-statuses";

const config = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};

// Smarthie status API services
const getSHAvailableStatus = async () => await httpClient.get(STATUS_END_POINT);
const getSHStatusByIdStatus = async ({ id }) => await httpClient.get(`${STATUS_END_POINT}/${id}`);
const createSHStatus = async ({ payload }) => await httpClient.post(STATUS_END_POINT, payload);
const updateSHStatus = async ({ id, payload }) => await httpClient.patch(`${STATUS_END_POINT}/${id}`, payload);
const deleteSHStatus = async ({ id }) => await httpClient.delete(`${STATUS_END_POINT}/${id}`);

// smarthire employees API services
const getEmployees = async () => await httpClient.get(EMPLOYEES_END_POINT);
const updateEmployee = async ({ id, payload }) => await httpClient.patch(`${EMPLOYEES_END_POINT}/${id}`, payload);
const bulkUpdateEmployees = async ({ file }) => await httpClient.post(`${EMPLOYEES_END_POINT}/bulk`, file, config);

export {
  /* smarthire status */
  getSHAvailableStatus,
  getSHStatusByIdStatus,
  createSHStatus,
  updateSHStatus,
  deleteSHStatus,

  /* smarthire employees */
  getEmployees,
  updateEmployee,
  bulkUpdateEmployees,
};
