export const getRoleText = (roleId) => {
  if (parseInt(roleId) === 1) return 'Admin';
  if (parseInt(roleId) === 2) return 'Uploader';
  if (parseInt(roleId) === 3) return 'Viewer';
  if (parseInt(roleId) === 4) return 'Viewer and Downloader';
  return 'Trainer'
}

export const encodeToBase64 = (json) => {
  const jsonString = JSON.stringify(json);
  const base64 = btoa(jsonString)
  return base64;
}

export const decodeFromBase64 = (string) => {
  return new Promise((resolve, reject) => {
    try {
      const binaryString = window.atob(string);
      const utf8Bytes = new Uint8Array(binaryString.length);
      for (let i = 0; i < binaryString.length; i++) {
        utf8Bytes[i] = binaryString.charCodeAt(i);
      }
      const decodedString = new TextDecoder('utf-8').decode(utf8Bytes);
      resolve(decodedString);
    } catch (error) {
      reject('');
    }
  });
};

export const getCustomTimestamp = () => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const day = String(currentDate.getDate()).padStart(2, '0');
  const hours = String(currentDate.getHours()).padStart(2, '0');
  const minutes = String(currentDate.getMinutes()).padStart(2, '0');
  const seconds = String(currentDate.getSeconds()).padStart(2, '0');
  return `${year}${month}${day}&${hours}hh${minutes}mm${seconds}ss`;
}