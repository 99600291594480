<template>
  <div id="app">
    <router-view/>
    <rd-modal @hide="dispatchOffModal" v-if="showModal" :type="modalType" :content="modalContent" />
    <rd-notification
      :showAlert="notification.show"
      :title="notification.title"
      :message="notification.message"
      :type="notification.type"
      :closeButton="notification.closeButton"
    />
    <div id="mask" v-if="$store.state.stateLoad">
      <div class="load"></div>
    </div>
  </div>
</template>
<script>
import { useWindowSize } from 'vue-window-size';
import { getSettings } from './services/settings/settings.api';
import RdModal from '@/components/rd-components/rd-modal.vue';
import { mapState } from 'vuex'
import RdNotification from '@/components/rd-components/rd-notification.vue';
import { getShoulShowChat } from '@/services/user/user.api.js'
import { initialize } from '@/utils/chat.js'
import { getRoleText } from '@/utils/utils.js';

export default {
  data() {
    return {
      windowWidth: null
    }
  },
  components: {
    RdModal,
    RdNotification
  },
  beforeCreate() {
    this.$store.dispatch('getI18nData', this.$i18n);
    this.$store.commit('setStateLoad', false)
    this.$store.commit('setIsMobile', window.location.href.includes('ismobile=true'))
    
  },
  async mounted() {
    this.dispatchOffModal();
    const { width } = useWindowSize();
    this.windowWidth = width
    await this.handleSettings()
    if(this.userLoggedIn2 && !this.isMobile) {
      const showChat = await getShoulShowChat();
      if(showChat.data) {
        const currentDate = new Date();
        const timestampUnix = Math.floor(currentDate.getTime() / 1000);
        initialize({
          api_base: "https://api-iam.intercom.io",
          app_id: "zi9hkx6m",
          name: `${this.user.userFirstname} ${this.user.userLastname}`,
          user_id: this.user.userId,
          email: this.user.userEmail,
          created_at: timestampUnix,
          rol: getRoleText(this.user.roleId),
          rol_id: this.user.roleId,
          app: 'repodocs',
          project: (this.settings? this.settings.title : null) ||  this.$t('Smart Files')
        });
      }
    }
  },
  watch: {
    windowWidth(newVal) {
      this.$store.commit('setWindowWidth', newVal);
      this.$store.commit('setInternalMobile', newVal < 768);
    },
  },
  computed: {
    userLoggedIn() {
      return this.$store.state.statusLogin
    },
    userLoggedIn2() {
      return Object.keys(this.$store.state.user).length > 0
    },
    ...mapState([
      'showModal',
      'modalContent',
      'modalType',
      'notification',
      'statusLogin',
      'loggedByToken',
      'isMobile',
      'settings',
      'user'
    ]),
  },
  methods: {
    dispatchOffModal() {
      this.$store.commit('setShowModal', false)
      this.$store.commit('setModalContent', null)
      this.$store.commit('setModalType', '')
    },
    async handleSettings() {
      try {
        const {data: settings} = await getSettings()
        this.$store.dispatch('handleSettings', settings[0])
      } catch (error) {
        console.log(error) // eslint-disable-line no-console
      }
    }
  }
}
</script>
<style>
#app {
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  padding: 0px;
  margin: 0;
}
.labelText {
  margin: 0;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: grey;
  float: left;
  border-radius: 4px;
}

.required::after {
  color: #6040b9;
  content: ' *';
  font-size: 16px;
}

.word-wrap {
  word-wrap: break-word;
}

.hoverable:hover {
  cursor: pointer;
}

#mask {
  background-color: rgb(250 240 245 / 73%);
  height: 100%;
  width: 100%;
  position: fixed;
  transition: all 1s ease;
  z-index: 1000;
  left: 0;
  top: 0;
}

.load {
  border: 15px solid #dcd3f4;
  border-top-color: #dcd3f4;
  border-top-style: groove;
  height: 100px;
  width: 100px;
  border-radius: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  animation: girar 1.5s linear infinite;
}

@keyframes girar {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.unselectable {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
.ql-font-roboto {
  font-family: 'Roboto', sans-serif!important;
}
</style>
