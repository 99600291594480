<template lang="">
  <div class="d-flex flex-column justify-content-between vh-100">
    <div>
      <rd-navbar :title="$t('Users (nav)')" />
      <div class="container bg-light my-3 p-3">
        <div class="row">
          <div class="col-12">
            <h2 class="ms-4 text-start">{{ $t('Edit User') }}</h2>
          </div>
        </div>

        <form
          @submit.prevent=""
          autocomplete="off"
          class="container"
        >
          <div class="row mb-4">
            <div class="col-sm-12 col-md-6">
              <rd-input
                type="text"
                :placeholder="$t('Username')" 
                :label="$t('Enter the username')"
                required
                :value="userName"
                @input="(e) => this.userName = e.target.value"
              />
            </div>
          </div>
          
          <div class="row mb-4">
            <div class="col-sm-12 col-md-6 text-start">
              <rd-input
                type="password"
                :placeholder="$t('Password')" 
                :label="$t('Enter user password')"
                required
                showPassIcon
                :value="userPassword"
                @input="(e) => this.userPassword = e.target.value"
                :errorMsg="$t('Password insecure')"
                :showError="showError"
                ref="password"
              />
              <div
                v-if="userPassword.length > 0" 
                class="m-0 mt-2"
              >
                <div
                  :class="{
                    'text-success': passwordIsLongEnough,
                    'text-danger': !passwordIsLongEnough
                  }"
                > 
                  <i data-bs-toggle="tooltip" data-bs-placement="right" title="Password Status" type="button" :class="`bi ${passwordIsLongEnough ? 'bi-check-circle-fill': 'bi-exclamation-circle-fill'}`" />
                  {{ $t('Minimum password length is 8 characters') }}
                </div>
                <div
                  :class="{
                    'text-success': passwordContainsCapitalLetters,
                    'text-danger': !passwordContainsCapitalLetters
                  }"
                >
                  <i data-bs-toggle="tooltip" data-bs-placement="right" title="Password Status" type="button" :class="`bi ${passwordContainsCapitalLetters ? 'bi-check-circle-fill': 'bi-exclamation-circle-fill'}`" />
                  {{ $t('Require at least one uppercase letter from Latin alphabet (A-Z)') }}
                </div>
                <div
                  :class="{
                    'text-success': passwordContainsLowerCase,
                    'text-danger': !passwordContainsLowerCase
                  }"
                >
                  <i data-bs-toggle="tooltip" data-bs-placement="right" title="Password Status" type="button" :class="`bi ${passwordContainsLowerCase ? 'bi-check-circle-fill': 'bi-exclamation-circle-fill'}`" />
                  {{ $t('Require at least one lowercase letter from Latin alphabet (a-z)') }}
                </div>
                <div
                  :class="{
                    'text-success': passwordContainsNumber,
                    'text-danger': !passwordContainsNumber
                  }"
                >
                  <i data-bs-toggle="tooltip" data-bs-placement="right" title="Password Status" type="button" :class="`bi ${passwordContainsNumber ? 'bi-check-circle-fill': 'bi-exclamation-circle-fill'}`" />
                  {{ $t('Require at least one number') }}
                </div>
                <div
                  :class="{
                    'text-success': passwordContainsEspecialChar,
                    'text-danger': !passwordContainsEspecialChar
                  }"
                >
                  <i data-bs-toggle="tooltip" data-bs-placement="right" title="Password Status" type="button" :class="`bi ${passwordContainsEspecialChar ? 'bi-check-circle-fill': 'bi-exclamation-circle-fill'}`" />
                  {{ $t('Require at least one non-alphanumeric character (e.g. !@#$%^&*()_+-)') }}
                </div>
              </div>
            </div>
          </div>

          <div class="row mb-4">
            <div class="col-sm-12 col-md-6">
              <rd-input
                placeholder="Email" 
                type="email"
                :label="$t('Enter the email')"
                :value="userEmail"
                @input="(e) => this.userEmail = e.target.value"
              />
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-sm-12 col-md-6">
              <rd-input
                type="text"
                :placeholder="$t('First name')"
                :label="$t('Enter user first name')"
                :value="userFirstname"
                @input="(e) => this.userFirstname = e.target.value"
              />
            </div>
          </div>
          
          <div class="row mb-4">
            <div class="col-sm-12 col-md-6">
              <rd-input
                type="text"
                :placeholder="$t('Last name')" 
                :label="$t('Enter user last name')"
                :value="userLastname"
                @input="(e) => this.userLastname = e.target.value"
              />
            </div>
          </div>  
          <div class="row mb-4">
            <div class="col-sm-12 col-md-6">
              <rd-input
                type="text"
                :placeholder="$t('Phone')" 
                :label="$t('Enter phone number')"
                :value="phone"
                @input="(e) => this.phone = e.target.value"
              />
            </div>
          </div>  
          
          <div class="row mb-4">
            <div class="col-sm-12 col-md-6">
              <rd-multiselect
                ref="multiselect-profiles"
                :initialValue="profilesSelected"
                :placeholderToTag="$t('Add this profile')"
                :placeholder="$t('Add profiles')"
                :label="$t('Enter the profiles related')"
                name="name"
                :optionsToSelect="profiles"
                @handleAddTagging="addTag"
              />
            </div>
          </div>

          <div class="row mb-4">
            <div class="col-sm-12 col-md-6">
              <rd-multiselect 
                ref="multiselect-roles"
                :initialValue="rolesSelected"
                :placeholderToTag="$t('Add this role')"
                :placeholder="$t('Add a role')"
                name="name"
                :label="$t('Enter the roles related')"
                :multiple="false"
                :optionsToSelect="roles"
                @handleAddTagging="addRoleTag"
              />
            </div>
          </div>

          <div class="my-3 row justify-content-end">
            <div class="col-lg-3 col-md-6 col-sm-6">
              <button class="btn btn-outline-primary me-3" @click="goBack">
                {{ $t('Dismiss') }}
              </button>
              <button type="submit" :disabled="disableSaveButton" class="btn btn-primary" @click="editUser">
                {{ $t('Edit User (btn)') }}
              </button>
            </div>
          </div>
        </form>
    </div>
    </div>
    <div>
      <rd-footer/>
    </div>
  </div>
</template>
<script>
import RdFooter from '../../components/rd-components/rd-footer.vue';
import RdNavbar from '../../components/rd-components/rd-navbar.vue';
import RdInput from '../../components/rd-components/rd-input.vue';
import RdMultiselect from '@/components/rd-components/rd-multiselect.vue';
import router from '@/router'
import { getAllProfiles } from '@/services/profiles/profile.api.js'
import { getAllRoles } from '@/services/roles/role.api.js'
import { getUserById, editUserById } from '@/services/user/user.api.js'

export default {
  data() {
    return {
      profilesSelected: [],
      profiles: [],
      rolesSelected: [],
      roles: [],
      userEmail: '',
      userFirstname: '',
      userLastname: '',
      phone: '',
      userName: '',
      userPassword: '',
      disableSaveButton: false,
      showError: false
    }
  },
  async mounted() {
    await this.setRoles()
    await this.setUserData()
    await this.setProfiles()
    this.$watch(
      () => {
        return [this.userName, this.$refs['multiselect-profiles'].value.length, this.$refs['multiselect-roles'].value, this.validPassword, this.userPassword]
      },
      ([userName, profilesSelected, role, validPassword, userPassword]) => {
        if (userName === '' || profilesSelected === 0 || Object.keys(role).length === 0 || (userPassword.length > 0 && !validPassword)) {
          this.disableSaveButton = true
        } else {
          this.disableSaveButton = false
        }
      }
    );
  },
  computed: {
    userId() {
      return this.$route.params.id
    },
    passwordIsLongEnough() {
      return this.userPassword && this.userPassword.length > 7
    },
    passwordContainsCapitalLetters() {
      return this.userPassword && /[A-Z]/.test(this.userPassword)
    },
    passwordContainsLowerCase() {
      return this.userPassword && /[a-z]/.test(this.userPassword)
    },
    passwordContainsNumber() {
      return this.userPassword && /\d/.test(this.userPassword)
    },
    passwordContainsEspecialChar() {
      return this.userPassword && /([!@#$%^&*()_+-]+)/.test(this.userPassword)
    },
    validPassword() {
      return (
        this.passwordIsLongEnough &&
        this.passwordContainsCapitalLetters &&
        this.passwordContainsLowerCase &&
        this.passwordContainsNumber &&
        this.passwordContainsEspecialChar
      )
    }
  },
  components: {
    RdFooter,
    RdNavbar,
    RdInput,
    RdMultiselect
  },
  methods: {
    async setUserData() {
      let userId = this.$route.params.id
      try {
        const { data } = await getUserById(userId)
        this.userEmail = data.user.userEmail
        this.userFirstname = data.user.userFirstname
        this.userLastname = data.user.userLastname 
        this.phone = data.user.phone 
        this.userName = data.user.userName
        this.profilesSelected = data.userProfiles.map(item => ({name: item.profileName, code: item.profileId}))
        this.rolesSelected = this.roles.find(role => parseInt(role.code) === parseInt(data.user.roleId))
      } catch (error) {
        console.log(error)
      }
    },
    async setProfiles() {
      try {
        const res = await getAllProfiles()
        this.profiles = res.data.map(item => ({name: item.profileName, code: item.profileId}))
      } catch (error) {
        console.log(error)
      }
    },
    async setRoles() {
      try {
        const { data } = await getAllRoles()
        this.roles = data.map(role => ({name: role.roleName, code: role.roleId }))
      } catch (error) {
        console.log(error)
      }
    },
    async editUser() {
      let profiles = this.$refs['multiselect-profiles'].value.map(item => parseInt(item.code));
      let role = this.$refs['multiselect-roles'].value.code;
      this.showError = false;
      const user = {
        userEmail: this.userEmail,
        userFirstname: this.userFirstname,
        userLastname: this.userLastname,
        phone: this.phone,
        userName: this.userName,
        profiles,
        roleId: role
      }
      if(this.userPassword.length > 0) user.userPassword = this.userPassword;
      try {
        await editUserById(this.userId, user)
        this.$store.dispatch('dispatchNotification', {
          title: 'Success!',
          message: 'User edited.',
          type: 'success',
        });
        router.back()
      } catch (error) {
        // Validate if error has a response and the status is a 400
        if(error.response && error.response.status === 400) {
          // Then check if the response has data, error and message
          if(error.response.data && error.response.data.error && error.response.data.error.message) {
            // If the message of the error includes password this means we have a trouble in password
            // Then we show the error message
            if(error.response.data.error.message.toLowerCase().includes('password')) {
              this.showError = true;
              this.userPassword = ''
            }
          }
        }
        this.$store.dispatch('dispatchNotification', {
          title: 'Error!',
          message: 'Could not edit this user.',
          type: 'error',
        });
        console.log(error)
      }
    },
    goBack() {
      router.back()
    },
    addTag (newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
      }
      this.profiles.push(tag)
      this.profilesSelected.push(tag)
    },
    addRoleTag (newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
      }
      this.roles.push(tag)
      this.rolesSelected.push(tag)
    }
  },
}
</script>

<style scoped>
.labelText {
  margin: 0;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: grey;
  float: left;
  border-radius: 4px;
}

</style>