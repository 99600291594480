<template lang="">
<div class="d-flex flex-column justify-content-between vh-100">
  <div>
    <rd-navbar :title="$t('Sessions (nav)')" />
    <div class="container bg-light my-3 p-3">
      <div class="row">
        <div class="col-12">
          <h2 class="ms-4 text-start">{{ $t('Trainer Feedback') }}</h2>
        </div>
        <div class="col-12">
          <h2 class="ms-4 text-start fw-bolder">{{ $t('Description') }}: <span class="fw-normal"> {{description}}</span></h2>
        </div>
        <div class="col-12">
          <p class="ms-4 mb-0 text-start fw-bolder">{{ $t('Name') }}: <span class="fw-normal"> {{code}}</span></p>
        </div>
        <div class="col-12">
          <p class="ms-4 mb-0 text-start fw-bolder">{{ $t('Date & Time') }}:
            <span v-if="!allDay" class="fw-normal"> {{formatedDate(date)}} - {{formatHour(startTime)}} - {{formatHour(endTime)}}</span>
            <span v-else class="fw-normal"> {{formatedDate(date)}} - {{ $t('All Day') }}</span>
          </p>
        </div>
        <div class="col-12">
          <p class="ms-4 mb-0 text-start fw-bolder">{{ $t('Trainer') }}:
            <span class="fw-normal"> {{trainer}}</span>
          </p>
        </div>
        <div class="row p-3 justify-content-between">
          <div class="p-0 m-0 mb-2 col-12 col-lg-4 ms-4">
            <rd-input class="w-100" :placeholder="$t('Search file by file name')" type="text" @input="(e) => this.search = e.target.value" />
          </div>

          <button class="btn btn-outline-primary col-12 col-lg-2 ms-4" @click="downloadFeedback">
            <i data-bs-toggle="tooltip" data-bs-placement="right" title="Download Feedback" type="button" class="bi bi-arrow-down-circle-fill"></i> {{ $t('Download Feedback') }}
          </button>
        </div>
        <div v-if="dataLoading">
          <rd-loading />
        </div>

        <div v-else>
          <div v-if="filteredUsers.length === 0" class="row p-3">
            <h2>
              {{ $t('No data found') }} <i data-bs-toggle="tooltip" data-bs-placement="right" title="No data Found" type="button" class="bi bi-exclamation-triangle"></i>
            </h2>
          </div>

          <div v-else class="row p-3 d-flex justify-content-start">
            <div style="overflow-x: auto; width: 100%; max-width: 100%;">
              <div class="w-100">
                <table class="table text-start table-hover" ref="table_resize">
                  <thead class="table-primary" @contextmenu.prevent="resize">
                    <tr>
                      <th scope="col" class="centered"> <input class="form-check-input px-2 mx-2 hoverable" @click="handleSelectAll" :value="true" v-model="selectAll" type="checkbox" > </th>
                      <th class="centered" scope="col">{{ $t('Users (table)') }}</th>
                      <th class="centered" scope="col">{{ $t('Score (table)') }}</th>
                      <th class="centered" scope="col">{{ $t('Rating (table)') }}</th>
                      <th class="centered" scope="col">{{ $t('Feedback (table)') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(user, index) in dataTemp" :key="index" class="text-start hoverable" :class="{ 'fill-ods': index % 2 !== 0 }">
                      <td style="width: 10%; word-break: break-all;" scope="row" :header="$t('Icon (table)')" class="word-wrap centered"><input @click="handleClickUser(user)" :value="true" v-model="user.feedBackToDownload" class="form-check-input px-2 mx-2 hoverable " type="checkbox" ></td>
                      <td class="centered" style="width: 25%; word-break: break-all;" scope="row" :header="$t('Users (table)')">
                        {{ `${user.user.userFirstname ?? ''} ${user.user.userLastname ?? ''}` }}
                      </td>
                      <td class="centered" style="width: 25%; word-break: break-all;" scope="row" :header="$t('Score (table)')">
                        {{ user.examAttemp ? user.examAttemp.score : '-' }}/{{ questionCount }}
                      </td>
                      <td class="centered" style="width: 25%; word-break: break-all;" scope="row" :header="$t('Rating (table)')">
                        <div class="px-2">
                          <select @change="handleChangeRating(user)"  id="" v-model="user.sessionsUser.rating">
                            <option v-for="(option,index) in options" :value="option.value" :key="index">{{ option.text }}</option>
                          </select>
                        </div>
                      </td>
                      <td class="centered" style="width: 15%; word-break: break-all;" :header="$t('Feedback (table)')">
                        <div class="container">
                          <div class="row justify-content-center">
                            <button v-if="!isInternalMobile"  class="btn btn-primary" style="width:auto;" @click="showModalFeedback(user)">
                              {{ $t('Feedback (btn)') }}
                            </button>
                            <i data-bs-toggle="tooltip" data-bs-placement="right" title="User Feedback" type="button" @click="showModalFeedback(user)" v-else class='bi bi-chat-left-text'></i>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <rd-paginator :items="filteredUsers" @page-change="handlePageChange" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="showModal" @click.self="hide" class="modal-feedback">
    <div class="embed-container p-3">
      <div class="w-100">
        <h2 class="text-start ms-2">{{ $t('Feedback') }}</h2>
        <p class="text-start ms-2">{{ $t('Observations') }}</p>
        <div class="border rounded p-3">
          <textarea v-model="feedback" resize class="form-control custom-textarea" :placeholder="$t('Feedback')"></textarea>
        </div>
      </div>
      <div class="d-flex w-100 mt-3" style="gap: 1rem;">
        <button 
            class="btn btn-outline-primary w-100" 
            @click="hide"
          >
            {{ $t('Cancel') }}
          </button>
          <button
            :disabled="false"
            class="btn btn-primary w-100" 
            @click="handleSave"
          >
            {{ $t('Save') }}
          </button>
        <!-- <div class="col-6 col-lg-2">
          
        </div>
        <div class="col-6 col-lg-2">
        </div> -->
      </div>
      <i data-bs-toggle="tooltip" data-bs-placement="right" title="Close" type="button" @click.self="hide" class="bi bi-x-circle close"></i>
    </div>
    
  </div>
  <div>
    <rd-footer />
  </div>
</div>
</template>

<script>
import RdFooter from '../../components/rd-components/rd-footer.vue';
import RdNavbar from '../../components/rd-components/rd-navbar.vue';
import router from '@/router'
import RdInput from '@/components/rd-components/rd-input.vue'
import {
  getSession,
  getSessionsUsers,
  editSessionFeedback
} from '@/services/sessions/sessions.api.js'
import {
  convertToCSV
} from '@/utils/convertFormat';
import {
  downloadCSV
} from '@/utils/handleFile';
import RdPaginator from '@/components/rd-components/rd-paginator.vue'
import { mapState } from 'vuex';

export default {
  data() {
    return {
      date: null,
      startTime: null,
      endTime: null,
      code: null,
      trainer: null,
      allDay: true,
      sessionUsers: [],
      dataLoading: true,
      selectAll: false,
      dataTemp: [],
      questionCount: '-',
      options: [
        { text: "1", value: 1 },
        { text: "2", value: 2 },
        { text: "3", value: 3 },
        { text: "4", value: 4 },
        { text: "5", value: 5 },
      ],
      search: '',
      showModal: false,
      currentUser: null,
      feedback: null,
      description: ''
    }
  },

  computed: {
    ...mapState(['isInternalMobile']),
    sessionId() {
      return this.$route.params.id
    },
    filteredUsers() {
      return this.sessionUsers.filter(
        user => `${user.user.userFirstname ?? ''} ${user.user.userLastname ?? ''}`.toLowerCase().includes(this.search.trim().toLowerCase())
        )
    },
  },
  watch: {
    filteredUsers(current) {
      this.selectAll = current.every((value) => value.feedBackToDownload === true)
    }
  },
  async mounted() {
    await this.setSessionData()
  },
  components: {
    RdFooter,
    RdNavbar,
    RdInput,
    RdPaginator
  },
  methods: {
    async handleChangeRating(user) {
      try {
        await editSessionFeedback(user.sessionsUser.id, {
          feedback: user.sessionsUser.feedback,
          rating: user.sessionsUser.rating
        })
        this.$store.dispatch('dispatchNotification', {
          title: 'Notification',
          message: 'Saved',
          type: 'success',
        })
      } catch (error) {
        console.log(error)
        this.$store.dispatch('dispatchNotification', {
          title: 'Notification',
          message: 'The feedback was not saved',
          type: 'error',
        })

      } 
    },
    async handleSave() {
      try {
        await editSessionFeedback(this.currentUser.sessionsUser.id, {
          feedback: this.feedback,
          rating: this.currentUser.sessionsUser.rating
        })
        this.currentUser.sessionsUser.feedback = this.feedback
        this.showModal = false;
        this.feedback = null
        this.currentUser = null
        this.$store.dispatch('dispatchNotification', {
          title: 'Notification',
          message: 'Saved',
          type: 'success',
        })
      } catch (error) {
        console.log(error)
        this.$store.dispatch('dispatchNotification', {
          title: 'Notification',
          message: 'The feedback was not saved',
          type: 'error',
        })

      } 
      
    },
    showModalFeedback(user) {
      this.currentUser = user
      this.showModal = true
      this.feedback = this.currentUser.sessionsUser.feedback
    },
    hide() {
      this.showModal = false;
      this.feedback = null
      this.currentUser = null
    },
    handleClickUser(user) {
      user.feedBackToDownload = !user.feedBackToDownload
      this.selectAll = this.filteredUsers.every((value) => value.feedBackToDownload === true)
    },
    async handlePageChange(data) {
      this.dataTemp = data;
    },
    handleSelectAll() {
      this.filteredUsers.forEach((sessionUser) => {
        sessionUser.feedBackToDownload = !this.selectAll;
      })
      this.selectAll = !this.selectAll;
    },
    async downloadFeedback() {
      try {
        const usersToDownload = this.filteredUsers.filter(
          user => user.feedBackToDownload
        ).map((user) => {
          return {
            name: `${user.user.userFirstname ?? ''} ${user.user.userLastname ?? ''}`,
            score: `${user.examAttemp ? user.examAttemp.score : '-'} / ${this.questionCount}`,
            rating: user.sessionsUser.rating,
            feedback: user.sessionsUser.feedback
          }
        })
        const csv = await convertToCSV({ data: usersToDownload });
        downloadCSV({ data: csv, filename: 'feedback_stats' });
      } catch (error) {
        console.log(error);
      }
    },
    formatHour(hour) {
      if (!hour) return ''
      let partsHour = hour.split(":");
      let hours = parseInt(partsHour[0]);
      let minutes = partsHour[1];

      let period = (hours >= 12) ? "PM" : "AM";

      hours = (hours > 12) ? hours - 12 : hours;
      hours = (hours == 0) ? 12 : hours;

      let formatedHour = hours + ":" + minutes + " " + period;

      return formatedHour;
    },
    formatedDate(date) {
      if (!date) {
        return ''
      }
      return date.split('T')[0].replace(/-/g, "/")
    },
    async setSessionData() {
      this.dataLoading = true
      try {
        const {
          data
        } = await getSession(this.sessionId)
        const {
          data: sessionUsers
        } = await getSessionsUsers(this.sessionId)
        const {
          session,
          trainer,
          exam,
          questions
        } = data;
        this.questionCount = questions ? questions.length : '-'
        this.description = session.description
        sessionUsers.forEach((sessionUser) => {
          sessionUser.feedBackToDownload = false;
          if(exam) {
            const examAttemp = exam.find(element => element.userName === sessionUser.user.userName)
            if(examAttemp) {
              sessionUser.examAttemp = examAttemp
            }
          }
        })
        
        const {
          date,
          startTime,
          endTime,
          code,
          allDay
        } = session
        this.allDay = allDay
        this.date = date;
        this.startTime = startTime;
        this.endTime = endTime;
        this.code = code;
        this.trainer = `${trainer?.userFirstname ?? ''} ${trainer?.userLastname ?? ''}`
        this.sessionUsers = sessionUsers
      } catch (error) {
        console.log(error)
      } finally {
        this.dataLoading = false;
      }
    },
    goBack() {
      router.back()
    }
  },
}
</script>

<style scoped>
.centered {
  text-align: center;
  vertical-align: middle;
}
.modal-feedback {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.4); /* fondo negro con opacidad del 40% */
  z-index: 9999; /* asegura que el modal se superpone a otros elementos */
  display: flex;
  justify-content: center;
  align-items: center;
}
.embed-container {
  position: relative;
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  flex-flow: column;
}
.close {
  position: absolute;
  top: 5px;
  right: 10px;
  font-size: 1.5rem;
  cursor: pointer;
}
.custom-textarea {
  min-width: 500px;
  width: 100%;
  height: 150px;
  resize: none;
}
@media (max-width: 768px) {
  .close {
    font-size: 1rem;
  }
  .custom-textarea {
    min-width: auto;
  }
  .embed-container {
    width: 90%;
  }
}
</style>
