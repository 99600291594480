<template>
  <div class="d-flex flex-column justify-content-between vh-100">
    <div>
      <rd-navbar />
      <div class="container bg-light rounded-3 my-3 p-3">
        <div class="row">
          <div class="col-12">
            <h2 class="ms-4 text-start">{{ $t('SmartHire') }}</h2>
          </div>
        </div>

        <div class="row p-3 mt-1">
          <div class="col-12 col-lg-4 col-md-4 mt-2">
            <rd-input
              :value="this.search"
              type="text"
              :placeholder="$t('Search by username or email')"
              @input="(e) => this.search = e.target.value"
            />
          </div>

          <div class="col-12 col-lg-4 col-md-4 mt-2">
            <rd-input
              ref="select-status"
              type="select"
              :placeholder="$t('Select status')"
              :placeholderToTag="$t('Add this')" 
              :items="statusAvailable.map(({ name, code }) => ({ name, value: code }))"
              @handleAddTagging="calcStatusSelected($event)"
            />
          </div>
          <div class="col-12 col-lg-4 col-md-4 mt-2">
            <rd-input
              ref="select-availability"
              type="select"
              :placeholder="$t('Select availibility')"
              :placeholderToTag="$t('Add this')" 
              :items="[
                { name: $t('Enabled'), value: true },
                { name: $t('Disabled'), value: false }
              ]"
              @handleAddTagging="calcAvailability($event)"
            />
          </div>
          <div class="col-12 col-lg-4 col-md-4 mt-2">
            <rd-input
              ref="select-exam"
              type="select"
              :placeholder="$t('Select exam')"
              :placeholderToTag="$t('Add this')" 
              :items="examsAvailable.map(({ name, id }) => ({ name, value: id }))"
              @handleAddTagging="calcExam($event)"
            />
          </div>
          <div v-if="examSelected" class="col-12 col-lg-4 col-md-4 mt-2">
            <rd-input
              ref="select-operator-logic"
              type="select"
              :placeholder="$t('Select the logical operator')"
              :placeholderToTag="$t('Add this')" 
              :items="[
                { name: $t('Equal'), value: '=' },
                { name: $t('Smaller than'), value: '<' },
                { name: $t('Less than equal'), value: '<=' },
                { name: $t('Greater than'), value: '>' },
                { name: $t('Greater than or equal'), value: '>=' },
              ]"
              @handleAddTagging="calcOperator($event)"
            />
          </div>
          <div v-if="examSelected" class="col-12 col-lg-4 col-md-4 mt-2">
            <rd-input
              type="number"
              :placeholder="$t('Search by percentage')"
              @input="(e) => this.percentage = e.target.value"
            />
          </div>
          <div class="col-12 col-lg-2 col-md-3 mt-2 pt-2">
            <button 
              class="btn btn-sm btn-outline-primary w-100"
              @click="clearFilters"
            >
              {{ $t('Clear filters') }}
            </button>
          </div>
        </div>

        <div v-if="screenLoading">
          <rd-loading /> 
        </div>
        <div v-else>
          <div
            v-if="filteredEmployees.length === 0" 
            class="row p-3"
          >
            <h2>
              {{ $t('No data found') }} <i data-bs-toggle="tooltip" data-bs-placement="right" title="No Employees Found" type="button" class="bi bi-exclamation-triangle"></i>
            </h2>
          </div>

          <template v-else>
            <div class="row p-3 d-flex justify-content-start">
  
              <div style="overflow-x: auto; width: 100%; max-width: 100%;">
                <div class="w-100">
                  <table class="table text-start table-hover" ref="table_resize">
                    <thead class="table-primary" @contextmenu.prevent="resize">
                      <tr>
                        <th scope="col">{{ $t('Username (table)') }}</th>
                        <th scope="col">{{ $t('First Name (table)') }}</th>
                        <th scope="col">{{ $t('Last Name (table)') }}</th>
                        <th scope="col">{{ $t('Email (table)') }}</th>
                        <th scope="col">{{ $t('Status (table)') }}</th>
                        <th scope="col">{{ $t('Enable (table)') }}</th>
                        <th scope="col" class="text-center">{{ $t('Actions (table)') }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="({
                          userId,
                          userFirstname: firstName,
                          userLastname: lastName,
                          userEmail,
                          userName,
                          isAvailable,
                          status
                        }, index) in employeesDataTemp" :key="`${userId}`"
                        class="text-start"
                        :class="{ 'fill-ods': index % 2 !== 0 }"
                      >
                        <td style="min-width: 14.28%; word-break: break-all;" scope="row" :header="$t('First Name (table)')" class="word-wrap" >{{ userName }}</td>
                        <td style="min-width: 14.28%; word-break: break-all;" scope="row" :header="$t('First Name (table)')" class="word-wrap" >{{ firstName }}</td>
                        <td style="min-width: 14.28%; word-break: break-all;" scope="row" :header="$t('Last Name (table)')" >{{ lastName }}</td>
                        <td style="min-width: 14.28%; word-break: break-all;" scope="row" :header="$t('Email (table)')" >{{ userEmail }}</td>
                        <td style="min-width: 14.28%; word-break: break-all;" scope="row" :header="$t('Status (table)')">
                          <select
                            class="form-select"
                            aria-label="select availability"
                            v-model="status.code"
                            @change="handleUserStatusChange($event, userId)"
                          >
                            <option v-for="(item, index) in statusAvailable" :key="index" :value="item.code">{{ item.name }}</option>
                          </select>
                        </td>
                        <td style="min-width: 14.28%; word-break: break-all; text-align: center" scope="row" :header="$t('Enable (table)')" >
                          <input
                            class="form-check-input"
                            type="checkbox"
                            :checked="isAvailable"
                            @change="handleUserAvailabilityChange($event, userId)"
                          />
                        </td>
                        <td style="min-width: 14.28%; word-break: break-all;" :header="$t('Actions (table)')">
                          <div class="container">
                            <div class="row text-center">
                              <div class="col p-0 m-0">
                                <button 
                                  class="btn btn-sm btn-outline-primary"
                                  @click="saveEmployeeChanges($event, userId)"
                                >
                                  Save
                                </button>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <rd-paginator :items="filteredEmployees" :force-render="forceRender" @page-change="handlePageChange" />
            </div>

            <div class="row d-flex justify-content-end gap-1 px-4">

              <div class="p-0 m-0 mb-2 col-12 col-lg-3">
                <button
                  class="btn btn-primary w-100"
                  @click="handleUploadCSV"
                >
                  {{ $t('Upload CSV') }}
                </button>
              </div>

              <div class="p-0 m-0 mb-2 col-12 col-lg-3">
                <button
                  class="btn btn-primary w-100"
                  @click="handleDownloadCSV"
                >
                  {{ $t('Download CSV') }}
                </button>
              </div>

            </div>
          </template>
        </div>
      </div>
    </div>
    <div>
      <rd-footer />
    </div>

    <!-- bulk load modal -->
    <div
      v-if="showBulkLoadModal"
      class="position-fixed top-0 start-0 backScreen"
    >
      <div 
        class="
          card 
          position-fixed 
          top-50
          start-50
          translate-middle
          modalPosition
          my-2
          w-75 p-4
        "
      >
        <div class="container h-100">
          <div class="row">
            <h4 class="col-12 text-capitalize">
              {{ $t('Bulk smarthire updates')}}
            </h4>
          </div>

          <div class="row mt-3">
            <div class="col-12">
              <rd-input
                type="file"
                ref="file"
                :placeholder="$t('File')"
                :label="$t('Upload a file to bulk update')"
                @input="handleFileSelect"
              />
            </div>
          </div>
          
          <!-- actions buttons -->
          <div class="row mt-4">
            <div class="col-6">
              <button
                @click="() => showBulkLoadModal = false"
                class="col-6 w-100 btn btn-outline-primary text-capitalize"
              >
                {{ $t('Dismiss') }}
              </button>
            </div>
            <div class="col-6 position-relative">
              <button 
                @click="handleUploadFile"
                class="w-100 btn btn-primary text-capitalize"
              >
                {{ $t('Upload') }}
              </button>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import RdFooter from '@/components/rd-components/rd-footer.vue'
import RdLoading from '@/components/rd-components/rd-loading.vue'
import RdNavbar from '@/components/rd-components/rd-navbar.vue'
import RdPaginator from '@/components/rd-components/rd-paginator.vue'
import {
  getEmployees,
  updateEmployee,
  getSHAvailableStatus,
  bulkUpdateEmployees
} from '@/services/smarthire/smarthire.api'
import { convertToCSV } from '@/utils/convertFormat'
import { getRoleText } from '@/utils/utils.js';
import { downloadCSV } from '@/utils/handleFile'
import RdInput from '../../components/rd-components/rd-input.vue';
import { getAllExams } from '../../services/exams/exam.api'
export default {
  components: {
    RdFooter,
    RdNavbar,
    RdLoading,
    RdPaginator,
    RdInput
  },
  data() {
    return {
      screenLoading: false,
      employees: [],
      employeesDataTemp: [],
      statusAvailable: [],
      availibilitySelected: null,
      statusSelected: null,
      showBulkLoadModal: false,
      file: null,
      search: '',
      examsAvailable: [],
      percentage: null,
      examSelected: null,
      operator: null,
      examScoreOptions: [
        { name: '0-10', value: [0, 10] },
        { name: '11-20', value: [11, 20] },
        { name: '21-30', value: [21, 30] },
        { name: '31-40', value: [31, 40] },
        { name: '41-50', value: [41, 50] },
        { name: '51-60', value: [51, 60] },
        { name: '61-70', value: [61, 70] },
        { name: '71-80', value: [71, 80] },
        { name: '81-90', value: [81, 90] },
        { name: '91-100', value: [91, 100] },
      ],
      forceRender: true,
    }
  },
  async mounted() {
    await this.getEmployees()
    await this.getStatus()
    await this.getExams()
  },
  computed: {
    filteredEmployees() {
      let tempEmployees = JSON.parse(JSON.stringify(this.employees))
      if (this.statusSelected !== null) {
        tempEmployees = tempEmployees.filter(({ status }) => {
          return status.code === this.statusSelected
        })
      }
      if (this.availibilitySelected !== null) {
        tempEmployees = tempEmployees.filter(({ isAvailable }) => {
          return isAvailable === this.availibilitySelected
        })
      }

      if (this.examSelected !== null) {
        tempEmployees = tempEmployees.filter(({ exams }) => {
          return exams.some(({ id }) => id === +(this.examSelected))
        })
      }

      if (
        this.examSelected !== null
        && this.operator!== null
        && this.percentage !== null
        && this.percentage !== ''
      ) {
        tempEmployees = tempEmployees.filter(({ exams }) => {
          const exam = exams.filter(({ id }) => id == this.examSelected )
          if(!(exam.length)) {
            return false;
          }
          const {score} = exam[0]
          if (this.operator == '=') {
            return score == this.percentage
          }
          if (this.operator == '<') {
            return score < this.percentage
          }
          if (this.operator == '<=') {
            return score <= this.percentage
          }
          if (this.operator == '>') {
            return score > this.percentage
          }
          if (this.operator == '>=') {
            return score >= this.percentage
          }
          return (
            false
          )
        })
      }

      if (this.search !== '') {
        tempEmployees = tempEmployees.filter(({ userEmail, userName }) => {
          return (
            userName.toLowerCase().includes(this.search.toLowerCase())
            || userEmail.toLowerCase().includes(this.search.toLowerCase())
          )
        })
      }

      return tempEmployees
    },
  },
  methods: {
    calcOperator(e) {
      if(!e) {
        this.operator = null;
        return;
      }
      this.operator = e.value;
    },
    calcStatusSelected(e) { 
      if(!e) {
        this.statusSelected = null;
        return;
      }
      this.statusSelected = e.value;
    },
    calcAvailability(e) { 
      if(!e) {
        this.availibilitySelected = null;
        return;
      }
      this.availibilitySelected = e.value;
    },
    calcExam(e) { 
      if(!e) {
        this.examSelected = null;
        return;
      }
      this.examSelected = e.value;
    },
    
    resize() {
      this.$refs.table_resize.style.width = '100%'
      const elements = document.querySelectorAll("table th");
      elements.forEach((el) => {
        el.style.width = 'auto'
      })
      document.querySelectorAll("table tbody tr td").forEach((td) => {
        td.style.width = '14.28%'
      })
    },
    handleFileSelect(e) {
      this.file = e.target.files[0];
    },
    async handleUploadFile() {
      try {
        const fileData = new FormData();
        fileData.append('file', this.file);
        
        await bulkUpdateEmployees({ file: fileData });

        // re-fetch employees
        await this.getEmployees();

        this.$store.dispatch('dispatchNotification', {
          title: 'Success!',
          message: 'Bulk Load was sucessful.',
          type: 'success',
        });
      } catch (error) {
        this.$store.dispatch('dispatchNotification', {
          title: 'Error!',
          message: 'Bulk Load was not created.',
          type: 'error',
        });
      } finally {
        this.showBulkLoadModal = false
      }
    },
    handleUploadCSV() {
      this.showBulkLoadModal = true
    },
    async handleDownloadCSV() {
      try {
        const data = this.filteredEmployees.map(({ status, ...rest }) => ({
          ...rest,
          smartHireStatus: status.name,
        }))
        const temp = JSON.parse((JSON.stringify(data)));
        temp.forEach((item) => {
          delete item.exams;
          item.role = getRoleText(item.roleId)
          delete item.roleId;
        })
        const csv = await convertToCSV({ data: temp, separator: ',' })
        downloadCSV({ data: csv, filename: 'smarthire_status' })
      } catch (error) {
        console.log(error)
      }
    },
    clearFilters() {
      this.statusSelected = null
      this.availibilitySelected = null
      this.examSelected = null
      this.search = ''
      this.operator = null
      this.percentage = null
      this.$refs['select-exam'].selectValue = null
      this.$refs['select-status'].selectValue = null
      this.$refs['select-availability'].selectValue = null
      if(this.$refs['select-operator-logic']) {
        this.$refs['select-operator-logic'].selectValue = null
      }
    },
    async getStatus() {
      try {
        const { data } = await getSHAvailableStatus();
        this.statusAvailable = data.map(({
          statusName, id
        }) => ({
          name: statusName, code: id
        }))
      } catch (error) {
        console.log(error)
      }
    },
    async getExams() {
      try {
        const { data } = await getAllExams();
        const exams = data.map(({
          examName, examId
        }) => ({
          id: examId, name: examName
        }));
        this.examsAvailable = exams
      } catch (error) {
        console.log(error)
      }
    },
    async getEmployees() {
      try {
        this.screenLoading = true
        const { data } = await getEmployees();
        this.employees = data.map(({ status, ...employee}) => ({
          ...employee,
          status: { name: status.statusName, code: status.id },
        }))
      } catch (error) {
        console.log(error)
      } finally {
        this.screenLoading = false
        await this.$nextTick(() => {
          const elements = document.querySelectorAll("table th");
          elements.forEach((el, index) => {
            el.style.position = "relative";
            el.id = index
            var grip = document.createElement("div");
            grip.innerHTML = "&nbsp;";
            grip.style.borderRight = 'black solid 1px'
            grip.style.top = 0;
            grip.style.right = 0;
            grip.style.bottom = 0;
            grip.style.width = "5px";
            grip.style.position = "absolute";
            grip.style.cursor = "col-resize";
            grip.addEventListener("mousedown", (e) => {
              this.thElm = el;
              this.startOffset = el.offsetWidth - e.pageX;
              this.lastWidth = 0
            });
            el.appendChild(grip);
          })
          document.addEventListener("mousemove", (e) => {
            if (this.thElm) {
              this.lastWidth  = this.thElm.offsetWidth;
              this.thElm.style.width = this.startOffset + e.pageX + "px";
              document.querySelectorAll("table tbody tr td").forEach((td, internalIndex) => {
                if((internalIndex % 7) == Number(this.thElm.id)) {
                  td.style.width = this.startOffset + e.pageX + "px";
                } else {
                  td.style.width = td.offsetWidth + "px";
                }
              })
              this.$refs.table_resize.style.width = this.$refs.table_resize.offsetWidth + ((this.thElm.offsetWidth - this.lastWidth)) + "px"
            }
          });

          document.addEventListener("mouseup", () => {
            this.thElm = undefined;
          });
        });
      }
    },
    handlePageChange(data) {
      this.employeesDataTemp = data;
    },
    async saveEmployeeChanges(e, id) {
      e.stopPropagation();
      const userToChange = this.employees.find(employee => employee.userId === id);
      const { isAvailable, status: { code } } = userToChange
      try {
        await updateEmployee({
          id: id,
          payload: {
            isAvailable: isAvailable,
            smartHireStatusId: code
          }
        })
        this.$store.dispatch('dispatchNotification', {
          title: 'Success!',
          message: 'User updated.',
          type: 'success',
        });
      } catch (error) {
        console.log(error)
        this.$store.dispatch('dispatchNotification', {
          title: 'Error!',
          message: 'User could not be updated.',
          type: 'error',
        });
      }
    },
    async handleUserStatusChange(e, id) {
      this.forceRender = false;
      const status = this.statusAvailable.find(({ code }) => code == e.target.value)
      this.employees.forEach(employ => {
        if (+employ.userId === +id) {
          employ.status = { name: status.name, code: status.code }
        }
      });
      await this.$nextTick();
      this.forceRender = true;
    },
    async handleUserAvailabilityChange(e, id) {
      this.forceRender = false;
      const { checked } = e.target
      const emp = this.employees.map(employee => {
        if (+employee.userId === +id) {
          employee.isAvailable = checked
        }
        return {...employee}
      })
      this.employees = emp;
      await this.$nextTick();
      this.forceRender = true;
    },
  }
}
</script>

<style lang="scss" scoped>
.fill-ods {
  background-color: #f5f5f5;
}
.backScreen {
  background: rgb(0, 0, 0, 0.7);
  min-height: 100vh;
  min-width: 100vw;
}

.modalPosition {
  max-height: 100%;
  overflow-y: scroll;
}

.hoverable:hover {
  cursor: pointer;
}
.hoverable:hover {
  cursor: pointer;
}

thead {
  border-top: #e0e0e0 solid 1px;
  border-right: #e0e0e0 solid 1px;
  border-left: #e0e0e0 solid 1px;
}
tr {
  border-top: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  border-left: 1px solid #e0e0e0;
}
tbody {
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
}
td {
  min-height: 50px;
}
th {
  min-height: 50px;
  height: 50px;
}
.table-primary {
  tr {
    th {
      vertical-align: middle;
    }
  }
}
.centered {
  text-align: center;
  vertical-align: middle;
}
.hoverable {
  td {
    vertical-align: middle;
  }
}
th {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}
td {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}
</style>