<template>
  <div class="d-flex flex-column justify-content-between vh-100 unselectable">
    <div>
      <rd-navbar />

      <div v-if="!exam">
        <rd-loading /> 
      </div>

      <div v-else class="container bg-light rounded-3 my-4 p-3">

        <template v-if="!started">
          <div class="row text-start">
            <div class="col-12 ps-4">
              <p class="fs-1 fw-bold my-0 py-0">{{ exam.examName }}</p>
              <p v-if="!!exam.examDescription" class="fs-6 my-0 py-0">{{ exam.examDescription }}</p>
            </div>
          </div>

          <div
            v-if="!!instructions"
            class="row w-100 mt-4 mx-1"
          >
            <div class="col-12">
              <quill-editor
                v-model:value="instructions"
                disabled
                :options="{
                  contentType: 'html',
                  modules: {
                    toolbar: '',
                  },
                  readOnly: true,
                }"
              />
            </div>
          </div>
  
          <div class="row justify-content-between mt-4">
            <div class="col-lg-3 col-6 text-start">
              <button
                class="btn btn-lg btn-outline-primary me-3"
                @click="$router.back()"
              >
                {{ $t('Back') }}
              </button>
            </div> 
  
            <div class="col-lg-3 col-6 text-end">
              <button 
                class="btn btn-lg btn-primary"
                @click="handleStartExam"
              >
                {{ $t('Start Exam') }}
              </button>
            </div>
          </div>
        </template>

        <template v-if="examTime && started && !showResults">
          <div class="countdown text-end">
            <rd-countdown
              :minutes="examTime"
              @over="countdownOver"
            />
          </div>
        </template>

        <template v-if="started && !finished && !showResults">
          <div
            class="my-4"
            :class="{
              'mx-5': !isInternalMobile,
            }"
          >
            <div class="text-break">
              <span class="fs-3 fw-bold">{{ questions[questionNumber].questionText }}</span>
              <br>
              <button
                class="btn btn-lg btn-link text-decoration-none mx-2"
                @click="handleFlagQuestion"
              >
                <i data-bs-toggle="tooltip" data-bs-placement="right" title="Flag Question" type="button" :class="questions[questionNumber].flagged ? 'bi bi-flag-fill' : 'bi bi-flag'"></i>
                {{ questions[questionNumber].flagged ? $t('Unmark question') : $t('Mark question') }}
              </button>
            </div>
            <div
              class="d-flex flex-column align-items-start my-5"
              :class="{
                'mx-5': !isInternalMobile,
              }"
              v-if="!(questions[questionNumber].isMultiple === true)"
            >
              <div class="w-100" style="display:flex; justify-content: center;" v-if="questions[questionNumber].hasImage">
                <img style="max-width: 50%;" :src="questions[questionNumber].image" alt="">
              </div>
              <div
                v-for="answer of questions[questionNumber].answers" :key="answer.text"
                class="mt-4 w-100 text-start hoverable"
                @click="!countdownFinished ? picked = answer.text : null"
              >
                <div
                  class="border-3 rounded-3 w-100 p-2 hoverable d-flex"
                  :class="{
                    'bg-picked-question': answer.text === picked,
                    'bg-question': answer.text !== picked,
                  }"
                >
                  <input
                    class="form-check-input px-2 mx-2 bg-primary hoverable"
                    :disabled="countdownFinished"
                    :class="{
                      'bg-picked-question': answer.text !== picked,
                      'bg-question': answer.text === picked,
                    }"
                  type="radio" :id="answer.text" :value="answer.text" v-model="picked">
                  <label class="form-check-label mx-1 text-start text-break hoverable" :for="answer.text">{{ answer.text }}</label>
                </div>
              </div>
            </div>
            <div
              class="d-flex flex-column align-items-start my-5"
              :class="{
                'mx-5': !isInternalMobile,
              }"
              v-else
            >
              <div class="w-100" style="display:flex; justify-content: center;" v-if="questions[questionNumber].hasImage">
                <img class="image-question" :src="questions[questionNumber].image" alt="">
              </div>
              <div
                v-for="answer of questions[questionNumber].answers" :key="answer.text"
                class="mt-4 w-100 text-start hoverable"
                @click="!countdownFinished? handlePickeMultiple(answer.text) : null"
              >
                <div
                  class="border-3 rounded-3 w-100 p-2 hoverable d-flex"
                  :class="{
                    'bg-picked-question': pickedMultiple.includes(answer.text),
                    'bg-question': !pickedMultiple.includes(answer.text),
                  }"
                >
                  <input
                    class="form-check-input px-2 mx-2 bg-primary hoverable"
                    :disabled="countdownFinished"
                    :class="{
                      'bg-picked-question': pickedMultiple.includes(answer.text),
                      'bg-question': !pickedMultiple.includes(answer.text),
                    }"
                  type="checkbox" :id="answer.text" :value="answer.text" v-model="pickedMultiple">
                  <label class="form-check-label mx-1 text-start text-break hoverable" :for="answer.text">{{ answer.text }}</label>
                </div>
              </div>
            </div>
          </div>

          <div class="row justify-content-end">
            <div v-if="questionNumber !== 0" class="col-6 col-md-2 col-lg-2 text-start order-1 col-sm-6 order-md-0">
              <button
                class="btn btn-lg btn-outline-primary me-3 w-100"
                @click="goToPreviousQuestion"
              >
                {{ $t('Previous') }}
              </button>
            </div>

            <div class="col-12 col-md-8 col-lg-8 my-2 order-0 order-md-1">
              <span
                v-for="({ qstNro, flagged, isMultiple }, index) of questions" :key="index"
              >
                <button
                  v-if="!isMultiple"
                  class="btn btn-sm m-1"
                  :class="{
                    'btn-outline-primary': qstNro === questionNumber,
                    'btn-success': qstNro !== questionNumber && answers.some(a => a.qstNro === qstNro && a.answer !== null),
                    'btn-warning': qstNro !== questionNumber && answers.some(a => a.qstNro === qstNro && a.answer === null),
                    'flagged': flagged
                  }"
                  @click="handleJumpToQuestion(qstNro)"
                >
                  {{ qstNro + 1 }}
                </button>
                <button
                  v-else
                  class="btn btn-sm m-1"
                  :class="{
                    'btn-outline-primary': qstNro === questionNumber,
                    'btn-success': qstNro !== questionNumber && answers.some(a => a.qstNro === qstNro && (a.answer && a.answer.length)),
                    'btn-warning': qstNro !== questionNumber && answers.some(a => a.qstNro === qstNro && (!a.answer || !(a.answer.length))),
                    'flagged': flagged
                  }"
                  @click="handleJumpToQuestion(qstNro)"
                >
                  
                  {{ qstNro + 1 }}
                </button>
              </span>
            </div>

            <div
            
              class="col-6 col-md-2 col-lg-2 text-end order-2 col-sm-6 order-md-2"
            >
              <button 
                class="btn btn-lg btn-primary w-100"
                @click="goToNextQuestion"
              >
                {{ !!this.lastQuestion ? $t('Finish')  :  $t('Next') }}
              </button>
            </div>
          </div>
        </template>

        <template v-if="finished && !showResults">
          <div class="fs-2 fw-bold my-4">
            {{ $t('Review your answers') }}
          </div>
          <div class="row p-3 d-flex justify-content-start">
            <div style="overflow-x: auto; width: 100%; max-width: 100%;">
              <div class="w-100">

                <table class="table text-start table-hover" ref="table_resize2">
                  <thead @contextmenu.prevent="resize2" class="table-primary">
                    <tr>
                      <th @click="sortTable('qstNro', 'qstNro')" scope="col">
                        {{ $t('ID') }} <i data-bs-toggle="tooltip" data-bs-placement="right" title="Sort Table" type="button" :class="tableSorting.qstNro ? 'bi bi-arrow-up-short' : 'bi bi-arrow-down-short'" />
                      </th>
                      <th @click="sortTable('question', 'question')" scope="col">
                        {{ $t('Question (table)') }}
                        <i data-bs-toggle="tooltip" data-bs-placement="right" title="Sort Table" type="button" :class="tableSorting.question ? 'bi bi-arrow-up-short' : 'bi bi-arrow-down-short'" />
                      </th>
                      <th @click="sortTable('answer', 'answer')" scope="col">
                        {{ $t('Answer (table)') }}
                        <i data-bs-toggle="tooltip" data-bs-placement="right" title="Sort Table" type="button" :class="tableSorting.answer ? 'bi bi-arrow-up-short' : 'bi bi-arrow-down-short'" />
                      </th>
                      <th scope="col" class="text-center">{{ ' ' }}</th>
                    </tr>
                  </thead>
                  <tbody class="text-start">
                    <tr
                      v-for="({question, answer, qstNro}, index) in dataTemp" :key="index"
                      class="text-start"
                      :class="{ 'fill-ods': index % 2 !== 0 }"
                      :style="{
                        'background-color': (!exam.examAllowsEmpty && (handleAnswer(answer) == $t('NO ANSWER'))) ? '#FFFF7D' : '',
                      }"
                    >
                      <td style="width: 7%; word-break: break-all;" :header="$t('ID')" scope="row">{{ qstNro +1 }}</td>
                      <td style="width: 40%; word-break: break-all;" :header="$t('Question (table)')" >{{ question }}</td>
                      <td style="width: 40%; word-break: break-all;" :header="$t('Answer (table)')" >  <p class="p-0 m-0" v-html="handleAnswer(answer)"></p></td>
                      <td style="width: 13%; word-break: break-all;" :header="$t(' ')" class="centered">
                        <p @click="handleReviewAnswer(qstNro)" class="mb-0 text-primary cursor-pointer">
                          {{ !isInternalMobile? $t('Review') : '' }}
                          <i data-bs-toggle="tooltip" data-bs-placement="right" title="Review" type="button" class="bi bi-box-arrow-right mx-1"></i>
                        </p> 
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <rd-paginator :items="answers" @page-change="handlePageChange" />
          </div>

          <div class="row justify-content-between mt-4">
            <div class="col-lg-3 col-6 text-start">
              <button
                v-if="!countdownFinished"
                class="btn btn-lg btn-outline-primary"
                @click="goToPreviousQuestion"
              >
                {{ $t('Back') }}
              </button>
            </div>
  
            <div class="col-lg-3 col-6 text-end">
              <button 
                class="btn btn-lg btn-primary"
                @click="submitExam"
              >
                {{ $t('Submit') }}
              </button>
            </div>
          </div>
        </template>

        <template v-if="showResults">
          <template v-if="!showReviewAnswer">
            <div class="row justify-content-end">
              <div class="col-12 col-md-6 col-lg-6">
                <pie-chart-component
                  :data="[
                    {
                      category: $t('Correct'),
                      value: results.score
                    }, {
                      category: $t('Incorrect'),
                      value: (results.questions - results.score),
                    }
                  ]"
                  :label="`${results.score} / ${results.questions}`"
                  height="300px"
                />
              </div>
              <div class="col-12 col-md-3 col-lg-3 p-4">
                <div class="row fs-4 fw-bold">
                  {{ $t('Exam Summary') }}:
                </div> 
                <div class="row justify-content-start">
                  <p class="text-start m-0 p-0">
                    <span class="fw-bold">{{ $t('Correct answers') }}:</span> {{ results.score }}
                  </p>
                </div> 
                <div class="row justify-content-start">
                  <p class="text-start m-0 p-0">
                    <span class="fw-bold">{{ $t('Minimum completion percentage')  }} :  </span> {{ results.percentage }}%
                  </p>
                </div> 
                <div class="row justify-content-start">
                  <p class="text-start m-0 p-0">
                    <span class="fw-bold">{{ $t('Completion percentage') }}: </span> {{ results.completionPercent }}%
                  </p>
                </div> 
                <div class="row justify-content-start">
                  <p class="text-start m-0 p-0">
                    <span class="fw-bold">{{ $t('Exam Status') }}:</span> {{ results.approved ? $t('Passed') : $t('Failed') }}
                  </p>
                </div> 
                <div v-if="(!user.isAdmin  && !user.isTrainer)" class="row justify-content-start">
                  <p class="text-start m-0 p-0">
                    <span class="fw-bold">{{ $t('Exam Attempt') }}:</span> {{ results.tryNumber }}
                  </p>
                </div> 
                <div v-if="!!results.attempts" class="row justify-content-start">
                  <p class="text-start m-0 p-0">
                    <span class="fw-bold">{{ $t('Exam Attempts Remaining') }}:</span> {{ results.attempts - results.tryNumber }}
                  </p>
                </div> 
                <div v-if="!!results.reviewAnswer && !!results.feedback.length" class="row justify-content-start">
                  <button
                    type="button"
                    class="btn btn-link text-start text-primary m-0 p-0"
                    @click="showOrHideReviewAnswer"
                  >
                      {{ $t('Review Answer') }}
                  </button>
                </div> 
              </div>
            </div>
  
            <div class="row justify-content-center">
              <div class="col-12 col-md-8 col-lg-8">
                <p class="fs-3 fw-bold">
                  {{ results.approved
                    ? $t('Congratulations on passing your exam! Well done!')
                    : $t(`Hey, I know the exam didn't go as you expected. Cheer up, keep going, you can do it!`)
                  }}
                </p>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-12 col-md-8 col-lg-8">
                <p class="fs-3 fw-bold">
                  {{ $t('Score') }}:  {{ (Number(results.score) / Number(results.questions)).toFixed(2) * 100 }}%
                </p>
              </div>
            </div>
  
            <div class="row justify-content-between mt-4">
              <div class="col-lg-3 col-6 text-start">
                <button
                  v-if="showRepeatExamBtn"
                  class="btn btn-lg btn-outline-primary"
                  @click="repeatExam"
                >
                  {{ $t('Repeat') }}
                </button>
              </div>
    
              <div class="col-lg-3 col-6 text-end">
                <button 
                  class="btn btn-lg btn-primary"
                  @click="handleDoneExam"
                >
                  {{ $t('Done') }}
                </button>
              </div>
            </div>
          </template>

          <!-- mostar detalles de las preguntas y respuestas si el caso lo permite -->
          <template v-else>
            <div class="row p-4">
              <div class="col-12">
                <div class="row my-2">
                  <button
                    type="button"
                    class="btn btn-link text-start text-primary m-0 p-0"
                    @click="showOrHideReviewAnswer"
                  >
                    <i data-bs-toggle="tooltip" data-bs-placement="right" title="Back to Summary" type="button" class="bi bi-arrow-left"></i> {{ $t('Back to Summary') }}
                  </button>
                </div>
                <div class="w-100">
                  <div style="overflow-x: auto; width: 100%; max-width: 100%;">
                    <table class="table text-start table-hover" ref="table_resize">
                      <thead
                        @contextmenu.prevent="resize"
                        class="table-primary"
                      >
                        <tr class="text-center">
                          <th scope="col">{{ $t('Status. (table)') }}</th>
                          <th @click="sortReviewTable('questionId', 'questionId')" scope="col">{{ $t('ID (table)') }} <i data-bs-toggle="tooltip" data-bs-placement="right" title="Sort Table" type="button" :class="reviewTableSorting.questionId ? 'bi bi-arrow-up-short' : 'bi bi-arrow-down-short'" /> </th>
                          <th @click="sortReviewTable('questionText', 'questionText')" scope="col">{{ $t('Question (table)') }} <i data-bs-toggle="tooltip" data-bs-placement="right" title="Sort Table" type="button" :class="reviewTableSorting.questionText ? 'bi bi-arrow-up-short' : 'bi bi-arrow-down-short'" /> </th>
                          <th scope="col">{{ $t('Answer (table)') }}</th>
                          <th scope="col">{{ $t('Correct Answer (table)') }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(feedback, index) in reviewAnswerDataTemp" :key="index"
                          class="text-center hoverable"
                          :class="{
                            'fill-ods': index % 2 !== 0,
                          }"
                        >
                          <td style="width: 10%; word-break: break-all; vertical-align: middle;" scope="row" :header="$t('ID (table)')" class="word-wrap" >
                            <img
                              v-if="listsAreEqual(feedback.userAnswer, feedback.correctAnswer)"
                              src="/assets/check.png"
                              style="width: 2rem;"
                              alt="right answer"
                            >
                            <img
                              v-else
                              src="/assets/wrong.png"
                              style="width: 2.5rem;"
                              alt="wrong answer"
                            >
                          </td>
                          <td style="width: 10%; word-break: break-all; vertical-align: middle;" scope="row" :header="$t('ID (table)')" class="word-wrap" >
                            {{ feedback.questionId }}
                          </td>
                          <td style="width: 50%; word-break: normal; vertical-align: middle; text-align: center;" scope="row" :header="$t('Question (table)')" >
                            {{ feedback.questionText }}
                          </td>
                          <td style="width: 20%; word-break: break-all; vertical-align: middle;" scope="row" :header="$t('Answer (table)')" >
                            <p class="p-0 m-0" v-html="handleAnswer(feedback.userAnswer)"></p>
                          </td>
                          <td style="width: 20%; word-break: break-all; vertical-align: middle;" scope="row" :header="$t('Correct Answer (table)')">
                            <p class="p-0 m-0" v-html="handleAnswer(feedback.correctAnswer)"></p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <rd-paginator :items="results.feedback" @page-change="handleReviewPageChange($event, index)" />
                </div>
              </div>
            </div>
          </template>
        </template>

      </div>
    </div>
    <div>
      <rd-footer />
    </div>
  </div>
</template>
<script>
import { getExamById, getScore, userCanPerformExam } from '@/services/exams/exam.api';
import RdNavbar from '@/components/rd-components/rd-navbar.vue';
import RdFooter from '@/components/rd-components/rd-footer.vue';
import { decodeFromBase64 } from '../../utils/utils';
import RdLoading from '@/components/rd-components/rd-loading.vue';
import RdCountdown from '@/components/rd-components/rd-countdown.vue';
import { mapState } from 'vuex';
import PieChartComponent from '../../components/amCharts/pie-chart-component.vue';
import RdPaginator from '../../components/rd-components/rd-paginator.vue';
import { logStartExam } from '../../services/exams/exam.api';
import { fileExamView } from '@/utils/handleFile.js';

export default {
  components: {
    RdNavbar,
    RdFooter,
    RdLoading,
    RdCountdown,
    PieChartComponent,
    RdPaginator,
  },
  data() {
    return {
      exam: {},
      examTime: null,
      questions: [],
      instructions: null,
      started: false,
      finished: false,
      questionNumber: 0,
      picked: null,
      pickedMultiple: [],
      answers: [],
      results: {},
      showReviewAnswer: false,
      alertInfo: {
        showNotification: false,
        alertTitle: '',
        alertMessage: '',
        throwsError: false
      },
      countdownFinished: false,
      dataTemp: [],
      reviewAnswerDataTemp: [],
      tableSorting: {
        qstNro: false,
        question: false,
        answer: false,
      },
      reviewTableSorting: {
        questionId: false,
        questionText: false,
      },
      thElm: null,
      thElm2: null,
      startOffset: null,
      startOffset2: null,
      lastWidth: 0,
      lastWidth2: 0
    }
  },
  computed: {
    ...mapState(['isInternalMobile', 'user']),
    lastQuestion() {
      return this.questionNumber === (this.questions.length - 1);
    },
    nextButton() {
      return !this.exam.examAllowsEmpty && !(this.picked || this.pickedMultiple.length);
    },
    showResults() {
      return !!Object.values(this.results).length;
    },
    showRepeatExamBtn() {
      if (this.user.isAdmin || this.user.isTrainer) return true;
      if (this.results.approved) return false;
      if (!!this.results.attempts && this.results.attempts - this.results.tryNumber > 0) return true;
      return false;
    },
  },
  async mounted() {
    try {
      const { id: examId } = this.$route.params;
      const { userId } = this.$store.state.user;
      const { data } = await userCanPerformExam(examId, userId);
      const { allowed, message, approved } = data;
      if (!allowed) {
        if (message) {
          // notify the user that no more remainings are available
          this.$store.dispatch('dispatchNotification', {
            title: 'Notification',
            message: message,
            type: approved ? 'success' : 'error',
          })
        }
        this.$router.back();
      }
    } catch (error) {
      console.log(error);
    }
    await this.getExam();
  },
  watch: {
    questionNumber(nro) {
      const found = this.answers.find(ans => ans.qstNro === nro)
      if(!(this.questions[this.questionNumber].isMultiple === true)) {
        this.picked = found.answer
        this.pickedMultiple = []
      } else {
        this.picked = null
        this.pickedMultiple = found.answer ?? []
      }
    },
  },
  methods: {
    async addResize() {
      await this.$nextTick(() => {
         const elements = document.querySelectorAll("table th");
         elements.forEach((el, index) => {
          el.style.position = "relative";
          el.id = index
          var grip = document.createElement("div");
          grip.innerHTML = "&nbsp;";
          grip.style.borderRight = 'black solid 1px'
          grip.style.top = 0;
          grip.style.right = 0;
          grip.style.bottom = 0;
          grip.style.width = "5px";
          grip.style.position = "absolute";
          grip.style.cursor = "col-resize";
          grip.addEventListener("mousedown", (e) => {
            this.thElm2 = el;
            this.startOffset2 = el.offsetWidth - e.pageX;
            this.lastWidth2 = 0
          });
          el.appendChild(grip);
         })
         document.addEventListener("mousemove", (e) => {
           if (this.thElm2) {
             this.lastWidth2  = this.thElm2.offsetWidth;
             this.thElm2.style.width = this.startOffset2 + e.pageX + "px";
              document.querySelectorAll("table tbody tr td").forEach((td, internalIndex) => {
                if((internalIndex % 4) == Number(this.thElm2.id)) {
                  td.style.width = this.startOffset2 + e.pageX + "px";
                } else {
                  td.style.width = td.offsetWidth + "px";
                }
              })
              this.$refs.table_resize2.style.width = this.$refs.table_resize2.offsetWidth + ((this.thElm2.offsetWidth - this.lastWidth2)) + "px"
           }
         });
          document.addEventListener("mouseup", () => {
            this.thElm2 = undefined;
          });
      });
    },
    async showOrHideReviewAnswer() {
      this.showReviewAnswer = !this.showReviewAnswer
      if (this.showReviewAnswer) {
        await this.$nextTick(() => {
           const elements = document.querySelectorAll("table th");
           elements.forEach((el, index) => {
            el.style.position = "relative";
            el.id = index
            var grip = document.createElement("div");
            grip.innerHTML = "&nbsp;";
            grip.style.borderRight = 'black solid 1px'
            grip.style.top = 0;
            grip.style.right = 0;
            grip.style.bottom = 0;
            grip.style.width = "5px";
            grip.style.position = "absolute";
            grip.style.cursor = "col-resize";
            grip.addEventListener("mousedown", (e) => {
              this.thElm = el;
              this.startOffset = el.offsetWidth - e.pageX;
              this.lastWidth = 0
            });

            el.appendChild(grip);
           })
           document.addEventListener("mousemove", (e) => {
             if (this.thElm) {
               this.lastWidth  = this.thElm.offsetWidth;
               this.thElm.style.width = this.startOffset + e.pageX + "px";
                document.querySelectorAll("table tbody tr td").forEach((td, internalIndex) => {
                  if((internalIndex % 5) == Number(this.thElm.id)) {
                    td.style.width = this.startOffset + e.pageX + "px";
                  } else {
                    td.style.width = td.offsetWidth + "px";
                  }
                })
                this.$refs.table_resize.style.width = this.$refs.table_resize.offsetWidth + ((this.thElm.offsetWidth - this.lastWidth)) + "px"
             }
           });

            document.addEventListener("mouseup", () => {
              this.thElm = undefined;
            });
        });
      }
    },
    listsAreEqual(userAnswers, correctAnswers) {
      correctAnswers.sort()
      userAnswers.sort()
      if (userAnswers.length !== correctAnswers.length) return false;
      const equalLists = userAnswers.every((value, index) => value === correctAnswers[index]);
      return equalLists
    },
    hideReviewAnswer() {
      this.showReviewAnswer = false
    },
    handlePickeMultiple(text) {
      if(!this.pickedMultiple.includes(text)) {
        this.pickedMultiple.push(text)
      } else {
        this.pickedMultiple = this.pickedMultiple.filter(element => element !== text);
      }
    },
    handleAnswer(answer) {
      if (Array.isArray(answer)) {
        if(answer.length) {
          return answer.join('<br>')
        }
        return this.$t('NO ANSWER');
      }
      return answer || this.$t('NO ANSWER');

    },
    resize2() {
      this.$refs.table_resize2.style.width = '100%'
      const elements = document.querySelectorAll("table th");
      elements.forEach((el) => {
        el.style.width = 'auto'
      })
      document.querySelectorAll("table tbody tr td").forEach((td, index) => {
        if(index % 4 == 0) {
          td.style.width = '7%'
        }
        else if(index % 4 == 1 || index % 4 == 2) {
          td.style.width = '40%'
        } else {
          td.style.width = '13%'
        }
      })
    },
    resize() {
      this.$refs.table_resize.style.width = '100%'
      const elements = document.querySelectorAll("table th");
      elements.forEach((el) => {
        el.style.width = 'auto'
      })
      document.querySelectorAll("table tbody tr td").forEach((td, index) => {
        if(index % 5 == 0 || index % 5 == 1) {
          td.style.width = '10%'
        }
        else if(index % 5 == 2) {
          td.style.width = '50%'
        } else {
          td.style.width = '20%'
        }
      })
    },
    async handlePageChange(data) {
      this.dataTemp = data;
    },
    async handleReviewPageChange(data) {
      this.reviewAnswerDataTemp = data;
    },
    sortTable(param, key) {
      let collator = new Intl.Collator()
      this.tableSorting[param] = !this.tableSorting[param]
      if (this.tableSorting[param]) {
        this.answers.sort((a, b) => collator.compare(a[`${key}`], b[`${key}`])) 
      } else {
        this.answers.sort((a, b) => collator.compare(b[`${key}`], a[`${key}`])) 
      }
    },
    sortReviewTable(param, key) {
      let collator = new Intl.Collator()
      this.reviewTableSorting[param] = !this.reviewTableSorting[param]
      if (this.reviewTableSorting[param]) {
        this.reviewAnswerDataTemp.sort((a, b) => collator.compare(a[`${key}`], b[`${key}`])) 
      } else {
        this.reviewAnswerDataTemp.sort((a, b) => collator.compare(b[`${key}`], a[`${key}`])) 
      }
    },
    handleReviewAnswer(index) {
      // order answer by qstNro again
      this.answers.sort((a, b) => a.qstNro - b.qstNro)

       // save answer bedore jumping to the selected
      if (this.answers[this.questionNumber]) {
        this.answers = this.answers.map((ans, index2) => {
          if (ans.qstNro === this.questionNumber) {
            ans.question = this.questions.find(q => q.qstNro === this.questionNumber).questionText;
            ans.answer = !(this.questions[index2].isMultiple === true) ? this.picked : this.pickedMultiple
          }
          return ans;
        })
      }
      this.questionNumber = +index;
      this.finished = false;
    },
    async getExam() {
      try {
        const { data } = await getExamById(this.$route.params.id);
        const { exam, questions } = data;
        // si el examen aun no tiene preguntas subidas, volver a la pantalla anterior
        if (questions.length === 0) {
          this.$router.back();
          return;
        }
        this.exam = exam;
        this.examTime = Number(exam.examDuration);
        this.questions = questions.map((q, i) => ({ ...q, qstNro: +i }));
        this.answers = questions.map((q, i) => ({ qstNro: +i, question: q.questionText, answer: null, flagged: false }));
        for (let index = 0; index < this.questions.length; index++) {
          const element = this.questions[index];
          if(element.hasImage) {
            const fileData = await fileExamView(element.questionId)
            const fileResponse = window.URL.createObjectURL(fileData);
            this.questions[index]['image'] = fileResponse
          }
        }
        const html = await decodeFromBase64(exam.examInstuctions);
        this.instructions = JSON.parse(html);
      } catch (error) {
        console.log(error);
      }
    },
    async handleStartExam() {
      try {
        await logStartExam({ exam: this.exam });
        this.started = true;
      } catch (error) {
        console.log(error)
      }
    },
    goToPreviousQuestion() {
      // if (this.countdownFinished || this.questionNumber === 0) {
      //   this.$store.dispatch('dispatchNotification', {
      //     title: 'Notification',
      //     message: 'Exam cancelled',
      //     type: 'error',
      //   })
      //   // TODO: add alert to confirm
      //   this.$router.back();
      //   return;
      // }
      this.answers = this.answers.map(ans => {
        if (ans.qstNro === this.questionNumber) {
          ans.question = this.questions.find(q => q.qstNro === this.questionNumber).questionText;
          ans.answer = !(this.questions[this.questionNumber].isMultiple === true) ? this.picked : this.pickedMultiple
        }
        return ans;
      })
      if (this.finished) {
        this.questionNumber = this.questions.length - 1;
        this.finished = false;
        return
      }
      this.questionNumber = this.questionNumber - 1;
    },
    goToNextQuestion() {
      this.answers = this.answers.map(ans => {
        if (ans.qstNro === this.questionNumber) {
          ans.question = this.questions.find(q => q.qstNro === this.questionNumber).questionText;
          ans.answer = !(this.questions[this.questionNumber].isMultiple === true) ? this.picked : this.pickedMultiple
        }
        return ans;
      })
      // finish
      if (this.lastQuestion) {
        this.finished = true;
        this.addResize();
        return;
      }
      this.questionNumber = this.questionNumber + 1;
    },
    async submitExam() {
      try {
        // if the exam does not allow empty answers
        if (!this.exam.examAllowsEmpty && this.answers.some(a => !a.answer) && !this.countdownFinished) {
          // throws notification
          this.$store.dispatch('dispatchNotification', {
            title: 'Check',
            message: 'Questions cannot be blank',
            type: 'error',
          })
          return;
        }
        const { user: { userId } } = this.$store.state;
        const validAnswers = this.answers.filter((a) => {
          if(Array.isArray(a.answer)) {
            return !!a.answer.length
          }
          return !!a.answer
        });
        const payload = {
          answers: validAnswers,
          examId: this.exam.examId,
          userId,
        }
        const { data } = await getScore(payload);
        const {
          score,
          questions,
          approved,
          completionPercent,
          try: tryNumber,
          examAttempts: attempts,
          reviewAnswer,
          feedback,
          percentage
        } = data;
        this.results = {
          score,
          questions,
          approved,
          tryNumber,
          attempts,
          reviewAnswer,
          feedback,
          percentage: Number(percentage).toFixed(2) * 100,
          completionPercent: completionPercent.toFixed(2),
        };
        this.$store.dispatch('dispatchNotification', {
          title: 'Notification',
          message: 'Exam submitted',
          type: 'success',
        })
      } catch (error) {
        console.log(error);
        this.$store.dispatch('dispatchNotification', {
          title: 'Error',
          message: 'No more attempts remaining',
          type: 'error',
        })
      }
    },
    repeatExam() {
      this.$router.go();
    },
    handleDoneExam() {
      const { isAdmin, isTrainer } = this.$store.state.user;
      if (isAdmin || isTrainer) this.$router.push('/admin/exam');
      else this.$router.push('/files');
    },
    handleJumpToQuestion(index) {
      const found = this.answers.find(a => a.qstNro === this.questionNumber);
      if (found) {
        this.answers = this.answers.map(ans => {
          if (ans.qstNro === this.questionNumber) {
            ans.question = this.questions.find(q => q.qstNro === this.questionNumber).questionText;
            ans.answer = !(this.questions[this.questionNumber].isMultiple === true) ? this.picked : this.pickedMultiple;
          }
          return ans;
        })
      }
      this.questionNumber = index;
    },
    countdownOver() {
      this.answers = this.answers.map(ans => {
        if (ans.qstNro === this.questionNumber) {
          ans.question = this.questions.find(q => q.qstNro === this.questionNumber).questionText,
          ans.answer = !(this.questions[this.questionNumber].isMultiple === true) ? this.picked : this.pickedMultiple;
        }
        return ans;
      })
      this.finished = true;
      this.addResize();
      this.countdownFinished = true;
    },
    handleFlagQuestion() {
      this.questions = this.questions.map(q => {
        if (q.qstNro === this.questionNumber) {
          q.flagged = !q.flagged;
        }
        return q;
      })
    },
  }
}
</script>
<style lang="scss" scoped>
@media (max-width: 768px) {
  .flex-column {
    flex-direction: column !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 2 !important;
  }

  .order-md-2 {
    order: 1 !important;
    margin-bottom: 1rem;
  }
}
.bg-question {
  background-color: #eee9fb;
}

.bg-picked-question {
  background-color: #a890e8;
}
.countdown {
  font-size: 1.5rem;
  color: #000;
}
.flagged {
  position: relative;
}
.flagged::before {
  content: '';
  position: absolute;
  top: -0.3rem;
  left: -0.3rem;
  border-radius: 50%;
  width: .7rem;
  height: .7rem;
  background: #ff0000df;
}
.border-3 {
  border: 1px solid #000;
}
.cursor-pointer {
  cursor: pointer;
}
.fill-ods {
  background-color: #f5f5f5;
}
thead {
  border-top: #e0e0e0 solid 1px;
  border-right: #e0e0e0 solid 1px;
  border-left: #e0e0e0 solid 1px;
}
tr {
  border-top: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  border-left: 1px solid #e0e0e0;
}
tbody {
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
}
td {
  min-height: 50px;
}
th {
  min-height: 50px;
  height: 50px;
}
.table-primary {
  tr {
    th {
      vertical-align: middle;
    }
  }
}
.centered {
  text-align: center;
  vertical-align: middle;
}
.hoverable {
  td {
    vertical-align: middle;
  }
}
th {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}
td {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}
.image-question {
  max-width: 20%;
}
@media (max-width: 768px) {
  .image-question {
    max-width: 50%;
  }
}
</style>