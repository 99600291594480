<template>
  <div class="d-flex flex-column justify-content-between vh-100 unselectable">
    <div>
      <rd-navbar />
      <div class="container my-3 p-3">
        <div v-if="loading">
          <rd-loading /> 
        </div>

        <div v-else class="p-3">
          <div class="row">
            <div class="col-12">
              <h3 class="text-start">{{ $t('Exam statistics') }}</h3>
            </div>
          </div>
          <div class="row p-3 justify-content-between">
            <div
              class="p-0 m-0 mb-2 col-12 col-lg-4"
            >
              <rd-input
                class="w-100"
                :placeholder="$t('Search exam by name')" 
                type="text"
                @input="(e) => this.search = e.target.value"
              />
            </div>
  
            <button 
              class="btn btn-outline-primary col-12 col-lg-2" 
              @click="downloadGeneralExamStats">
              <i data-bs-toggle="tooltip" data-bs-placement="right" title="Download Stats" type="button" class="bi bi-arrow-down-circle-fill"></i> {{ $t('Download Stats') }}
            </button>
          </div>
          <div
            v-if="filteredStats.length === 0" 
            class="row p-3"
          >
            <h2>
              {{ $t('No data found') }} <i data-bs-toggle="tooltip" data-bs-placement="right" title="No stats Found" type="button" class="bi bi-exclamation-triangle"></i>
            </h2>
          </div>
          <div v-else class="row mt-3 d-flex justify-content-start">
            <div style="overflow-x: auto; width: 100%; max-width: 100%;">
              <div class="w-100">
                <table class="table text-start table-hover" ref="table_resize">
                  <thead class="table-primary" @contextmenu.prevent="resize">
                    <tr>
                      <th scope="col">{{ $t('Exam (table)') }}</th>
                      <th scope="col">{{ $t('Assigned (table)') }}</th>
                      <th scope="col">{{ $t('Pending (table)') }}</th>
                      <th scope="col">{{ $t('In Progress (table)') }}</th>
                      <th scope="col">{{ $t('Failed (table)') }}</th>
                      <th scope="col">{{ $t('Approved (table)') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(attempt, index) in dataTemp" :key="index"
                      class="text-start hoverable"
                      :class="{ 'fill-ods': index % 2 !== 0 }"
                    >
                      <td style="width: 16.6%; word-break: break-all;" @click="goToExamStats(attempt.examId)" scope="row" :header="$t('Exam (table)')" class="word-wrap" >{{ attempt.examName }}</td>
                      <td style="width: 16.6%; word-break: break-all;" scope="row" :header="$t('Assigned (table)')" >
                        {{ attempt.assigned }}
                      </td>
                      <td style="width: 16.6%; word-break: break-all;" scope="row" :header="$t('Pending (table)')" >
                        {{ attempt.pending }}
                      </td>
                      <td style="width: 16.6%; word-break: break-all;" scope="row" :header="$t('In Progress (table)')" >
                        {{ attempt.in_progress }}
                      </td>
                      <td style="width: 16.6%; word-break: break-all;" scope="row" :header="$t('Failed (table)')" >
                        {{ attempt.failed }}
                      </td>
                      <td style="width: 16.6%; word-break: break-all;" scope="row" :header="$t('Approved (table)')" >
                        {{ attempt.approved }}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <rd-paginator :forceRender="false" :items="filteredStats" @page-change="handlePageChange($event, index)" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <rd-footer />
    </div>
  </div>
</template>
<script>
import router from '@/router';
import RdFooter from '@/components/rd-components/rd-footer.vue';
import RdNavbar from '@/components/rd-components/rd-navbar.vue';
import RdInput from '@/components/rd-components/rd-input.vue'
import RdLoading from '@/components/rd-components/rd-loading.vue';
import { getGeneralStats } from '@/services/exams/exam.api.js';
import { convertToCSV } from '@/utils/convertFormat';
import { downloadCSV } from '@/utils/handleFile';
import { ATTEMPT_STATUS } from '@/utils/constants';
import RdPaginator from '@/components/rd-components/rd-paginator.vue'

export default {
  data() {
    return {
      stats: null,
      loading: true,
      ATTEMPT_STATUS,
      examTitle: null,
      search: '',
      dataTemp: [],
    }
  },
  components: {
    RdFooter,
    RdNavbar,
    RdLoading,
    RdInput,
    RdPaginator
  },
  async mounted() {
    await this.getExamsStats();
  },
  methods: {
    async handlePageChange(data) {
      this.dataTemp = data;
    },
    goToExamStats(id) {
      router.push(`/admin/exam/stats/${id}`);
    },
    async downloadGeneralExamStats() {
      try {
        const csv = await convertToCSV({ data: this.filteredStats });
        downloadCSV({ data: csv, filename: 'general_stats'});
      } catch (error) {
        console.log(error);
      }
    },
    async getExamsStats() {
      this.loading = true;
      try {
        const { data } = await getGeneralStats();
        
        this.stats = data.map(obj => ({
          ...obj,
          assigned: parseInt(obj.approved) + parseInt(obj.failed) + parseInt(obj.in_progress) +  parseInt(obj.pending)
        }));

      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
    filteredStats() {
      return this.stats.filter(
          exam => exam.examName.toLowerCase().includes(this.search.trim().toLowerCase())
        )
    },
  }
}
</script>