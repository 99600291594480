import httpClient from "../httpClient";

const END_POINT = "/api/bulk";
const config = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};

const bulkImport = (entity, file) =>
  httpClient.post(`${END_POINT}/${entity}`, file, config);
const bulDownload = async (entity) => await httpClient.get(`${END_POINT}/${entity}`)

export { bulkImport, bulDownload };
