<template lang="">
  <div class="d-flex flex-column justify-content-between vh-100">
    <div>
      <rd-navbar :title="$t('Files (nav)')" />
      <div class="container bg-light my-3 p-3">
        <div class="row">
          <div class="col-12">
            <h2 class="ms-4 text-start">{{ $t('Edit File') }}</h2>
          </div>
        </div>
        <div class="container">

          <div class="row mb-4">
            <div class="col-sm-12 col-md-6">
              <rd-input
                :placeholder="$t('file name')" 
                type="text"
                :label="$t('Enter file name')"
                required
                :value="fileName"
                @input="(e) => this.fileName = e.target.value"
              />
            </div>
          </div>

          <div class="row mb-4">
            <div class="col-sm-12 col-md-6">
              <rd-input
                type="select"
                :placeholder="$t('Choose a category')" 
                :placeholderToTag="$t('Add this category')"
                :label="$t('Enter file category')"
                :items="categoriesOfDB"
                required
                :initialValue="selectedCategory"
                @handleAddTagging="handleCategorySelect"
              />
            </div>
          </div>

          <div class="row mb-4">
            <div class="col-sm-12 col-md-6">
              <rd-input
                type="select"
                :placeholder="$t('Choose a subcategory')" 
                :placeholderToTag="$t('Add this subcategory')"
                :label="$t('Enter the file subcategory')"
                :items="subCategoriesOfDB"
                required
                :initialValue="selectedSubcategory"
                @handleAddTagging="(subcategory) => this.selectedSubcategory = subcategory"
              />
            </div>
          </div>
          
          <div class="row mb-4">
            <div class="col-sm-12 col-md-6">
              <rd-input
                type="text"
                :placeholder="$t('Choose a language')"
                :label="$t('Enter the file language')"
                required
                :value="selectedLanguage"
                @input="(e) => this.selectedLanguage = e.target.value"
              />
            </div>
          </div>

          <div class="row mb-4">
            <div class="col-sm-12 col-md-6">
              <rd-input
                type="text"
                :placeholder="$t('Description')"
                :label="$t('Enter the file description')"
                :value="description"
                @input="(e) => this.description = e.target.value"
              />
            </div>
          </div>  

          <div class="row mb-4">
            <div class="col-sm-12 col-md-6">
              <table width="100%">
                <tr>
                  <td>
                    <label class="labelText">
                      {{ $t('Type of file') }}
                    </label>
                  </td>
                </tr>
                <tr>
                  <td>
                    <rd-input
                      :disable="fileSelected"
                      type="file"
                      :label="$t('Choose a file')"
                      required
                      :value="file"
                      :showError="showFileError"
                      :errorMsg="fileErrorMsg"
                      @input="handleFileSelect"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="form-check d-flex">
                      <input class="form-check-input me-2" type="checkbox" v-model="fileSelected" id="flexCheckDefault">
                      <label class="form-check-label" for="flexCheckDefault">
                        {{ $t(`It's an external source?`) }}
                      </label>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <rd-input
                      v-if="fileSelected"
                      type="text"
                      :placeholder="$t('Link to the external source')"
                      :label="$t('Enter link to the external source')"
                      :value="externalSourceLink"
                      required
                      @input="handleLinkSelect"
                    />
                  </td>
                </tr>
              </table>
            </div>
          </div>

          <div class="row mb-4">
          <div class="col-12 col-lg-3">
            <table>
              <tr>
                <td>
                  <div class="row no-gutters pb-4 labelText h3">
                    {{ $t('File visibility') }}
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <rd-input
                    refs="file-visibility"
                    type="radio"
                    name="file-visibility"
                    :items="[
                      {label: $t('Visible (opt)'), value:'1'},
                      {label: $t('Hidden (opt)'),value:'0'}
                    ]"
                    :checked="isVisible ? 1 : 0"
                    @input="(e) => this.isVisible = e.target.value == 1"
                  />
                </td>
              </tr>
            </table>
            </div>
          </div>

          <div class="my-3 row justify-content-end">
            <div class="col-lg-3 col-md-6 col-sm-6">
              <button class="btn btn-outline-primary me-3" @click="goBack">
                {{ $t('Dismiss') }}
              </button>
              <button :disabled="disableSaveButton" class="btn btn-primary" @click="editFile">
                {{ $t('Edit File (btn)') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <rd-footer/>
    </div>
  </div>
</template>
<script>
import RdFooter from '../../components/rd-components/rd-footer.vue';
import RdNavbar from '../../components/rd-components/rd-navbar.vue';
import RdInput from '@/components/rd-components/rd-input.vue'
import router from '@/router'
import { getFileById, editFileById, acceptFile, deleteFileById } from '@/services/files/file.api.js'
import { getAllCategory, getCategoryByProfile } from '@/services/categories/categories.api.js'
import { notAllowedFileExtensions } from '../../utils/constants';

export default {
  data() {
    return {
      fileSelected: false,
      categoriesOfDB: [],
      subCategoriesOfDB: [],
      languagesOfDB: [],
      fileName: '',
      selectedCategory: null,
      selectedSubcategory: null,
      selectedLanguage: '',
      description: '',
      externalSourceLink: '',
      isPublic: false,
      isVisible: 1,
      file: null,
      disableSaveButton: false,
      fileId: null,
      showFileError: false,
      fileErrorMsg: '',
      hasSpinner: true,
    }
  },
  async beforeMount() {
    await this.setCategories()
    await this.setFileData()
  },
  async mounted() {
    this.$watch(
      () => {
        return [this.fileName, this.selectedCategory, this.selectedSubcategory, this.selectedLanguage, this.file, this.externalSourceLink, this.showFileError]
      },
      ([fileName, selectedCategory, selectedSubcategory, selectedLanguage, file, externalSourceLink, showFileError]) => {
        if (fileName === '' || selectedCategory === '' || selectedSubcategory === '' || selectedLanguage === '' || (file === '' && externalSourceLink === '') || showFileError) {
          this.disableSaveButton = true
        } else { 
          this.disableSaveButton = false
        }
      } 
    );
  },
  components: {
    RdFooter,
    RdNavbar,
    RdInput,
  },
  computed: {
    fileUUID() {
      return this.$route.params.uuid
    },
    user(){
      return this.$store.getters.getUser;
    },
  },
  methods: {
    async setFileData() {
      try {
        const {data} = await getFileById(this.fileUUID)
        this.fileId = data.fileId
        this.fileName = data.fileName
        this.selectedLanguage = data.fileLanguage
        this.description = data.fileDescription
        if (data.fileExternalSource) {
          this.externalSourceLink = data.fileExternalSource
          this.fileSelected = true
        }
        this.isPublic = data.filePublic
        this.isVisible = data.fileVisibility

        // set current file category
        for (const category of this.categoriesOfDB) {
          if (category.value == data.categoryId) {
            this.selectedCategory = category
          }
        }

        // set current file subcategory
        const sub = this.selectedCategory.subcategories
          .find(sub => sub.subCategoryId == data.subCategoryId)
        
        if (sub) {
          this.selectedSubcategory = {
            value: sub.subCategoryId,
            name: sub.subCategoryName
          }
        }

      } catch (error) {
        console.log(error)
      }
    },
    async setCategories() {
      try {
        let {isAdmin, userId} = this.user

        let {data} = isAdmin 
          ? await getAllCategory()
          : await getCategoryByProfile(userId, 'show_hidden')
          
        this.categoriesOfDB = data.map(category => {
          return {
            value: category.categoryId, 
            name: category.categoryName, 
            subcategories: category.subcategories
          }
        })
      } catch (error) {
        console.log(error)
      }
    },
    async editFile(){
      this.hasSpinner = true;
      setTimeout(() => {
          if(this.hasSpinner) {
            this.$store.commit('setStateLoad', true)
          }
        }, 200);
      try {
        let file = new FormData();
        if (this.externalSourceLink != '') {
          file.append('fileExternalSource', this.externalSourceLink);
          file.append('filePath', '')
        }
        if (this.file) {
          file.append('fileExternalSource', '');
          file.append('file', this.file)
        }
        file.append('fileName', this.fileName);
        file.append('categoryId', this.selectedCategory.value);
        file.append('subCategoryId', this.selectedSubcategory.value);
        file.append('fileLanguage', this.selectedLanguage);
        file.append('fileDescription', this.description);
        file.append('filePublic', this.isPublic);
        file.append('fileVisibility', this.isVisible);

        const {data} = await editFileById(this.fileId, file)
        this.hasSpinner = false;
        if(!data.isTemp) {
          this.$store.dispatch('dispatchNotification', {
            title: 'Success!',
            message: 'File edited.',
            type: 'success',
          });
          router.back()
          return;
        }
        const {fileId} = data;
        this.$swal.fire({
          title: "Would you like to save this file? The file extension you provided does not match the file's format",
          showDenyButton: true,
          confirmButtonText: 'Save',
          denyButtonText: `Don't save`,
          allowEscapeKey: false,
          allowOutsideClick: false,
          allowEnterKey: false
        }).then(async (result) => {
          if (result.isConfirmed) {
            await acceptFile(fileId);
            this.$swal.fire('Saved!', '', 'success')
          } else if (result.isDenied) {
            await deleteFileById(fileId);
            this.$swal.fire('The file was not saved', '', 'info')
          }
          setTimeout(() => {
            router.back()
          }, 1000);
        })
      } catch (error) {
        this.$store.dispatch('dispatchNotification', {
          title: 'Error!',
          message: 'Could not edit this file.',
          type: 'error',
        });
        console.log(error)
      } finally {
        this.$store.commit('setStateLoad', false)
      }
    },
    goBack() {
      router.back()
    },
    handleLinkSelect(event) {
      this.file = ""
      this.externalSourceLink = event.target.value
    },
    validateExt(fileName) {
      let result = false
      for (const extention of notAllowedFileExtensions) {
        if (fileName.endsWith(extention)) {
          result = true
          break
        }
      }
      return result
    },
    handleFileSelect(event) {
      this.showFileError = false
      if (this.validateExt(event.target.files[0].name)) {
        this.showFileError = true
        this.fileErrorMsg = 'File type not supported'
        return
      }
      this.externalSourceLink = ""
      this.file = event.target.files[0]
    },
    handleCategorySelect(category) {
      this.selectedCategory = category
      const {subcategories} = this.categoriesOfDB.find(cat => parseInt(cat.value) === parseInt(this.selectedCategory.value))
      this.subCategoriesOfDB = subcategories.map(subcategory => (
        { value: subcategory.subCategoryId, name: subcategory.subCategoryName }
      ));
    },
  },
}
</script>
<style scoped>
.labelText {
  margin: 0;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: grey;
  float: left;
  border-radius: 4px;
}
</style>