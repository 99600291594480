<template>
  <div class="d-flex flex-column justify-content-between vh-100">
    <div>
      <rd-navbar :title="$t('Users (nav)')" />
      <div class="container bg-light my-3 p-3">
        <div class="row">
          <div class="col-12">
            <h2 class="ms-4 text-start">{{ $t('Users') }}</h2>
          </div>
        </div>
        <div class="row p-3 justify-content-between">
          <!-- Search bar -->
          <div
            class="p-0 m-0 mb-2 col-12 col-lg-4"
          >
            <rd-input
              class="w-100"
              :placeholder="$t('Search user by first name, last name or username')" 
              type="text"
              @input="(e) => this.search = e.target.value"
            />
          </div>
          <button 
            class="btn btn-primary col-12 col-lg-2" 
            @click="goToAddNewUser">
            <i data-bs-toggle="tooltip" data-bs-placement="right" title="Add User" type="button" class="bi bi-plus-circle-fill"></i> {{ $t('Add User (btn)') }}
          </button>
        </div>

        <div v-if="userLoading">
          <rd-loading />
        </div>

        <div v-else>
          
          <div
            v-if="filteredUsers.length === 0" 
            class="row p-3"
          >
            <h2>
              {{ $t('No data found') }} <i data-bs-toggle="tooltip" data-bs-placement="right" title="No users Found" type="button" class="bi bi-exclamation-triangle"></i>
            </h2>
          </div>
          <div v-else class="row p-3">
            <div style="overflow-x: auto; width: 100%; max-width: 100%;">
              <div class="w-100">
                <table class="table text-start table-hover" ref="table_resize">
                  <thead class="table-primary" @contextmenu.prevent="resize">
                    <tr>
                      <th @click="sortTable('clickFirstName', 'userFirstname')" scope="col">{{ $t('First name (table)') }} <i data-bs-toggle="tooltip" data-bs-placement="right" title="Sort Table" type="button" :class="tableSorting.clickFirstName ? 'bi bi-arrow-up-short' : 'bi bi-arrow-down-short'" /></th>
                      <th @click="sortTable('clickLastName', 'userLastname')" scope="col">{{ $t('Last name (table)') }} <i data-bs-toggle="tooltip" data-bs-placement="right" title="Sort Table" type="button" :class="tableSorting.clickLastName ? 'bi bi-arrow-up-short' : 'bi bi-arrow-down-short'" /></th>
                      <th @click="sortTable('clickUsername', 'userName')" scope="col">{{ $t('Username (table)') }} <i data-bs-toggle="tooltip" data-bs-placement="right" title="Sort Table" type="button" :class="tableSorting.clickUsername ? 'bi bi-arrow-up-short' : 'bi bi-arrow-down-short'" /></th>
                      <th @click="sortTable('clickRole', 'roleId')" scope="col">{{ $t('role (table)') }} <i data-bs-toggle="tooltip" data-bs-placement="right" title="Sort Table" type="button" :class="tableSorting.clickRole ? 'bi bi-arrow-up-short' : 'bi bi-arrow-down-short'" /></th>
                      <th scope="col" class="text-center">{{ $t('Actions (table)') }}</th>
                    </tr>
                  </thead>
                  <tbody class="text-start">
                    <tr
                      v-for="(user, index) in dataTemp" :key="index"
                      class="hoverable"
                      :class="{ 'fill-ods': index % 2 !== 0 }"
                    >
                      <td style="width: 20%; word-break: break-all;" :header="$t('First name (table)')" scope="row">{{ user.userFirstname || '-' }}</td>
                      <td style="width: 20%; word-break: break-all;" :header="$t('Last name (table)')" >{{ user.userLastname || '-'}}</td>
                      <td style="width: 20%; word-break: break-all;" :header="$t('Username (table)')" >{{ user.userName }}</td>
                      <td style="width: 20%; word-break: break-all;" :header="$t('role (table)')" >{{ getRoleText(user.roleId) }}</td>
                      <td style="width: 20%; word-break: break-all;" :header="$t('Actions (table)')" class="centered">
                        <button 
                          class="btn btn-label" 
                          @click="goToEditUser(user.userId)"
                        >
                          <i data-bs-toggle="tooltip" data-bs-placement="right" title="Edit User" type="button" class="bi bi-pencil-square"></i>
                        </button>
                        <button 
                          class="btn btn-label" 
                          @click="goToUserProfile(user.userId)"
                        >
                          <i data-bs-toggle="tooltip" data-bs-placement="right" title="User Profile" type="button" class="bi bi-eye-fill"></i>
                        </button>
                        <button 
                          class="btn btn-label" 
                          @click="showModal($event, user)"
                        >
                          <i data-bs-toggle="tooltip" data-bs-placement="right" title="Delete User" type="button" class='bi bi-trash-fill'></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <rd-paginator :items="filteredUsers" @page-change="handlePageChange" />
          </div>

        </div>
      </div>
    </div>
    <div>
      <rd-footer/>
    </div>

    <rd-confirmation-modal 
      v-if="showModalProp"
      modalTitle="Delete user permanently?"
      confirmButton="Delete"
      dismissButton="Dismiss"
      @confirmAction="deleteUser(userToDelete)"
      @dismissAction="dismissAction"
    />
  </div>
</template>
<script>
import RdFooter from '../../components/rd-components/rd-footer.vue';
import RdNavbar from '../../components/rd-components/rd-navbar.vue';
import router from '@/router'
import { getAllUsers, deleteUserById } from '@/services/user/user.api.js'
import RdConfirmationModal from '../../components/rd-components/rd-confirmation-modal.vue';
import RdLoading from '../../components/rd-components/rd-loading.vue';
import RdInput from '../../components/rd-components/rd-input.vue';
import RdPaginator from '@/components/rd-components/rd-paginator.vue'
import { getRoleText } from '../../utils/utils';

export default {
  data() {
    return {
      users: [],
      userLoading: true,
      showModalProp: false,
      userToDelete: {},
      search: '',
      dataTemp: [],
      tableSorting: {
        clickFirstName: false,
        clickLastName: false,
        clickUsername: false,
        clickRole: false,
      },
      thElm: null,
      startOffset: null,
      lastWidth: 0,
      getRoleText,
    }
  },
  computed: {
    filteredUsers() {
      return this.users.filter( user => 
        (user.userFirstname && user.userFirstname.toLowerCase().includes(this.search.trim().toLowerCase())) || 
        (user.userLastname && user.userLastname.toLowerCase().includes(this.search.trim().toLowerCase())) ||
        user.userName.toLowerCase().includes(this.search.trim().toLowerCase())
      )
    }
  },
  async mounted() {
    await this.setUsers()
  },
  components: {
    RdFooter,
    RdNavbar,
    RdConfirmationModal,
    RdLoading,
    RdInput,
    RdPaginator
  },
  methods: {
    resize() {
      this.$refs.table_resize.style.width = '100%'
      const elements = document.querySelectorAll("table th");
      elements.forEach((el) => {
        el.style.width = 'auto'
      })
      document.querySelectorAll("table tbody tr td").forEach((td) => {
        td.style.width = '20%'
      })
    },
    sortTable(param, key) {
      this.tableSorting[param] = !this.tableSorting[param]
      if(!this.tableSorting[param]) {
        this.users.reverse();
        return; 
      }
      if(key === 'roleId') {
        this.users.sort((a, b) => getRoleText(a[`${key}`]).localeCompare(getRoleText(b[`${key}`])))
        return;
      }
      this.users.sort((a, b) => a[`${key}`].localeCompare(b[`${key}`])) 
    },
    async handlePageChange(data) {
      this.dataTemp = data;
    },
    showModal(e, user){
      e.stopPropagation()
      this.showModalProp = true
      this.userToDelete = user
    },
    dismissAction() {
      this.userToDelete = {}
      this.showModalProp = false
    },
    async deleteUser({userId}){
      try {
        await deleteUserById(userId)
        await this.setUsers()
      } catch (error) {
        console.log(error)
      } finally {
        this.showModalProp = false
      }
    },
    async setUsers() {
      try {
        this.userLoading = true
        const {data} = await getAllUsers()
        this.users = data
      } catch (error) {
        console.log(error)
      } finally {
        this.userLoading = false
        await this.$nextTick(() => {
           const elements = document.querySelectorAll("table th");
           elements.forEach((el, index) => {
            el.style.position = "relative";
            el.id = index

            var grip = document.createElement("div");
            grip.innerHTML = "&nbsp;";
            grip.style.borderRight = 'black solid 1px'
            grip.style.top = 0;
            grip.style.right = 0;
            grip.style.bottom = 0;
            grip.style.width = "5px";
            grip.style.position = "absolute";
            grip.style.cursor = "col-resize";
            grip.addEventListener("mousedown", (e) => {
              this.thElm = el;
              this.startOffset = el.offsetWidth - e.pageX;
              this.lastWidth = 0
            });

            el.appendChild(grip);
           })
           document.addEventListener("mousemove", (e) => {
             if (this.thElm) {
               this.lastWidth  = this.thElm.offsetWidth;
               this.thElm.style.width = this.startOffset + e.pageX + "px";
                document.querySelectorAll("table tbody tr td").forEach((td, internalIndex) => {
                  if((internalIndex % 5) == Number(this.thElm.id)) {
                    td.style.width = this.startOffset + e.pageX + "px";
                  } else {
                    td.style.width = td.offsetWidth + "px";
                  }
                })
                this.$refs.table_resize.style.width = this.$refs.table_resize.offsetWidth + ((this.thElm.offsetWidth - this.lastWidth)) + "px"
             }
           });

            document.addEventListener("mouseup", () => {
              this.thElm = undefined;
            });
        });
      }
    },
    goToAddNewUser(){
      router.push(`/admin/user/add/`)
    },
    goToEditUser(id) {
      router.push(`/admin/user/edit/${id}`)
    },
    goToUserProfile(id){
      router.push(`/admin/user/${id}`)
    },
  },
}
</script>
<style lang="scss" scoped>
.hoverable:hover {
  cursor: pointer;
}
.hoverable:hover {
  cursor: pointer;
}
.fill-ods {
  background-color: #f5f5f5;
}
thead {
  border-top: #e0e0e0 solid 1px;
  border-right: #e0e0e0 solid 1px;
  border-left: #e0e0e0 solid 1px;
}
tr {
  border-top: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  border-left: 1px solid #e0e0e0;
}
tbody {
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
}
td {
  min-height: 50px;
}
th {
  min-height: 50px;
  height: 50px;
}
.table-primary {
  tr {
    th {
      vertical-align: middle;
    }
  }
}
.centered {
  text-align: center;
  vertical-align: middle;
}
.hoverable {
  td {
    vertical-align: middle;
  }
}
th {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}
td {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}
</style>