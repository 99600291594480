<template>
  <div class="d-flex flex-column justify-content-between vh-100">
    <div>
      <rd-navbar />
      <div class="container bg-light rounded-3 my-3 p-3">
        <div class="row">
          <div class="col-12">
            <h2 class="ms-4 text-start">{{ $t('SmartHire Status') }}</h2>
          </div>
        </div>
        <div class="row p-3 justify-content-between">
          <!-- Search bar -->
          <div
            class="p-0 m-0 mb-2 col-12 col-lg-3"
          >
            <rd-input
              class="w-100"
              :placeholder="$t('Search by name or description')" 
              type="text"
              @input="(e) => this.search = e.target.value"
            />
          </div>

          <!-- buttons -->
          <div class="p-3 m-0 mb-2 col-12 col-lg-6">
            <div class="row justify-content-end gap-1">

              <div class="p-0 m-0 mb-2 col-12 col-lg-5">
                <button
                  class="btn btn-primary w-100"
                  @click="goToAddNewStatus"
                >
                  <i data-bs-toggle="tooltip" data-bs-placement="right" title="Add Status" type="button" class="bi bi-plus-circle-fill"></i> {{ $t('Add Status') }}
                </button>
              </div>
              
            </div>
          </div>
        </div>

        <div v-if="screenLoading">
          <rd-loading /> 
        </div>
        <div v-else>
          <div
            v-if="status.length === 0" 
            class="row p-3"
          >
            <h2>
              {{ $t('No data found') }} <i data-bs-toggle="tooltip" data-bs-placement="right" title="No status Found" type="button" class="bi bi-exclamation-triangle"></i>
            </h2>
          </div>

          <div v-else class="row p-3 d-flex justify-content-start">
            <div style="overflow-x: auto; width: 100%; max-width: 100%;">
              <div class="w-100">
                <table class="table text-start table-hover" ref="table_resize">
                  <thead class="table-primary">
                    <tr>
                      <th scope="col">{{ $t('Status (table)') }}</th>
                      <th scope="col">{{ $t('Description (table)') }}</th>
                      <th scope="col" class="text-center">{{ $t('Actions (table)') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="({ id, statusName, description }, index) in statusDataTemp" :key="index"
                      class="text-start hoverable"
                      :class="{ 'fill-ods': index % 2 !== 0 }"
                    >
                      <td style="width: 33%; word-break: break-all;" scope="row" :header="$t('Status (table)')" class="word-wrap" >{{ statusName }}</td>
                      <td style="width: 33%; word-break: break-all;" scope="row" :header="$t('Description (table)')" >{{ description }}</td>
                      <td style="width: 33%; word-break: break-all;" :header="$t('Actions (table)')">
                        <div class="container">
                          <div class="row text-center">
                            <div class="col p-0 m-0">
                              <button 
                                class="btn btn-label"
                                @click="goToEditStatus($event, id)"
                              >
                                <i data-bs-toggle="tooltip" data-bs-placement="right" title="Edit Status" type="button" class="bi bi-pencil-square"></i>
                              </button>

                              <button 
                                class="btn btn-label" 
                                @click="showDeleteModal($event, id)"
                              >
                                <i data-bs-toggle="tooltip" data-bs-placement="right" title="Delete Status" type="button" class='bi bi-trash-fill'></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            
            <rd-paginator :items="filteredStatus" @page-change="handlePageChange" />
          </div>
        </div>
      </div>
    </div>
    <div>
      <rd-footer />
    </div>
  </div>
  <rd-confirmation-modal
    v-if="showModalProp"
    modalTitle="Delete status permanently?"
    confirmButton="Delete"
    dismissButton="Dismiss"
    @confirmAction="deleteUser(statusToDelete)"
    @dismissAction="dismissAction"
  />
</template>
<script>
import RdConfirmationModal from '../../components/rd-components/rd-confirmation-modal.vue'
import rdFooter from '../../components/rd-components/rd-footer.vue'
import RdInput from '../../components/rd-components/rd-input.vue'
import RdLoading from '../../components/rd-components/rd-loading.vue'
import RdNavbar from '../../components/rd-components/rd-navbar.vue'
import RdPaginator from '../../components/rd-components/rd-paginator.vue'
import router from '../../router'
import { deleteSHStatus, getSHAvailableStatus } from '../../services/smarthire/smarthire.api'

export default {
  components: { rdFooter, RdInput, RdNavbar, RdLoading, RdPaginator, RdConfirmationModal },
  data() {
    return {
      screenLoading: false,
      status: [],
      search: '',
      statusDataTemp: [],
      showModalProp: false,
      statusToDelete: null,
    }
  },
  async mounted() {
    await this.getStatus()
  },
  computed: {
    filteredStatus() {
      return this.status.filter(({ statusName, description }) => (
        statusName.toLowerCase().includes(this.search.toLowerCase())
        || description && description.toLowerCase().includes(this.search.toLowerCase())
      ))
    },
  },
  methods: {
    async getStatus() {
      try {
        this.screenLoading = true
        const { data } = await getSHAvailableStatus();
        this.status = data
      } catch (error) {
        console.log(error)
      } finally {
        this.screenLoading = false
      }
    },
    dismissAction() {
      this.showModalProp = false
      this.statusToDelete = null
    },
    showDeleteModal(_, id) {
      this.showModalProp = true
      this.statusToDelete = id
    },
    async deleteUser(id) {
      try {
        await deleteSHStatus({ id })
        this.$store.dispatch('dispatchNotification', {
          title: 'Success!',
          message: 'Status deleted.',
          type: 'success',
        })
        this.showModalProp = false
        this.statusToDelete = null
        await this.getStatus()
      } catch (error) {
        this.$store.dispatch('dispatchNotification', {
          title: 'Failed!',
          message: 'Error deleting Status.',
          type: 'Error',
        })
        console.log(error)
      }
    },
    handlePageChange(data) {
      this.statusDataTemp = data;
    },
    goToEditStatus(e, id) {
      e.preventDefault();
      router.push(`/admin/smarthire/status/edit/${id}`)
    },
    goToAddNewStatus() {
      router.push('/admin/smarthire/status/create');
    },
    showModal(e, id) {
      e.preventDefault();
      console.log(`delete status with ${id} flow`)
    },
  },
}
</script>