<template lang="">
  <div class="d-flex flex-column justify-content-between vh-100">
    <div>
      <rd-navbar :title="$t('Categories (nav)')" />
      <div class="container bg-light my-3 p-3">
        <div class="row">
          <div class="col-12">
            <h2 class="ms-4 text-start">{{ $t('Edit Category') }}</h2>
          </div>
        </div>
        
        <div class="row mb-4">
          <div class="col-sm-12 col-md-6">
            <rd-input
              type="text"
              :placeholder="$t('Category name')" 
              :label="$t('Enter the category name')"
              required
              :value="categoryName"
              @input="(e) => this.categoryName = e.target.value"
            />
          </div>
        </div>

        <div class="my-3 row justify-content-end">
          <div class="col-lg-4 col-md-6 col-sm-6">
            <button class="btn btn-outline-primary me-3" @click="goBack">
              {{ $t('Dismiss') }}
            </button>
            <button :disabled="disableSaveButton" class="btn btn-primary" @click="editCategory">
              {{ $t('Edit Category (btn)') }}
            </button>
          </div>
        </div>

      </div>
    </div>
    <div>
      <rd-footer/>
    </div>
  </div>
</template>
<script>
import RdFooter from '../../components/rd-components/rd-footer.vue';
import RdNavbar from '../../components/rd-components/rd-navbar.vue';
import RdInput from '@/components/rd-components/rd-input.vue'
import router from '@/router'
import { getCategoryById, editCategoryById } from '@/services/categories/categories.api.js'

export default {
  data() {
    return {
      categoryName: '',
      disableSaveButton: false,
    }
  },
  computed: {
    categoryId() {
      return this.$route.params.id
    }
  },
  async mounted() {
    await this.setCategoryData()
    this.$watch(
      () => {
        return [this.categoryName]
      },
      ([categoryName]) => {
        if (categoryName === '') {
          this.disableSaveButton = true
        } else {
          this.disableSaveButton = false
        }
      } 
    );
  },
  components: {
    RdFooter,
    RdNavbar,
    RdInput,
  },
  methods: {
    async setCategoryData() {
      try {
        const { data } = await getCategoryById(this.categoryId)
        this.categoryName = data.category.categoryName
      } catch (error) {
        console.log(error)
      }
    },
    async editCategory() {
      const category = {
        categoryName: this.categoryName
      }
      try {
        await editCategoryById(this.categoryId, category)
        this.$store.dispatch('dispatchNotification', {
          title: 'Success!',
          message: 'Category edited',
          type: 'success',
        });
        router.back()
      } catch (error) {
        this.$store.dispatch('dispatchNotification', {
          title: 'Error!',
          message: 'Category was not created.',
          type: 'error',
        });
        console.log(error)
      }
    },
    goBack() {
      router.back()
    }
  },
}
</script>
<style scoped>
  
</style>