<template>
  <div class="d-flex flex-column justify-content-between vh-100">
    <div>
      <rd-navbar
        :title="$t('Main')"
        :showBackButton="false"
      />
      <div class="col-12" style="background:#efefef; padding-left: 1rem; padding-top: 1rem; padding-bottom: 1rem;">
        <h2 class="ms-12 text-start" style="margin-bottom: 0;">{{ $t('System Administration') }}</h2>
      </div>
      <div style="margin-top: 0 !important; padding-top: 0 !important;" class="container container-custom p-2">
        <div class="accordion accordion-custom">
          <div class="accordion-item px-2" style="border: none;">
            <h2 class="accordion-header" id="headingOne">
              <div class="custom-dot"></div>
              <button class="accordion-button accordion-button-custom" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                Central administration
              </button>
            </h2>
            <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <div class="row g-4">
                  <div class="col-12 col-lg-4" v-if="user.isAdmin">
                    <rd-admin-card
                      :name="$t('Categories')"
                      :description="$t('Manage and create categories')"
                      @route="goTo('/admin/categories')"
                    />
                  </div>
                  <div class="col-12 col-lg-4" v-if="user.isAdmin || user.isUploader">
                    <rd-admin-card
                      :name="$t('Files')"
                      :description="$t('Manage all related to files')"
                      @route="goTo('/admin/files')"
                    />
                  </div>
                  <div class="col-12 col-lg-4" v-if="user.isAdmin">
                    <rd-admin-card
                      :name="$t('Profiles')"
                      :description="$t('Control user profiles')"
                      @route="goTo('/admin/profiles')"
                    />
                  </div>
                  <div class="col-12 col-lg-4" v-if="user.isAdmin">
                    <rd-admin-card
                      :name="$t('Users')"
                      :description="$t('Manage, add and delete users')"
                      @route="goTo('/admin/user')"
                    />
                  </div>
                  <div class="col-12 col-lg-4" v-if="user.isAdmin">
                    <rd-admin-card
                      :name="$t('Settings')"
                      :description="$t('Manage general website settings')"
                      @route="goTo('/admin/settings')"
                    />
                  </div>
                  <div class="col-12 col-lg-4" v-if="user.isAdmin || user.isUploader">
                    <rd-admin-card
                      :name="$t('Main Site')"
                      :description="$t('Main page of the application')"
                      @route="goTo('/files')"
                    />
                  </div>
                  <div class="col-12 col-lg-4" v-if="user.isAdmin || user.isUploader">
                    <rd-admin-card
                      :name="$t('Bulk Load')"
                      :description="$t('Bulk load of data')"
                      @route="openModalBulkModal()"
                    />
                  </div>
                  <div class="col-12 col-lg-4" v-if="user.isAdmin || user.isTrainer">
                    <rd-admin-card
                      :name="$t('Exam Creation')"
                      :description="$t('Exam Creation')"
                      @route="goTo('/admin/exam')"
                    />
                  </div>
                  <div class="col-12 col-lg-4" v-if="user.isAdmin || user.isTrainer">
                    <rd-admin-card
                    :name="$t('Sessions')"
                    :description="$t('Sessions Configuration')"
                    @route="goTo('/session')"
                    />
                  </div>
                  <div class="col-12 col-lg-4" v-if="user.isAdmin">
                    <rd-admin-card
                      :name="$t('SmartHire')"
                      :description="$t('SmartHire Module')"
                      @route="goTo('/admin/smarthire')"
                    />
                  </div>
                  <div class="col-12 col-lg-4" v-if="user.isAdmin">
                    <rd-admin-card
                      :name="$t('Status Configuration')"
                      :description="$t('SmartHire Status Configuration')"
                      @route="goTo('/admin/smarthire/status')"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <rd-footer />
    </div>
    <bulk-load
      v-if="showBulkLoadModal"
      @hide="() => showBulkLoadModal = false" 
    />
  </div>
</template>
<script>
import RdAdminCard from '@/components/rd-components/rd-admin-card.vue'
import RdNavbar from '@/components/rd-components/rd-navbar.vue'
import RdFooter from '@/components/rd-components/rd-footer.vue'
import BulkLoad from '@/views/bulk/bulk-load.vue'
import router from '@/router'
import { mapState } from 'vuex'

export default {
  components: {
    RdFooter,
    RdNavbar,
    RdAdminCard,
    BulkLoad
  },
  data() {
    return {
      showBulkLoadModal: false,
    }
  },
  computed: {
    ...mapState(['user'])
  },
  methods: {
    goTo(route) {
      router.push(route)
    },
    openModalBulkModal() {
      this.showBulkLoadModal = true;
    }
  },
}
</script>
<style lang="scss" scoped>
.container-custom {
  margin-left: unset;
  width: 100%;
  max-width: 100%;
}
.container-custom.container-custom {
  padding-left: 3rem;
  padding-right: 3rem;
}
.accordion-custom {
  padding: 0 1rem 0;
  .accordion-button-custom {
    background: #ffffff;
    color: #000000;
    padding: 0.8rem 0;
  }
  .custom-dot {
    width: 1rem;
    height: 1rem;
    background-color: #421cac;
    border-radius: 50%;
    position: relative;
    top: -1px;
  }
  .accordion-header {
    border-bottom: black solid 3px;
    display: flex;
    align-items: center;
  }
  
  .accordion-button-custom::after {
    display: none;
  } 
  .accordion-button-custom::before {
    margin: -0.2rem 0.5rem 0;
    flex-shrink: 0;
    width: var(--bs-accordion-btn-icon-width);
    height: var(--bs-accordion-btn-icon-width);
    content: "";
    background-image: var(--bs-accordion-btn-icon);
    background-repeat: no-repeat;
    background-size: var(--bs-accordion-btn-icon-width);
    transition: var(--bs-accordion-btn-icon-transition);
    transform: rotate(0.75turn);
  }
  .accordion-button-custom:not(.collapsed)::before {
    transform: rotate(0turn);
  }
}
.custom-sub {
  display: flex;
  gap: 1rem;
  padding-left: 1rem;
  padding-top: 1rem;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}
</style>