<template>
<div class="w-100">
    <div class="row justify-content-center">
        <div class="col-12 col-md-6 col-lg-9">
            <pie-chart-component :data="[
          {
            category: $t('Correct'),
            value: data.last.score
          }, {
            category: $t('Incorrect'),
            value: (data.questionsCount - data.last.score),
          }
        ]" :label="`${data.last.score} / ${data.questionsCount}`" height="300px" />
        </div>
        <div class="col-12 col-md-3 col-lg-3 p-4">
            <div class="row fs-4 fw-bold">
                {{ $t('Exam Summary') }}:
            </div>
            <div class="row justify-content-start">
                <p class="text-start m-0 p-0">
                    <span class="fw-bold">{{ $t('Correct answers') }}:</span> {{ data.last.score }}
                </p>
            </div>
            <div class="row justify-content-start">
                <p class="text-start m-0 p-0">
                    <span class="fw-bold">{{ $t('Minimum completion percentage: ')  }}:</span> {{ percentage }}% 
                </p>
            </div>
            <div class="row justify-content-start">
                <p class="text-start m-0 p-0">
                    <span class="fw-bold">{{ $t('Completion percentage') }}: </span> {{ data.last.completion_percent }}%
                </p>
            </div>
            <div class="row justify-content-start">
                <p class="text-start m-0 p-0">
                    <span class="fw-bold">{{ $t('Exam Status') }}:</span> {{ data.last.approved ? $t('Passed') : $t('Failed') }}
                </p>
            </div>
            <div class="row justify-content-start">
                <p class="text-start m-0 p-0">
                    <span class="fw-bold">{{ $t('Exam Attempt') }}:</span> {{ data.attemps }}
                </p>
            </div>
        </div>
    </div>
    <div class="row justify-content-start">
        <div class="col-12 col-md-10 col-lg-10">
            <p class="fs-3 fw-bold">
                {{ data.last.approved
          ? $t('Congratulations on passing your exam! Well done!')
          : $t(`Hey, I know the exam didn't go as you expected. Cheer up, keep going, you can do it!`)
        }}
            </p>
        </div>
    </div>
    <div class="row justify-content-start">
        <div class="col-12 col-md-10 col-lg-10">
            <p class="fs-3 fw-bold">
                {{ $t('Score') }}:  {{ (Number(data.last.score) / Number(data.questionsCount)).toFixed(2) * 100 }}%
            </p>
        </div>
    </div>
</div>
</template>

<script>
import PieChartComponent from '../amCharts/pie-chart-component.vue';
export default {
    name: 'RDExamResults',
    components: {
        PieChartComponent,
    },
    props: {
        data: {
            type: Object,
            required: true
        },
    },
    computed: {
        percentage() {
            return (Number(this.data.minimum_score) / 100).toFixed(2) * 100;
        }
    },
    data() {
        return {

        };
    },
};
</script>
