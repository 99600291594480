<template>
  <div
    class="position-fixed top-0 start-0 backScreen"
    @click="hideModal($event)"
  >
    <div 
      class="
        card 
        position-fixed
        top-50
        start-50
        translate-middle
        modalPosition
        my-2
        w-75 p-4
      "
    >
      <div class="container h-100">
        <div class="row">
          <h4 class="col-12 text-capitalize">
            {{ $t('Bulk loads')}}
          </h4>
        </div>

        <div class="row mt-2">
          <div class="col-12">
            <rd-radio-button
              :label="$t('Select the option you want to upload')"
              :options="options"
              @change="handleImportType"
            />
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-12">
            <rd-input
              type="file"
              ref="file"
              :placeholder="$t('File')"
              :label="$t('Upload a file to bulk upload')"
              @input="handleFileSelect"
            />
          </div>
        </div>

        <div 
          v-if="!!templatePath"
          class="row mt-2"
        >
          <div class="col-2">
            <div class="d-flex">
              <a 
                class="btn btn-outline-primary m-1" 
                :href="templatePath" 
                download
              >
                {{ $t('Download template') }}
              </a>
              <a 
                class="btn btn-outline-primary m-1" 
                @click="downloadEntity"
              >
                {{ $t('Download ' + entity) }}
              </a>
            </div>
          </div>
        </div>
        
        <!-- actions buttons -->
        <div class="row mt-4">
          <div class="col-6">
            <button
              @click="this.$emit('hide')"
              class="col-6 w-100 btn btn-outline-primary text-capitalize"
            >
              {{ $t('Dismiss') }}
            </button>
          </div>
          <div class="col-6 position-relative">
            <button 
              @click="handleUploadFile"
              class="w-100 btn btn-primary text-capitalize"
            >
              {{ $t('Upload') }}
            </button>
          </div>
        </div>

        <!-- created and updated -->
        <div
          v-if="created.length > 0 || updated.length > 0"
          class="row mt-4"
        >
          <div class="col-12">
            <hr>
            {{ $t('Created') }}: {{ created.length }}
            <hr>
            {{ $t('Updated') }}: {{ updated.length }}
          </div>
        </div>
        
        <!-- errors -->
        <div
          v-if="errors.length > 0" 
          class="accordion accordion-flush row mt-4" 
          id="errorsAccordion"
        >
          <div class="accordion-item col-12">
            <h2 
              class="accordion-header" 
              id="error-headingMain"
            >
              <button 
                class="accordion-button collapsed" 
                type="button" 
                data-bs-toggle="collapse" 
                data-bs-target="#error-collapsable"
                aria-expanded="false" 
                aria-controls="error-collapsable"
              >
                {{ $t('Errors while Bulk loading') }}
              </button>
            </h2>
            <div 
              id="error-collapsable" 
              class="accordion-collapse collapse" 
              aria-labelledby="error-headingMain" 
              data-bs-parent="#errorsAccordion"
            >
              <div class="accordion-body w-100 p-0">
                <li 
                  class="list-group-item fs-6"
                  v-for="(error, index) in errors" :key="index"
                >
                  <span
                  >
                    <span class="fw-bold d-flex">
                      <br>
                      {{ error.message }}
                    </span>
                  </span>
                  <br>
                  <span class="d-flex">
                    {{ $t('At line') }}:  
                    <span class="fw-bold">
                      {{ error.line }}
                    </span>
                  </span>
                </li>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import router from '@/router';
import RdInput from '@/components/rd-components/rd-input.vue';
import RdRadioButton from '@/components/rd-components/rd-radio-button.vue';
import { bulkImport, bulDownload } from '@/services/bulk/bulk.api.js';
import { ENTITIES_TO_IMPORT } from '@/utils/constants';
import {
  convertToCSV
} from '@/utils/convertFormat';
import {
  downloadCSV
} from '@/utils/handleFile';

export default {
  components: {
    RdInput,
    RdRadioButton,
  },
  data() {
    return {
      importType: null,
      ENTITIES_TO_IMPORT,
      templatePath: null,
      file: '',
      entity: '',
      errors: [],
      updated: [],
      created: [],
      entitiesToImportKeys: Object.keys(ENTITIES_TO_IMPORT),
    }
  },
  watch: {
    importType(val) {
      const parsedValue = Number(val);
      this.entity = this.entitiesToImportKeys.find(key => ENTITIES_TO_IMPORT[key].value === parsedValue)?.toLowerCase();
      this.templatePath = `/templates/${this.entity}.csv`;
    }
  },
  computed: {
    ...mapState(['user']),
    options() {
      return this.entitiesToImportKeys.map(entity => ({ name: ENTITIES_TO_IMPORT[entity].label, value: ENTITIES_TO_IMPORT[entity].value }))
    }
  },
  methods: {
    async downloadEntity() {
      const {data} = await bulDownload(this.entity);
      const csv = await convertToCSV({ data, separator: ',', firstRowReparator: ',' });
      downloadCSV({ data: csv, filename: this.entity });
    },
    hideModal(e) {
      if (e.target !== e.currentTarget) return
      this.$emit('hide')
    },
    goBack() {
      router.back()
    },
    handleImportType(e) {
      this.importType = e.target.value;
      this.errors = [];
    },
    handleFileSelect(e) {
      this.file = e.target.files[0];
    },
    async handleUploadFile() {
      this.updated = [];
      this.created = [];
      this.errors = [];
      try {
        const fileData = new FormData();
        fileData.append('file', this.file);
        const { data } = await bulkImport(this.entity, fileData);
        this.errors = data.errors;
        this.created = data.created ? data.created : [];
        this.updated = data.updated ? data.updated : [];
        if (this.errors.length) throw new Error('Error when importing');
        this.$store.dispatch('dispatchNotification', {
          title: 'Success!',
          message: 'Bulk Load was sucessful.',
          type: 'success',
        });
      } catch (error) {
        this.$store.dispatch('dispatchNotification', {
          title: 'Error!',
          message: 'Bulk Load was not created.',
          type: 'error',
        });
      }
    }
  },
}
</script>
<style scoped>
.backScreen {
  background: rgb(0, 0, 0, 0.7);
  min-height: 100vh;
  min-width: 100vw;
}

.modalPosition {
  max-height: 100%;
  overflow-y: scroll;
}
</style>