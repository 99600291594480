<template>
  <embed v-if="windowWidth > 768" :src="`${data}#navpanes=0&scrollbar=0&view=FitH`"
        type="application/pdf"> 
  <rd-not-valid-viewer v-else />
</template>
<script>
import RdNotValidViewer from './rd-not-valid-viewer.vue'
import { mapState } from 'vuex';
export default {
  name: 'RdPdfViewer',
  props: {
    data: {
      type: String,
      required: true
    },
  },
  components: {
    RdNotValidViewer,
  },
  computed: {
    ...mapState(['windowWidth'])
  }
}
</script>
<style scoped>
</style>
