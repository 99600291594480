<template>
  <nav class="navbar navbar-light bg-light">
    <div class="container-fluid">
      <div
        :style="{'cursor': 'pointer'}"
        @click="goToMain"
      >
        <img 
          v-if="!calcLogo" 
          src="@/assets/statics/smartmatic-logo.svg" 
          title="Smartfiles logo" 
          alt="Smartfiles logo"
          :style="{
            'max-height': '50px',
            'max-width': '150px',
            'object-fit': 'cover'
          }"
          class="d-inline-block align-text-top"
        >
        <img 
          v-else 
          :src="calcLogo" 
          title="Smartfiles logo" 
          alt="Smartfiles logo" 
          :style="{
            'max-height': '50px',
            'max-width': '150px',
            'object-fit': 'cover'
          }"
          class="d-inline-block align-text-top"
        >
  
        <span
          style="padding-top: 0;"
          class="navbar-brand btn btn-link"
        >
          {{ (settings? settings.title : null) ||  $t('Smart Files') }}
        </span>
      </div>
      <div 
        v-if="userLoggedIn" 
        class="d-flex ms-auto custom-responsive"
      >
        <div v-if="user.isAdmin || user.isUploader" class="dropdown">
          <a 
            class="btn btn-link dropdown-toggle" 
            role="button" 
            id="userMenu" 
            data-bs-toggle="dropdown" 
            aria-expanded="false"
            style="color:black;"
          >
            <p style="color:black;"  class="btn btn-link text-decoration-none p-0 m-0 ms-1">
              Navigation
            </p>
          </a>

          <ul class="dropdown-menu dropdown-menu-end custom-dropdown custom-dropdown-responsive" aria-labelledby="userMenu">

            <li v-if="user.isAdmin || user.isUploader">
              <button
                class="btn btn-head text-decoration-none dropdown-item"
              >
                ADMINISTRATION FILES
              </button>
            </li>
            <li v-if="user.isAdmin || user.isUploader">
              <button
                @click="goTo('/admin/files')"
                class="btn btn-link text-decoration-none dropdown-item"
              >
                Files
              </button>
            </li>            
            <li v-if="user.isAdmin">
              <button
                @click="goTo('/admin/categories')"
                class="btn btn-link text-decoration-none dropdown-item"
              >
                Categories
              </button>
            </li>            
            <li v-if="user.isAdmin">
              <button
                class="btn btn-head text-decoration-none dropdown-item"
              >
                ADMINISTRATION USER
              </button>
            </li>
            <li v-if="user.isAdmin">
              <button
                @click="goTo('/admin/user')"
                class="btn btn-link text-decoration-none dropdown-item"
              >
                Users
              </button>
            </li>            
            <li v-if="user.isAdmin">
              <button
                @click="goTo('/admin/profiles')"
                class="btn btn-link text-decoration-none dropdown-item"
              >
                Profiles
              </button>
            </li>            
            <li v-if="user.isAdmin || user.isUploader">
              <button
                class="btn btn-head text-decoration-none dropdown-item"
              >
                MAIN SITE
              </button>
            </li>
            <li v-if="user.isAdmin || user.isUploader">
              <button
                @click="goTo('/files')"
                class="btn btn-link text-decoration-none dropdown-item"
              >
                Main Site
              </button>
            </li>                       
            <li v-if="user.isAdmin">
              <button
                class="btn btn-head text-decoration-none dropdown-item"
              >
                CONFIGURATION
              </button>
            </li>
            <li>
              <button
                v-if="user.isAdmin"
                @click="goTo('/admin/settings')"
                class="btn btn-link text-decoration-none dropdown-item"
              >
                Settings
              </button>
            </li>                      
          </ul>
        </div>
        <rd-language-switcher/>
        <div v-if="user.isAdmin || user.isUploader" @click="goToMain" class="dropdown" style="cursor:pointer;">
           <a 
            class="btn btn-link dropdown-toggle custom-dropdown-toogle" 
            role="button" 
            title="Configuration"
            data-bs-toggle="dropdown" 
            aria-expanded="false"
            style="color:black;"
          >
            <i data-bs-toggle="tooltip" data-bs-placement="right" title="Go to Main" type="button" class="bi bi-gear-fill"></i>
          </a>
        </div>
        <div class="dropdown" style="cursor:pointer;">
           <a 
            class="btn btn-link dropdown-toggle custom-dropdown-toogle" 
            role="button"
            title="About"
            data-bs-toggle="modal" 
            aria-expanded="false"
            data-bs-target="#infoModal"
            style="color:black;"
          >
            <i data-bs-toggle="tooltip" data-bs-placement="right" title="About" type="button" class="bi bi-info-circle"></i>
          </a>
        </div>
        <div class="dropdown">
          <a 
            class="btn btn-link dropdown-toggle" 
            role="button" 
            id="userMenu"
            data-bs-toggle="dropdown" 
            aria-expanded="false"
            style="color:black;"
          >
            <i data-bs-toggle="tooltip" data-bs-placement="right" title="User" type="button" class="bi bi-person-fill"></i>
            <p style="color:black;" v-if="!(windowWidth < 768)" class="btn btn-link text-decoration-none p-0 m-0 ms-1">
              {{ user.userName }}
            </p>
            <span class="role_styles">
              {{ getRoleText(user.roleId) || ''}}
            </span>
          </a>

          <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="userMenu">

            <li v-if="user.isAdmin || user.isUploader" >

              <button 
                class="btn btn-link text-decoration-none dropdown-item"
                @click="goToMain"
              >
                <i data-bs-toggle="tooltip" data-bs-placement="right" title="Go to Main" type="button" class="bi bi-house-fill"></i>
                {{ $t('Main')}}
              </button>

            </li>
            
            <li>
              
              <button
                class="btn btn-link text-decoration-none dropdown-item"
                @click="handleLogout"
              >
                <i data-bs-toggle="tooltip" data-bs-placement="right" title="Logout" type="button" class="bi bi-box-arrow-in-right"></i>
                {{ $t('Logout') }}
              </button>
            
            </li>
          </ul>
        </div>

      </div>
      <rd-info-modal />
    </div>
    <div 
      class="container-fluid" 
      v-if="showBackButton"
    >
      <button 
        class="btn btn-link text-decoration-none" 
        @click="goBack"
      >
        <i data-bs-toggle="tooltip" data-bs-placement="right" title="Back" type="button" class="bi bi-arrow-left"></i>  {{ $t('Go back') }}
      </button>
    </div>
  </nav>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import rdLanguageSwitcher from './rd-language-switcher.vue';
import rdInfoModal from './rd-info-modal.vue';
import { getRoleText } from '../../utils/utils';
export default {
  components: { rdLanguageSwitcher, rdInfoModal },
  data() {
    return {
      getRoleText,
    }
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    showBackButton: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    calcLogo() {
      return (
        !!this.$store.state.settings?.logo && 
        `/settings/${this.$store.state.settings.logo}`
      );
    },
    user() {
      return this.$store.state.user;
    },

    userLoggedIn() {
      return Object.keys(this.$store.state.user).length > 0
    },

    userRoute() {
      return this.$store.state.user.isAdmin || this.$store.state.user.isUploader || this.$store.state.user.isTrainer ? '/admin' : '/files';
    },
    ...mapState(['windowWidth', 'settings'])
  },
  methods: {
    ...mapActions([
      'logout'
    ]),
    goToMain() {
      this.$router.push(this.userRoute)
    },
    async handleLogout() {
      if (window.Intercom) window.Intercom('shutdown');
      await this.logout();
    },
    goBack() {
      this.$router.back()
    },
    goTo(route) {
      this.$router.push(route)
    }
  },
  
}
</script>

<style scoped>
.custom-dropdown {
  max-height: 200px;
  overflow-y: scroll  ;
}
.custom-dropdown .btn-head {
  color: #9baec4;
  cursor: auto;
}
.custom-dropdown .btn-head:hover {
  background: unset;
}
.custom-dropdown::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #efefef;
}

.custom-dropdown::-webkit-scrollbar {
  width: 10px;
  background-color: #efefef;
}

.custom-dropdown::-webkit-scrollbar-thumb {
  border-radius: 10px;
  max-height: 5px;
  background-color: #6c6d75;
}
.dropdown-toggle::after {
  position: relative;
  top: 2px;
}
.custom-dropdown-toogle::after {
  display: none;
}
@media (max-width: 768px) {
  /* .custom-responsive {
    margin-left: 0 !important;
    flex-flow: column;
    align-items: baseline;
  } */
  .custom-dropdown-responsive {
    right: auto !important;
  }
}

.role_styles {
  font-size: .5rem;
  text-decoration: none;
  position: absolute;
  top: 2rem;
  right: 1rem;
}

.role_styles_logo {
  font-size: .5rem;
  text-decoration: none;
  position: absolute;
  top: 1rem;
  right: .5rem;
}

</style>