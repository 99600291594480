<template>
  <div class="d-flex flex-column justify-content-between vh-100">
    <div>
      <rd-navbar />
      <div class="container bg-light my-3 p-3">
        <div class="row">
          <div class="col-12">
            <h2 class="ms-4 text-start">{{ $t('Edit Smarthire Status') }}</h2>
          </div>
        </div>

        <form 
          @submit.prevent="" 
          autocomplete="off" 
          class="container"
        >
          <div class="row mb-2">
            <div class="col-sm-12 col-md-6">
              <rd-input
                type="text"
                :placeholder="$t('Status')" 
                :label="$t('Enter the status')"
                :value="name"
                required
                @input="(e) => this.name = e.target.value"
              />
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-sm-12 col-md-6">
              <rd-input
                type="text"
                :placeholder="$t('Description')" 
                :label="$t('Enter the status description')"
                :value="description"
                @input="(e) => this.description = e.target.value"
              />
            </div>
          </div>

          <div class="my-3 row justify-content-end">
            <div class="col-12 col-lg-3 col-md-6 m-1">
              <button class="btn btn-outline-primary me-3 w-100" @click="goBack">
                {{ $t('Dismiss') }}
              </button>
            </div>
            <div class="col-12 col-lg-3 col-md-6 m-1">
              <button type="submit" class="btn btn-primary w-100" @click="handleCreateStatus">
                <i data-bs-toggle="tooltip" data-bs-placement="right" title="Edit Status" type="button" class="bi bi-plus-circle-fill"></i> {{ $t('Edit Status (btn)') }}
              </button>
            </div>
          </div>

        </form>
      </div>
    </div>
    <div>
      <rd-footer />
    </div>
  </div>
</template>
<script>
import RdInput from '../../components/rd-components/rd-input.vue';
import rdFooter from "../../components/rd-components/rd-footer.vue"
import RdNavbar from '../../components/rd-components/rd-navbar.vue'
import router from "../../router";
import { updateSHStatus, getSHStatusByIdStatus } from '../../services/smarthire/smarthire.api';

export default {
  components: {
    rdFooter,
    RdNavbar,
    RdInput
  },
  data() {
    return {
      name: null,
      description: null,
    }
  },
  async mounted() {
    await this.getCurrentStatus();
  },
  computed: {
    statusId() {
      return this.$route.params.id
    },
  },
  methods: {
    async getCurrentStatus() {
      try {
        const { data } = await getSHStatusByIdStatus({
          id: this.statusId
        })
        const { statusName, description } = data
        this.name = statusName
        this.description = description
      } catch (error) {
        console.log(error)
      }
    },
    async handleCreateStatus() {
      try {
        const payload = {}
        if (this.name) payload.statusName = this.name
        if (this.description) payload.description = this.description
        await updateSHStatus({
          id: this.statusId,
          payload
        })
        this.$store.dispatch('dispatchNotification', {
          title: 'Success!',
          message: 'Status edited.',
          type: 'success',
        });
        router.back()
      } catch (error) {
        console.log(error)
        this.$store.dispatch('dispatchNotification', {
          title: 'Failed!',
          message: 'Error editing Status.',
          type: 'error',
        });
      }
    },
    goBack() {
      router.back()
    },
  }
}
</script>