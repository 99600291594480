import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';
import Home from '../views/Home.vue'
import LoginScreen from '../views/login/login-screen.vue'
import LoginWithToken from '../views/login/login-with-token.vue'

// user routes
import UserManage from '../views/user/user-manage.vue'
import UserEdit from '../views/user/user-edit.vue'
import UserAdd from '../views/user/user-add.vue'
import UserProfile from '../views/user/user-profile.vue'

// admin routes
import AdminView from '@/views/admin/admin-main.vue'
import CategoriesManage from '@/views/categories/categories-manage.vue'
import ProfilesManage from '@/views/profiles/profiles-manage.vue'
import FilesManage from '@/views/files/files-manage.vue'

// exam routes
import ExamManage from '@/views/exam/exam-manage.vue'
import ExamAdd from '@/views/exam/exam-add.vue'
import ExamEdit from '@/views/exam/exam-edit.vue'
import ApplyExam from '@/views/exam/apply-exam.vue'
import ExamStats from '@/views/exam/exam-stats.vue'
import ExamGeneralStats from '@/views/exam/exam-general-stats.vue'
import ExamResults from '@/views/exam/exam-results.vue'
import ExamAttempts from '@/views/exam/exam-attempts.vue'

// general settings
import AdminGeneralSettings from '@/views/general-settings/admin-general-settings.vue'
import BackgroundSettings from '@/views/general-settings/background-settings.vue'

// files routes
import FileAdd from '@/views/files/files-add.vue'
import FileEdit from '@/views/files/files-edit.vue'
import FilesStream from '@/views/files/files-stream.vue'

// category routes
import CategoryAdd from '@/views/categories/category-add.vue'
import CategoryEdit from '@/views/categories/category-edit.vue'

// subcategory routes
import SubcategoryAdd from '@/views/subcategories/subcategories-add.vue'
import SubcategoryEdit from '@/views/subcategories/subcategories-edit.vue'
import SubcategoryAll from '@/views/subcategories/subcategories-all.vue'
import SubcategoryView from '@/views/subcategories/subcategory-view.vue'

// profile routes
import ProfileAdd from '@/views/profiles/profiles-add.vue'
import ProfileEdit from '@/views/profiles/profiles-edit.vue'

// languages routes
import LanguageManage from '@/views/languages/language-manage.vue'
import LanguageAdd from '@/views/languages/language-add.vue'
import LanguageEdit from '@/views/languages/language-edit.vue'

// mobile 
import FileDetail from '@/views/mobile/File-detail.vue'

// Session Routes
import SessionManage from '@/views/sessions/sessions-manage.vue'
import SessionEdit from '@/views/sessions/sessions-edit.vue'
import SessionAdd from '@/views/sessions/sessions-add.vue'
import SessionFeedback from '@/views/sessions/sessions-feedback.vue'
import SessionUser from '@/views/sessions/sessions-users.vue'

// SmartHire module
import SmarthireManagement from '@/views/smarthire/smarthire-management.vue'
import SmarthireStatusManagement from '@/views/smarthire/smarthire-status-management.vue'
import SmarthireStatusAdd from '@/views/smarthire/smarthire-status-add.vue'
import SmarthireStatusEdit from '@/views/smarthire/smarthire-status-edit.vue'

const publicRoutes = [
  {
    path: '/login',
    name: 'Login',
    component: LoginScreen
  },
  {
    path: '/login_with_token',
    name: 'LoginWithToken',
    component: LoginWithToken
  }
]

const privateRoutes = [
  {
    path: '/files',
    name: 'Home',
    component: Home,
    meta: { authorize: true }
  },
  {
    path: '/admin',
    name: 'Administration',
    component: AdminView,
    meta: { authorize: true }
  },
  {
    path: '/admin/files',
    name: 'Files Managament',
    component: FilesManage,
    meta: { authorize: true }
  },
  {
    path: '/admin/file/add',
    name: 'Add File',
    component: FileAdd,
    meta: { authorize: true }
  },
  {
    path: '/admin/file/edit/:uuid',
    name: 'Edit File',
    component: FileEdit,
    meta: { authorize: true }
  },
  {
    path: '/admin/profiles',
    name: 'Profiles Managament',
    component: ProfilesManage,
    meta: { authorize: true }
  },
  {
    path: '/admin/profiles/add',
    name: 'Add Profile',
    component: ProfileAdd,
    meta: { authorize: true }
  },
  {
    path: '/admin/profiles/edit/:id',
    name: 'Edit Profile',
    component: ProfileEdit,
    meta: { authorize: true }
  },
  {
    path: '/admin/categories',
    name: 'Categories Managament',
    component: CategoriesManage,
    meta: { authorize: true }
  },
  {
    path: '/admin/categories/add',
    name: 'Add Category',
    component: CategoryAdd,
    meta: { authorize: true }
  },
  {
    path: '/admin/categories/edit/:id',
    name: 'Edit Category',
    component: CategoryEdit,
    meta: { authorize: true }
  },
  {
    path: '/admin/subcategories/add',
    name: 'Add Subategory',
    component: SubcategoryAdd,
    meta: { authorize: true }
  },
  {
    path: '/admin/subcategories/edit/:id',
    name: 'Edit Subcategory',
    component: SubcategoryEdit,
    meta: { authorize: true }
  },
  {
    path: '/admin/subcategories/all',
    name: 'All Subcategories',
    component: SubcategoryAll,
    meta: { authorize: true }
  },
  {
    path: '/admin/subcategories/view/:id',
    name: 'Subcategory View',
    component: SubcategoryView,
    meta: { authorize: true }
  },
  {
    path: '/admin/settings',
    name: 'General Settings',
    component: AdminGeneralSettings,
    meta: { authorize: true }
  },
  {
    path: '/admin/settings/language',
    name: 'Language Settings',
    component: LanguageManage,
    meta: { authorize: true }
  },
  {
    path: '/admin/settings/language/add',
    name: 'Add Language',
    component: LanguageAdd,
    meta: { authorize: true }
  },
  {
    path: '/admin/settings/language/edit/:id',
    name: 'Edit Language',
    component: LanguageEdit,
    meta: { authorize: true }
  },
  {
    path: '/admin/settings/background',
    name: 'Background Settings',
    component: BackgroundSettings,
    meta: { authorize: true }
  },
  {
    path: '/admin/user',
    name: 'Users Managament',
    component: UserManage,
    meta: { authorize: true }
  },
  {
    path: '/admin/user/add',
    name: 'Add User',
    component: UserAdd,
    meta: { authorize: true }
  },
  {
    path: '/admin/user/edit/:id',
    name: 'Edit User',
    component: UserEdit,
    meta: { authorize: true }
  },
  {
    path: '/admin/user/:id',
    name: 'User Profile',
    component: UserProfile,
    meta: { authorize: true }
  },
  {
    path: '/files/:uuid',
    name: 'Video Stream',
    component: FilesStream,
    meta: { authorize: true }
  },
  {
    path: '/file/detail/:uuid',
    name: 'File Details',
    component: FileDetail,
    meta: { authorize: true }
  },
  {
    path: '/session',
    name: 'Session Management',
    component: SessionManage,
    meta: { authorize: true }
  },
  {
    path: '/session/edit/:id',
    name: 'Session Edit',
    component: SessionEdit,
    meta: { authorize: true }
  },
  {
    path: '/session/feedback/:id',
    name: 'Session Feedback',
    component: SessionFeedback,
    meta: { authorize: true }
  },
  {
    path: '/session/users/:id',
    name: 'Session Users',
    component: SessionUser,
    meta: { authorize: true }
  },
  {
    path: '/session/add',
    name: 'Session Add',
    component: SessionAdd,
    meta: { authorize: true }
  },
  {
    path: '/admin/exam',
    name: 'Exam Management',
    component: ExamManage,
    meta: { authorize: true }
  },
  {
    path: '/admin/exam/add',
    name: 'Exam Add',
    component: ExamAdd,
    meta: { authorize: true }
  },
  {
    path: '/admin/exam/edit/:id',
    name: 'Exam Edit',
    component: ExamEdit,
    meta: { authorize: true }
  },
  {
    path: '/apply/exam/:id',
    name: 'Apply Exam',
    component: ApplyExam,
    meta: { authorize: true }
  },
  {
    path: '/admin/exam/stats/:id',
    name: 'Exam Statistics',
    component: ExamStats,
    meta: { authorize: true }
  },
  {
    path: '/view/exam/:id',
    name: 'Exam Results',
    component: ExamResults,
    meta: { authorize: true }
  },
  {
    path: '/view/attempts/:id',
    name: 'Exam Attemps',
    component: ExamAttempts,
    meta: { authorize: true }
  },
  {
    path: '/admin/exam/stats',
    name: 'Exam General Statistics',
    component: ExamGeneralStats,
    meta: { authorize: true }
  },
  {
    path: '/admin/smarthire',
    name: 'SmartHire Module',
    component: SmarthireManagement,
    meta: { authorize: true }
  },
  {
    path: '/admin/smarthire/status',
    name: 'SmartHire Status',
    component: SmarthireStatusManagement,
    meta: { authorize: true }
  },
  {
    path: '/admin/smarthire/status/create',
    name: 'SmartHire Status Create',
    component: SmarthireStatusAdd,
    meta: { authorize: true }
  },
  {
    path: '/admin/smarthire/status/edit/:id',
    name: 'SmartHire Status Edit',
    component: SmarthireStatusEdit,
    meta: { authorize: true }
  },
];

const routes = publicRoutes.concat(privateRoutes);

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const { authorize } = to.meta
  const user = store.getters.getUser

  // enviar al /login cuando quiera ir al root
  if (to.path === '/') return next('/login')

  // si la ruta es publica, dejar pasar
  if(!authorize) return next();
  console.log

  const viewerAllowedRoutes = (
    to.path ==='/files' 
    || to.name === 'Video Stream'
    || to.name === 'File Details'
    || to.name === 'Apply Exam'
    || to.name === 'Exam Results'
    || to.name === 'Exam Attemps'
  )
  const trainerAllowedRoutes = (
    to.name === 'Session Management'
    || to.name === 'Session Edit'
    || to.name === 'Session Feedback'
    || to.name === 'Session Users'
    || to.name === 'Session Add'
    || to.name === 'Administration'
    || to.name === 'Exam Statistics'
    || to.name === 'Apply Exam'
    || to.name === 'Exam Management'
  )
  
  if (
    user.isAdmin 
    || user.isUploader 
    || ((user.isViewer || user.isViewDownloader) && viewerAllowedRoutes) 
    || ((user.isTrainer) && trainerAllowedRoutes)
    || (to.path === '/login')
    || (to.path === '/login_with_token')
  ) {
    next();
  } else {
    next('/login');
  }
});

router.afterEach((to) => {
  if (to.path !== '/login' && to.path !== '/files' && to.path !== '/login_with_token') {
    store.dispatch('refreshTokenSession');
  }
});

export default router
