<template lang="">
  <div class="d-flex flex-column justify-content-between vh-100">
    <div>
      <rd-navbar :title="$t('Profiles (nav)')" />
      <div class="container bg-light my-3 p-3">
        <div class="row">
          <div class="col-12">
            <h2 class="ms-4 text-start">{{ $t('Edit Profile') }}</h2>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col-sm-12 col-md-6">
            <rd-input
              type="text"
              :placeholder="$t('Profile name')" 
              :label="$t('Enter the profile name')"
              required
              :value="this.profileName"
              @input="(e) => this.profileName = e.target.value"
            />
          </div>
        </div>

        <div class="row mb-4">
          <div class="col-sm-12 col-md-6">
            <rd-input
              type="text"
              :placeholder="$t('Profile description')" 
              :label="$t('Enter the profile description')"
              required
              :value="this.profileDescription"
              @input="(e) => this.profileDescription = e.target.value"
            />
          </div>
        </div>

        <div class="row mb-4" v-if="categories.length !== 0">
          <div class="col-sm-12 col-md-6">
            <label class="labelText required">
              {{ $t('Link categories or subcategories to this profile') }}
            </label>
            <table class="table text-start">
              <thead class="table-primary">
                <tr>
                  <th scope="col">
                    <input
                      v-model="allCategoriesSelected"
                      type="checkbox"
                      :checked="this.allSubcategories.length === this.subcategoriesToAdd.length"
                      @change="addAllCategories"
                    />
                    {{ $t('Category (table)') }}
                  </th>
                  <th scope="col">{{ $t('Subcategory (table)') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(category, index) in categories" :key="index"
                >
                  <rd-row
                    v-if="category.subcategories.length > 0"
                    :category="category"
                    :currentSubcategories="subcategoriesToAdd"
                    @addSubcategories="addSubcategories"
                    @cleanSubcategories="cleanSubcategories"
                  />
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!-- Buttons -->
        <div class="my-3 row justify-content-end">
          <div class="col-lg-4 col-md-6 col-sm-6">
            <button class="btn btn-outline-primary me-3" @click="goBack">
              {{ $t('Dismiss') }}
            </button>
            <button 
              class="btn btn-primary" 
              @click="editThisProfile"
              :disabled="disableSaveButton"
            >
              {{ $t('Edit Profile (btn)') }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div>
      <rd-footer/>
    </div>
  </div>
</template>
<script>
import RdFooter from '../../components/rd-components/rd-footer.vue';
import RdNavbar from '../../components/rd-components/rd-navbar.vue';
import RdInput from '@/components/rd-components/rd-input.vue'
import RdRow from '@/components/rd-components/rd-row.vue'
import router from '@/router'
import { editProfileById, getProfileById } from '@/services/profiles/profile.api.js'
import { getAllCategory } from "@/services/categories/categories.api.js";

export default {
  data() {
    return {
      profileName: '',
      profileDescription: '',
      subcategoriesToAdd: [],
      categories: [],
      allCategoriesSelected: false,
      allSubcategories: [],
    }
  },
  async mounted() {
    await this.setCategories()
    await this.getProfileData()
  },
  computed: {
    disableSaveButton() {
      return (
        this.profileName === '' ||
        this.profileDescription === ''
      )
    },
    profileId() {
      return this.$route.params.id;
    }
  },
  components: {
    RdFooter,
    RdNavbar,
    RdInput,
    RdRow,
  },
  methods: {
    async getProfileData() {
      try {
        const { data } = await getProfileById(this.profileId)
        this.profileName = data.profile.profileName
        this.profileDescription = data.profile.profileDescription
        this.subcategoriesToAdd = data.subcategoriesId
          .map(el => parseInt(el.subCategoryId))
          .sort((a, b) => a - b)
      } catch (error) {
        console.log(error);
      }
    },
    async setCategories() {
      try {
        const { data } = await getAllCategory();
        this.categories = data
        for (const category of data) {
          const subcategories = category.subcategories.map(sub => sub.subCategoryId)
          this.allSubcategories.push(...subcategories)
        }
      } catch (error) {
        console.log(error);
      }
    },
    addSubcategories({subcategoryId}) {
      if (!this.subcategoriesToAdd.includes(subcategoryId)) {
        this.subcategoriesToAdd.push(subcategoryId)
        return
      }
      this.subcategoriesToAdd = this.subcategoriesToAdd.filter(sub => sub !== subcategoryId)
    },
    cleanSubcategories(subcategories) {
      for (const subcategory of subcategories) {
        this.subcategoriesToAdd = this.subcategoriesToAdd.filter(sub => sub !== subcategory)
      }
    },
    addAllCategories() {
      if (!this.allCategoriesSelected) {
        this.subcategoriesToAdd = []
        return;
      } 
      this.subcategoriesToAdd = []
      for (const category of this.categories) {
        category.subcategories.forEach(sub => {
          this.subcategoriesToAdd.push(parseInt(sub.subCategoryId))
        })
      }
    },
    async editThisProfile() { 
      try {
        const formattedSubcategories = [
          ...new Set(this.subcategoriesToAdd),
        ];
        const profile = {
          profileName: this.profileName,
          profileDescription: this.profileDescription,
          subcategoriesToAdd: formattedSubcategories
        };
        await editProfileById(this.profileId, profile)
        this.$store.dispatch('dispatchNotification', {
          title: 'Success!',
          message: 'Profile edited.',
          type: 'success',
        });
        router.back()
      } catch (error) {
        this.$store.dispatch('dispatchNotification', {
          title: 'Error!',
          message: 'Could not edit this profile.',
          type: 'error',
        });
        console.log(error)
      }
    },
    goBack() {
      router.back()
    }
  },
}
</script>
<style scoped>
.labelText {
  margin: 0;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: grey;
  float: left;
  border-radius: 4px;
}
</style>
