<template>
  <div class="d-flex flex-column justify-content-between vh-100">
    <div>
      <rd-navbar :title="$t('Categories (nav)')" />
      <div class="container bg-light my-3 p-3">
        <div class="row">
          <div class="col-12">
            <h2 class="ms-4 text-start">{{ $t('All Subcategories') }}</h2>
          </div>
        </div>
        <div class="row p-3 justify-content-between">
          <!-- Search bar -->
          <div
            class="p-0 m-0 mb-2 col-12 col-lg-4"
          >
            <rd-input
              class="w-100"
              :placeholder="$t('Search subcategory by name')" 
              type="text"
              @input="(e) => this.search = e.target.value"
            />
          </div>

          <button 
            class="btn btn-primary col-12 col-lg-3" 
            @click="goToAddNewSubCategory">
            <i data-bs-toggle="tooltip" data-bs-placement="right" title="Add Subcategory" type="button" class="bi bi-plus-circle-fill"></i> {{ $t('Add Subcategory (btn)') }}
          </button>
        </div>

        <div v-if="subcategoriesLoading">
          <rd-loading />
        </div>
        <div v-else>

          <div
            v-if="filteredSubcategories.length === 0" 
            class="row p-3"
          >
            <h2>
              {{ $t('No data found') }} <i data-bs-toggle="tooltip" data-bs-placement="right" title="No subcategories Found" type="button" class="bi bi-exclamation-triangle"></i>
            </h2>
          </div>

          <!-- Subcategories -->
          <div v-else class="row p-3 d-flex justify-content-center">
            <div style="overflow-x: auto; width: 100%; max-width: 100%;">
              <div class="w-100">
                <table class="table text-center table-hover" ref="table_resize">
                  <thead class="table-primary" @contextmenu.prevent="resize">
                    <tr>
                      <th scope="col" class="text-start">{{ $t('Sub Category (table)') }}</th>
                      <th scope="col" class="text-start">{{ $t('Category (table)') }}</th>
                      <th scope="col">{{ $t('Actions (table)') }}</th>
                    </tr>
                  </thead>
                  <tbody id="table-body" class="text-start">
                    <tr
                      v-for="(subcategory, index) in dataTemp" :key="index"
                      class="hoverable"
                      :class="{ 'fill-ods': index % 2 != 0 }"
                    >
                      <td style="width: 33%; word-break: break-all;" :header="$t('Sub Category (table)')" scope="row">{{ subcategory.subCategoryName }}</td>
                      <td style="width: 33%; word-break: break-all;" :header="$t('Category (table)')" scope="row">{{ subcategory.categoryName }}</td>
                      <td style="width: 33%; word-break: break-all;" :header="$t('Actions (table)')" class="centered">
                        <button 
                          class="btn btn-label" 
                          @click="goToEditSubCategory(subcategory.subCategoryId)"
                        >
                          <i data-bs-toggle="tooltip" data-bs-placement="right" title="Edit Subcategory" type="button" class="bi bi-pencil-square"></i>
                        </button>
                        <button 
                          class="btn btn-label" 
                          @click="showModal(subcategory)"
                        >
                          <i data-bs-toggle="tooltip" data-bs-placement="right" title="Delete Subcategory" type="button" class='bi bi-trash-fill'></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <rd-paginator :items="filteredSubcategories" @page-change="handlePageChange" />
          </div>

        </div>
        </div>
      </div>
    <div>
      <rd-footer/>
    </div>

    <rd-confirmation-modal
      v-if="showModalProp"
      modalTitle="Delete subcategory permanently?"
      confirmButton="Delete"
      dismissButton="Dismiss"
      :showBody="filesRelated.length > 0"
      bodyTitleText="Files related"
      confirmCheckText="Delete anyway"
      :items="filesRelated"
      @confirmAction="deleteSubCategory(subcategoryToDelete)"
      @dismissAction="dismissAction"
    />
  </div>
</template>
<script>
import RdFooter from '../../components/rd-components/rd-footer.vue'
import RdNavbar from '../../components/rd-components/rd-navbar.vue'
import { getAllCategory } from '@/services/categories/categories.api.js'
import { getSubCategoriesByCategoryId, deleteSubcategoryById } from '@/services/subcategories/subcategories.api.js'
import { 
  filesBySubcategory 
} from '@/services/files/file.api.js'
import router from '@/router'
import RdConfirmationModal from '../../components/rd-components/rd-confirmation-modal.vue'
import RdLoading from '../../components/rd-components/rd-loading.vue'
import RdInput from '../../components/rd-components/rd-input.vue'
import RdPaginator from '@/components/rd-components/rd-paginator.vue'

export default {
  data() {
    return {
      categories: [],
      subcategories: [],
      subcategoriesLoading: true,
      showModalProp: false,
      subcategoryToDelete: {},
      search: '',
      filesRelated: [],
      dataTemp: []
    }
  },
  computed: {
    filteredSubcategories() {
      return this.subcategories.filter(
        subcategory => subcategory.subCategoryName.toLowerCase().includes(this.search.trim().toLowerCase())
      )
    },
  },
  async mounted() {
    await this.setCategories()
    await this.setSubcategories()
  },
  components: {
    RdFooter,
    RdNavbar,
    RdConfirmationModal,
    RdLoading,
    RdInput,
    RdPaginator
  },
  methods: {
    resize() {
      this.$refs.table_resize.style.width = '100%'
      const elements = document.querySelectorAll("table th");
      elements.forEach((el) => {
        el.style.width = 'auto'
      })
      document.querySelectorAll("table tbody tr td").forEach((td) => {
        td.style.width = '33%'
      })
    },
    async handlePageChange(data) {
      this.dataTemp = data;
    },
    async setCategories() {
      try {
        const {data} = await getAllCategory()
        this.categories = data
      } catch (error) {
        console.log(error)
      }
    },
    async setSubcategories() {
      this.subcategoriesLoading = true
      this.subcategories = []
      for (let category of this.categories) {
        try {
          const {data} = await getSubCategoriesByCategoryId(category.categoryId)
          data.forEach(element => element.categoryName = category.categoryName);
          this.subcategories.push(...data)
        } catch (error) {
          console.log(error)
        } finally {
          this.subcategoriesLoading = false
          await this.$nextTick(() => {
           const elements = document.querySelectorAll("table th");
           elements.forEach((el, index) => {
            el.style.position = "relative";
            el.id = index

            var grip = document.createElement("div");
            grip.innerHTML = "&nbsp;";
            grip.style.borderRight = 'black solid 1px'
            grip.style.top = 0;
            grip.style.right = 0;
            grip.style.bottom = 0;
            grip.style.width = "5px";
            grip.style.position = "absolute";
            grip.style.cursor = "col-resize";
            grip.addEventListener("mousedown", (e) => {
              this.thElm = el;
              this.startOffset = el.offsetWidth - e.pageX;
              this.lastWidth = 0
            });

            el.appendChild(grip);
           })
           document.addEventListener("mousemove", (e) => {
             if (this.thElm) {
               this.lastWidth  = this.thElm.offsetWidth;
               this.thElm.style.width = this.startOffset + e.pageX + "px";
                document.querySelectorAll("table tbody tr td").forEach((td, internalIndex) => {
                  if((internalIndex % 3) == Number(this.thElm.id)) {
                    td.style.width = this.startOffset + e.pageX + "px";
                  } else {
                    td.style.width = td.offsetWidth + "px";
                  }
                })
                this.$refs.table_resize.style.width = this.$refs.table_resize.offsetWidth + ((this.thElm.offsetWidth - this.lastWidth)) + "px"
             }
           });

            document.addEventListener("mouseup", () => {
              this.thElm = undefined;
            });
        });
        }
      }
    },
    goToAddNewSubCategory(){
      router.push(`/admin/subcategories/add`)
    },
    goToEditSubCategory(id) {
      router.push(`/admin/subcategories/edit/${id}`)
    },
    async showModal(subcategory){
      try {
        const {data} = await filesBySubcategory(subcategory.subCategoryId)
        this.filesRelated = data.map(file => ({name: file.fileName}))
      } catch (error) {
        console.log(error)
      }
      this.showModalProp = true
      this.subcategoryToDelete = subcategory
    },
    dismissAction() {
      this.subcategoryToDelete = {}
      this.showModalProp = false
    },
    async deleteSubCategory({subCategoryId}) {
      try {
        await deleteSubcategoryById(subCategoryId)
        await this.setSubcategories()
      } catch (error) {
        console.log(error)
      } finally {
        this.showModalProp = false
      }
    }
  },
}
</script>
<style lang="scss" scoped>
.hoverable:hover {
  cursor: pointer;
}
.fill-ods {
  background-color: #f5f5f5;
}
thead {
  border-top: #e0e0e0 solid 1px;
  border-right: #e0e0e0 solid 1px;
  border-left: #e0e0e0 solid 1px;
}
tr {
  border-top: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  border-left: 1px solid #e0e0e0;
}
tbody {
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
}
td {
  min-height: 50px;
}
th {
  min-height: 50px;
  height: 50px;
}
.table-primary {
  tr {
    th {
      vertical-align: middle;
    }
  }
}
.centered {
  text-align: center;
  vertical-align: middle;
}
.hoverable {
  td {
    vertical-align: middle;
  }
}
th {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}
td {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}
</style>