<template>
  <div class="d-flex flex-column justify-content-between vh-100">
    <div>
      <rd-navbar :title="$t('Settings (nav)')" />
      <div class="container bg-light my-3 p-3">
        <div class="row">
          <div class="col-12">
            <h2 class="ms-4 text-start">{{ $t('Settings') }}</h2>
          </div>
        </div>
        <div class="container mt-3 py-3">
          <div class="row g-4">
            <div class="col-12 col-lg-6">
              <rd-admin-card
                :name="$t('Language')"
                :description="$t('Set main language for the application')"
                @route="goTo('/admin/settings/language')"
              />
            </div>
            <div class="col-12 col-lg-6">
              <rd-admin-card
                :name="$t('Background')"
                :description="$t('Change background on login screen')"
                @route="goTo('/admin/settings/background')"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <rd-footer/>
    </div>
  </div>
</template>

<script>
import RdFooter from '@/components/rd-components/rd-footer.vue'
import RdNavbar from '@/components/rd-components/rd-navbar.vue'
import RdAdminCard from '@/components/rd-components/rd-admin-card.vue'
import router from '@/router'

export default {
  components: { 
    RdFooter, 
    RdNavbar, 
    RdAdminCard
  },
  methods: {
    goTo(route) {
      router.push(route)
    }
  },
}
</script>
<style scoped>
  
</style>