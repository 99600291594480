import httpClient from '../httpClient';

const END_POINT = '/api/subcategories';
const createSubcategory = (subcategory) => httpClient.post(`${END_POINT}`, subcategory)
const getAllSubcategory = () => httpClient.get(`${END_POINT}`)
const getSubcategoryById = (subcategoryId) => httpClient.get(`/subcategory/${subcategoryId}`)
const editSubcategoryById = (subcategoryId, subcategory) => httpClient.patch(`${END_POINT}/${subcategoryId}`, subcategory)
const deleteSubcategoryById = (id) => httpClient.delete(`${END_POINT}/${id}`)
const getSubCategoriesByCategoryId = (categoryId) => httpClient.get(`${END_POINT}/${categoryId}`);

export {
  createSubcategory,
  getAllSubcategory,
  getSubcategoryById,
  editSubcategoryById,
  deleteSubcategoryById,
  getSubCategoriesByCategoryId
}