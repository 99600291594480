<template>
<div class="w-100 p-3">
    <rd-exam-results v-if="show" :data="data"></rd-exam-results>
    <div v-if="show" class="d-flex justify-content-end">
        <button class="btn btn-lg btn-primary" @click="$router.back()">
            {{ $t('Done') }}
        </button>
    </div>
</div>
</template>

<script>
import rdExamResults from '../../components/rd-components/rd-exam-results.vue';
import {
    getAttempt
} from '@/services/exams/exam.api';
export default {
    name: 'RDExamResultsView',
    components: {
        rdExamResults,
    },
    data() {
        return {
            data: {
                last: {

                }
            },
            show: false,
        };
    },
    async mounted() {
        const {
            id: examId
        } = this.$route.params;
        const {
            userId
        } = this.$store.state.user;
        await this.getAttemp(examId, userId)
    },
    methods: {
        async getAttemp(examId, userId) {
            try {
                const {
                    data
                } = await getAttempt(examId, userId)
                if(data.allowed === false || !data.last) {
                    this.$router.back()
                    return;
                }
                this.data = data;
                this.show = true;
            } catch (error) {
                this.$router.back()
            }
        }
    }
};
</script>
