<template lang="">
  <div class="d-flex flex-column justify-content-between vh-100">
    <div>
      <rd-navbar :title="$t('Users (nav)')" />
      <div class="container bg-light my-3 p-3">
        <div class="row">
          <div class="col-12">
            <h2 class="ms-4 text-start">{{ $t('User Profile') }}</h2>
          </div>
        </div>
        <div class="container mt-3 py-3">
          <div class="row g-4">
            <div class="col-6">
              <rd-admin-card
                :name="$t('Viewed files')"
                :description="userInteraction.visualizeCount"
                @route="goTo('/files')"
              />
            </div>
            <div class="col-6">
              <rd-admin-card
                :name="$t('Downloaded files')"
                :description="userInteraction.downloadCount"
                @route="goTo('/admin/files')"
              />
            </div>
            <div class="col-6">
              <rd-admin-card
                :name="$t('Last session')"
                :description="convertDateFormat(userInteraction.interactionTime)"
                @route="goTo('/admin/categories')"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <rd-footer/>
    </div>
  </div>
</template>
<script>
import RdFooter from '@/components/rd-components/rd-footer.vue';
import RdNavbar from '@/components/rd-components/rd-navbar.vue';
import RdAdminCard from '@/components/rd-components/rd-admin-card.vue';
import { getInteractionsByUserId } from '@/services/interactions/interactions.api.js';
import { convertDateFormat } from '@/utils/convertFormat.js';

export default {
  data(){
    return {
      userInteraction: {},
      convertDateFormat
    }
  },

  components: {
    RdFooter,
    RdNavbar,
    RdAdminCard
  },
  computed: {
    userId(){
      return this.$route.params.id
    }
  },
  async mounted() {
    try {
      let { data } = await getInteractionsByUserId(this.userId);
      this.userInteraction = data;
    } catch (error) {
      console.log(error);
    }
  }
}
</script>
<style scoped>
  
</style>