<template>
  <div class="d-flex flex-column justify-content-between vh-100">
    <div>
      <rd-navbar :title="$t('Settings (nav)')" />
      <div class="container bg-light my-3 p-3">
        <div class="row">
          <div class="col-12">
            <h2 class="ms-4 text-start">{{ $t('Edit Language') }}</h2>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col-sm-12 col-md-6">
            <rd-input
              type="text"
              :placeholder="$t('Language name')" 
              :label="$t('Enter the language name')"
              required
              :value="languageName"
              @input="(e) => this.languageName = e.target.value"
            />
          </div>
        </div>

        <div class="row mb-4">
          <div class="col-sm-12 col-md-6">
            <rd-input
              type="text"
              :placeholder="$t('Language abbreviation')" 
              :label="$t('Enter the language abbreviation')"
              required
              :value="languageAbbreviation"
              @input="(e) => this.languageAbbreviation = e.target.value"
            />
          </div>
        </div>

        <div class="row mb-4">
          <div class="col-sm-12 col-md-6">
            <table width="100%">
              <tr>
                <td>
                  <label class="labelText">
                    {{ $t('Language File') }}
                  </label>
                </td>
              </tr>
              <tr>
                <td>
                  <rd-input
                    type="file"
                    :label="$t('Choose a file')"
                    required
                    @input="handleFileSelect"
                  />
                </td>
              </tr>
            </table>
          </div>
        </div>

        <!-- Buttons -->
        <div class="my-3 row justify-content-end">
          <div class="col-lg-4 col-md-6 col-sm-6">
            <button class="btn btn-outline-primary me-3" @click="goBack">
              {{ $t('Dismiss') }}
            </button>
            <button
              :disabled="disableSaveButton"
              class="btn btn-primary" 
              @click="editThisLanguage"
            >
              {{ $t('Edit Language (btn)') }}
            </button>
          </div>
        </div>

      </div>
    </div>
      <rd-footer />
    </div>
</template>
<script>
  import rdFooter from '../../components/rd-components/rd-footer.vue'
  import RdNavbar from '../../components/rd-components/rd-navbar.vue'
  import RdInput from '@/components/rd-components/rd-input.vue'
  import router from '@/router'
  import { getLanguageById, editLanguageById} from '@/services/languages/language.api.js'
  import { bufferToString } from '../../utils/convertFormat.js'

  export default {
    data() {
      return {
        languageName: '',
        languageAbbreviation: '',
        languageResourceFile: null,
        disableSaveButton: false,
      }
    },
    components: {
      rdFooter,
      RdNavbar,
      RdInput,
    },
    async mounted() {
      await this.setLanguage()
    },
    computed: {
      thisFileId() {
        return this.$route.params.id
      }
    },
    methods: {
      async setLanguage() {
        try {
          const { data } = await getLanguageById(this.thisFileId)
          this.languageName = data.languageName
          this.languageAbbreviation = data.languageAbbreviation
          this.languageResourceFile = bufferToString(data.languageResourceFile.data)
        } catch (error) {
          console.log(error)
        }
      },
      goBack() {
        router.back()
      },
      async editThisLanguage() {
        const language = {
          languageName: this.languageName,
          languageAbbreviation: this.languageAbbreviation,
          languageResourceFile: this.languageResourceFile
        }
        try {
          await editLanguageById(this.thisFileId, language)
          this.$store.dispatch('dispatchNotification', {
            title: 'Success!',
            message: 'Language edited.',
            type: 'success',
          });
          router.back()
        } catch (error) {
          this.$store.dispatch('dispatchNotification', {
            title: 'Error!',
            message: 'Could not edit this language.',
            type: 'error',
          });
        }
      },
      handleFileSelect(event) {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = e => this.languageResourceFile = e.target.result;
        reader.readAsText(file);
      }
    },

  }
</script>