<template>
  <div
    v-if="show" 
    class="alert alert-dismissible fade show shadow-sm alertPosition" 
    :class="[colorClass]"
    role="alert"
  >
    <span class="me-2">
      <i data-bs-toggle="tooltip" data-bs-placement="right" title="Status Notification" type="button" :class="[iconClass]"></i>
    </span>
    <template v-if="title">
      <strong class="mx-1">{{ title }}</strong>
      <span v-if="message">{{ message }}</span>
    </template>
    <button 
      v-if="closeButton" 
      data-bs-toggle="tooltip" data-bs-placement="right" title="Close Notification" type="button"
      class="btn-close"
      @click="handleHide"
    ></button>
  </div>
</template>
<script>
export default {
  name: 'rd-notification',
  props: {
    showAlert: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    message: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'success'
    },
    closeButton: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      show: false,
      SUCCESS: 'success',
      ERROR: 'error',
      DANGER: 'danger',
    }
  },
  watch: {
    showAlert(n) {
      this.show = n;
    }
  },
  mounted() {
    this.show = this.showAlert;
  },
  computed: {
    colorClass() {
      if (this.type.trim().toLowerCase() === this.ERROR) return 'alert-warning';
      if (this.type.trim().toLowerCase() === this.DANGER) return 'alert-danger';
      if (this.type.trim().toLowerCase() === this.SUCCESS) return 'alert-success';
      return 'alert-info'
    },
    iconClass() {
      if (this.type.trim().toLowerCase() === this.ERROR) return 'bi bi-exclamation-triangle-fill';
      if (this.type.trim().toLowerCase() === this.DANGER) return 'bi bi-dash-circle-fill';
      if (this.type.trim().toLowerCase() === this.SUCCESS) return 'bi bi-check-circle-fill';
      return 'bi bi-info';
    },
  },
  methods: {
    handleHide() {
      this.show = false;
      this.$store.dispatch('dispatchNotification', {
        show: false,
        timeout: 0,
      });
    }
  }
}
</script>
<style scoped>
.alertPosition {
  position: fixed;
  top: 25px;
  right: 15px;
}
</style>