<template lang="">
<div class="d-flex flex-column justify-content-between vh-100">
  <div>
    <rd-navbar :title="$t('Sessions (nav)')" />
    <div class="container bg-light my-3 p-3">
      <div class="row">
        <div class="col-12">
          <h2 class="ms-4 text-start">{{ code }}</h2>
        </div>
        <div class="col-12">
          <p class="ms-4 mb-0 text-start fw-bolder">{{ $t('Description') }}: <span class="fw-normal"> {{description}}</span></p>
        </div>
        <div class="col-12">
          <p class="ms-4 mb-0 text-start fw-bolder">{{ $t('Name') }}: <span class="fw-normal"> {{code}}</span></p>
        </div>
        <div class="col-12">
          <p class="ms-4 mb-0 text-start fw-bolder">{{ $t('Date & Time') }}:
            <span v-if="!allDay" class="fw-normal"> {{formatedDate(date)}} - {{formatHour(startTime)}} - {{formatHour(endTime)}}</span>
            <span v-else class="fw-normal">{{formatedDate(date)}} - {{ $t('All Day') }}</span>
          </p>
        </div>
        <div class="col-12">
          <p class="ms-4 mb-0 text-start fw-bolder">{{ $t('Trainer') }}:
            <span class="fw-normal"> {{trainer}}</span>
          </p>
        </div>
        <div class="row p-3 justify-content-between">
          <div class="p-0 m-0 mb-2 col-12 col-lg-4 ms-4">
            <rd-input class="w-100" :placeholder="$t('Search file by file name')" type="text" @input="(e) => this.search = e.target.value" />
          </div>
        </div>
        <div class="row p-3">
          <div class="ms-4 d-flex" style="gap: 1rem; flex-wrap: wrap;">
            <button style="width:auto;" class="btn btn-outline-primary" @click="handleClickCsvUpload">
              <i data-bs-toggle="tooltip" data-bs-placement="right" title="Upload Users" type="button" class="bi bi-arrow-up-circle-fill"></i> {{ $t('Upload Users') }}
              <input 
                v-show="false"
                ref="csv_input"
                type="file"
                class="form-control"
                :accept="'.csv'"
                @input="handleCsvUploaded($event)"
              >
            </button>
            <button style="width:auto;" class="btn btn-outline-primary" @click="downloadFeedback">
              <i data-bs-toggle="tooltip" data-bs-placement="right" title="Download Users" type="button" class="bi bi-arrow-down-circle-fill"></i> {{ $t('Download Users') }}
            </button>
          </div>
        </div>
        <div v-if="dataLoading">
          <rd-loading />
        </div>

        <div v-else>
          <div v-if="filteredUsers.length === 0" class="row p-3">
            <h2>
              {{ $t('No data found') }} <i data-bs-toggle="tooltip" data-bs-placement="right" title="No Users Found" type="button" class="bi bi-exclamation-triangle"></i>
            </h2>
          </div>

          <div v-else class="row p-3 d-flex justify-content-start">
            <div style="overflow-x: auto; width: 100%; max-width: 100%;">
              <div class="w-100">
                <table class="table text-start table-hover" ref="table_resize">
                  <thead class="table-primary" @contextmenu.prevent="resize">
                    <tr>
                      <th scope="col" class="centered"> <input class="form-check-input px-2 mx-2 hoverable" @click="handleSelectAll" :value="true" v-model="selectAll" type="checkbox"> </th>
                      <th scope="col">{{ $t('Users (table)') }}</th>
                      <th scope="col">{{ $t('Email (table)') }}</th>
                      <th scope="col">{{ $t('Role (table)') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="user in dataTemp" :key="user.userId" class="text-start hoverable" :class="{ 'fill-ods': index % 2 !== 0 }">
                      <td style="width: 10%; word-break: break-all;" scope="row" :header="$t('Icon (table)')" class="word-wrap centered"><input @click="handleClickUser(user)" :value="true" v-model="user.isIncluded" class="form-check-input px-2 mx-2 hoverable " type="checkbox"></td>
                      <td style="width: 30%; word-break: break-all;" scope="row" :header="$t('Users (table)')">
                        {{ `${user.userFirstname ?? ''} ${user.userLastname ?? ''}` }}
                      </td>
                      <td style="width: 30%; word-break: break-all;" scope="row" :header="$t('Users (table)')">
                        {{ user.userEmail }}
                      </td>
                      <td style="width: 30%; word-break: break-all;" :header="$t('role (table)')" >{{ getRoleText(user.roleId) }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <rd-paginator :items="filteredUsers" @page-change="handlePageChange" />
            <div class="row my-3 justify-content-end w-100">
              <div class="col-6 col-lg-2 d-flex">
                <button class="btn btn-outline-primary me-3" @click="goBack">
                  {{ $t('Dismiss') }}
                </button>
                <button
                  :disabled="false"
                  class="btn btn-primary w-100" 
                  @click="handleSave"
                >
                  {{ $t('Save') }}
                </button>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </div>
  <div>
    <rd-footer />
  </div>
</div>
</template>

  
<script>
import RdFooter from '../../components/rd-components/rd-footer.vue';
import RdNavbar from '../../components/rd-components/rd-navbar.vue';
import router from '@/router'
import RdInput from '@/components/rd-components/rd-input.vue'
import {
  getSession,
  getSessionsUsers,
  editSessionUsers,
  getAllUsers
} from '@/services/sessions/sessions.api.js'
import {
  convertToCSV
} from '@/utils/convertFormat';
import {
  downloadCSV
} from '@/utils/handleFile';
import RdPaginator from '@/components/rd-components/rd-paginator.vue'
import {
  mapState
} from 'vuex';
import { getRoleText } from '../../utils/utils';

export default {
  data() {
    return {
      date: null,
      startTime: null,
      endTime: null,
      code: null,
      trainer: null,
      allDay: true,
      sessionUsers: [],
      dataLoading: true,
      selectAll: false,
      dataTemp: [],
      search: '',
      showModal: false,
      currentUser: null,
      feedback: null,
      getRoleText,
      description: ''
    }
  },

  computed: {
    ...mapState(['isInternalMobile']),
    sessionId() {
      return this.$route.params.id
    },
    filteredUsers() {
      return this.sessionUsers.filter(
        user => `${user.userFirstname ?? ''} ${user.userLastname ?? ''}`.toLowerCase().includes(this.search.trim().toLowerCase())
      )
    },
  },
  watch: {
    filteredUsers(current) {
      this.selectAll = current.every((value) => value.isIncluded === true)
    }
  },
  async mounted() {
    await this.setSessionData()
  },
  components: {
    RdFooter,
    RdNavbar,
    RdInput,
    RdPaginator
  },
  methods: {
    async handleSave() {
      const usersToUpload = this.sessionUsers.filter(
        user => user.isIncluded
      ).map((user) => {
        return user.userId
      })
      try {
        await editSessionUsers(this.sessionId, {
          users: usersToUpload
        })
        this.$store.dispatch('dispatchNotification', {
          title: 'Notification',
          message: 'Saved',
          type: 'success',
        })
        setTimeout(() => {
          router.back()
        }, 1000);
      } catch (error) {
        console.log(error)
        this.$store.dispatch('dispatchNotification', {
          title: 'Notification',
          message: 'The users was not saved',
          type: 'error',
        })
      }
      
    },
    handleClickCsvUpload() {
      this.$refs['csv_input'].click()
    },
    async handleCsvUploaded(event) {
      const file = event.target.files[0];
      const text = await file?.text();
      if(file) {
        const newText = text.trim()
        const lines = newText.split(/\r?\n/);
        const headers = lines[0].split('|');
        const dataArray = [];
        for (let i = 1; i < lines.length; i++) {
          const obj = {};
          const currentLine = lines[i].split('|');
          for (let j = 0; j < headers.length; j++) {
            obj[headers[j]] = currentLine[j];
          }
          dataArray.push(obj);
        }
        const users = []
        dataArray.forEach((data) => {
          if(data.userId) {
            users.push(data.userId)
          }
        })
        this.sessionUsers.forEach((sessionUser) => {
          if(users.includes(sessionUser.userId + '')) {
            this.handleClickUser(sessionUser, true)
          }
        })
      }
    },
    handleClickUser(user, force=false) {
      user.isIncluded = force? true : !user.isIncluded
      this.selectAll = this.filteredUsers.every((value) => value.isIncluded === true)
    },
    async handlePageChange(data) {
      this.dataTemp = data;
    },
    handleSelectAll() {
      this.filteredUsers.forEach((sessionUser) => {
        sessionUser.isIncluded = !this.selectAll;
      })
      this.selectAll = !this.selectAll;
    },
    async downloadFeedback() {
      try {
        const usersToDownload = this.sessionUsers.map((user) => {
          return {
            userId: user.userId,
            name: `${user.userFirstname ?? ''} ${user.userLastname ?? ''}`,
            email: user.userEmail,
            role: getRoleText(user.roleId)
          }
        })
        const csv = await convertToCSV({ data: usersToDownload });
        downloadCSV({ data: csv, filename: 'user_list' });
      } catch (error) {
        console.log(error);
      }
    },
    formatHour(hour) {
      if (!hour) return ''
      let partsHour = hour.split(":");
      let hours = parseInt(partsHour[0]);
      let minutes = partsHour[1];

      let period = (hours >= 12) ? "PM" : "AM";

      hours = (hours > 12) ? hours - 12 : hours;
      hours = (hours == 0) ? 12 : hours;

      let formatedHour = hours + ":" + minutes + " " + period;

      return formatedHour;
    },
    formatedDate(date) {
      if (!date) {
        return ''
      }
      return date.split('T')[0].replace(/-/g, "/")
    },
    async setSessionData() {
      this.dataLoading = true
      try {
        const {
          data
        } = await getSession(this.sessionId)
        const {
          data: sessionUsers
        } = await getSessionsUsers(this.sessionId)
        const {
          data: users
        } = await getAllUsers();
        const usersIncluded = sessionUsers.map((sessionUser) => {
          return sessionUser.sessionsUser.userId
        })
        users.forEach((user) => {
          user.isIncluded = false;
          if(usersIncluded.includes(user.userId + '')) {
            user.isIncluded = true
          }
        })
        const {
          session,
          trainer
        } = data;
        
        
        const {
          date,
          startTime,
          endTime,
          code,
          allDay,
          description
        } = session
        this.allDay = allDay
        this.date = date;
        this.startTime = startTime;
        this.endTime = endTime;
        this.code = code;
        this.description = description
        this.trainer = `${trainer?.userFirstname ?? ''} ${trainer?.userLastname ?? ''}`
        this.sessionUsers = users
      } catch (error) {
        console.log(error)
      } finally {
        this.dataLoading = false;
      }
    },
    goBack() {
      router.back()
    }
  },
}
</script>

  
<style scoped>
.centered {
  text-align: center;
  vertical-align: middle;
}
</style>
