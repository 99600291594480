<template>
  <nav class="navbar navbar-light bg-light">
    <div class="container-fluid">
      <div class="d-flex justify-content-end w-100">
          &copy; {{ thisYear() }} {{ $t('Smartmatic') }}
      </div>
    </div>
  </nav>
</template>
<script>
export default {
  methods: {
    thisYear() {
      const current = new Date()
      return current.getFullYear()
    }
  },
}
</script>