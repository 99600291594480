<template>
  <div>
    <table width="100%">
      <tr>
        <td>
          <label
            :class="[required ? 'required labelText' : 'labelText' ]"
          >
            {{ label }}
          </label>
        </td>
      </tr>
      <tr>
        <td>
          <small class="help-text text-start mb-1">{{ helpText }}</small>
        </td>
      </tr>
      <tr>
        <td>
          <div 
            class="w-100 btn-group"
            role="group"
          >
            <template
              v-for="(item, index) in options" 
              :key="index"
            >
              <input
                type="radio" 
                class="btn-check" 
                :id="item.value" 
                :value="item.value"
                v-model="radioButtons"
              >
              <label
                class="btn btn-outline-primary" 
                :for="item.value"
              >
                {{ item.name }}
              </label>
            </template>
          </div>
        </td>
      </tr>
      <tr
        v-show="showError"
      >
        <td>
          <small
            class="float-left text-left invalid-custom-feedback"
          >{{ showError ? errorMsg: '' }}&nbsp;</small>
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
export default {
  props: {
    helpText: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false,
    },
    showError: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default: function() {
        return [];
      }
    },
  },
  data() {
    return {
      radioButtons: null
    }
  },
}
</script>
<style scoped>
</style>
