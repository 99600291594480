<template>
  <div class="row">
    <!-- Background image -->
    <div v-if="windowWidth >= 992 && !calcBackground" class="col-lg-5 bg-image main-image position-relative vh-100 p-0 m-0">
      <div class="card position-absolute start-50 bottom-0 translate-middle text-start">
        <div class="card-body">
          <h5 class="card-title">{{ $t('Trainer Resources (login)') }}</h5>
          <p class="card-text">{{ $t('Find the manuals and downloads you need to operate Smartmatic\'s systems.') }}</p>
        </div>
      </div>
    </div>
    <div :style="calcBackgroundStyle" v-else-if="windowWidth >= 992 && calcBackground" class="col-lg-5 bg-image position-relative vh-100 p-0 m-0">
      <div class="card position-absolute start-50 bottom-0 translate-middle text-start">
        <div class="card-body">
          <h5 class="card-title">{{ $t('Trainer Resources (login)') }}</h5>
          <p class="card-text">{{ $t('Find the manuals and downloads you need to operate Smartmatic\'s systems.') }}</p>
        </div>
      </div>
    </div>
    <!-- Login form -->
    <div class="col-lg-7 d-flex justify-content-center align-items-center min-vh-100 p-0 m-0">
      <form-login @route="goToMain"/>    
    </div>
    
  </div>
</template>
<script>
import FormLogin from './form-login.vue'
import router from '@/router'
import { mapState } from 'vuex'

export default {
  components: {
    FormLogin
  },
  mounted() {
    this.$store.commit('setStatusLogin', false)
  },
  computed: {
    ...mapState(['windowWidth']),
    calcBackground() {
      if(!this.$store.state.settings) {
        return false;
      }
      if(!this.$store.state.settings.background) {
        return false;
      }
      return `/settings/${this.$store.state.settings.background}`;
    },
    calcBackgroundStyle() {
      if(!this.calcBackground) {
        return {};
      }
      return {
        background: `linear-gradient(rgba(0, 0, 255, 0.4), rgba(0, 0, 255, 0.4)), url('${this.calcBackground}')`,
        'background-repeat': 'no-repeat',
        'background-size': 'cover',
        height: '100vh'
      };
    }
  },
  methods: {
    goToMain() {
      router.push('/files')
    },
  }
}
</script>
<style scoped>
.main-image {
  background: linear-gradient(rgba(0, 0, 255, 0.4), rgba(0, 0, 255, 0.4)), url('../../assets/login/login-main-image.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}
</style>