<template>
  <div @click.self="$emit('hide')" class="modal">
    <div class="embed-parent" :class="[(type == 'rd-not-valid-viewer' || (type == 'rd-pdf-viewer' && windowWidth <= 768 )) ? 'mini' : '', type== 'RDExamResults' ? 'auto': '']">
      <div class="embed-container">
        <component :is="type" :data="type== 'RDExamResults' ? JSON.parse(content) : content" />
        
      </div>
      <i data-bs-toggle="tooltip" data-bs-placement="right" title="Close Modal" type="button" @click.self="$emit('hide')" class="bi bi-x-circle close"></i>
    </div>
    
  </div>
</template>
<script>
import RdImageViewer from './rd-image-viewer.vue'
import RdPdfViewer from './rd-pdf-viewer.vue'
import RdNotValidViewer from './rd-not-valid-viewer.vue'
import RdVideoViewer from './rd-video-viewer.vue'
import RDExamResults from './rd-exam-results.vue'
import { mapState } from 'vuex';

export default {
  name: 'RdModal',
  props: {
    type: {
      type: String,
      required: true
    },
    content: {
      type: String,
      required: true
    },
  },
  components: {
    RdImageViewer,
    RdPdfViewer,
    RdNotValidViewer,
    RdVideoViewer,
    RDExamResults
  },
  computed: {
    ...mapState(['windowWidth'])
  }
}
</script>
<style scoped>
.mini {
  height: 10% !important;
  width: 40% !important;
  max-height: 100px;
  max-width: 900px;
}
.filter {
  font-weight: 900;
}

.hoverable:hover {
  cursor: pointer;
}
.grid-container {
  /* width: 100vw; */
  padding: 0;
  overflow-x: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  overflow-y: scroll;
}
.grid-container::-webkit-scrollbar {
  display: none;
}
/**Agregar breakpoints para agregar los overflow */
.vue-grid-layout {
    background: #fff;
    min-width: 1000px;
}
.vue-grid-item:not(.vue-grid-placeholder) {
    background: #ffff;
    border-bottom: 1px solid rgb(222,226,230);
}
.vue-grid-item:nth-child(-n+5) {
  border: none;
}
.row-hover {
  background: rgba(0, 0, 0, 0.075);
}
.custom-container {
  height: 100%;
  padding: 1rem;
}
.vue-grid-item .resizing {
    opacity: 0.9;
}
.vue-grid-item .static {
    background: #cce;
}
.vue-grid-item .text {
    font-size: 16px;
    text-align: initial;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 100%;
    width: 100%;
    padding: 1rem;
}
.vue-grid-item .no-drag {
    height: 100%;
    width: 100%;
}
.vue-grid-item .minMax {
    font-size: 12px;
}
.vue-grid-item .add {
    cursor: pointer;
}
.vue-draggable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    left: 0;
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><circle cx='5' cy='5' r='5' fill='#999999'/></svg>") no-repeat;
    background-position: bottom right;
    padding: 0 8px 8px 0;
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    cursor: pointer;
}
.custom-header {
  background: #dcd3f4;
  border: none;
  font-weight: bold;
  font-size: 20px !important;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.4); /* fondo negro con opacidad del 40% */
  z-index: 9999; /* asegura que el modal se superpone a otros elementos */
  display: flex;
  justify-content: center;
  align-items: center;
}

.embed-container {
  position: relative;
  width: 80%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.embed-container img {
  width: 100%;
  height: 100%;
}
.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.embed-parent {
  height: 80%;
  width: 80%;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  position: relative;
  border-radius: 0.375rem;
}
.close {
  position: absolute;
  top: 5px;
  right: 10px;
  font-size: 2rem;
  cursor: pointer;
}
@media (max-width: 768px) {
  .embed-parent {
    height: 25%;
  }
  .close {
    font-size: 1rem;
  }
  .mini {
    width: 80% !important;
  }
}
.auto {
  height: auto !important;
}
</style>
