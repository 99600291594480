<script>
import Multiselect from 'vue-multiselect'
export default {
  props: {
    required: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    helpText: {
      type: String,
      default: ''
    },
    maxlength: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    errorMsg: {
      type: String,
      default: ''
    },
    showError: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    iconRight: {
      type: Boolean,
      default: true
    },
    icon: {
      type: String,
      default: ''
    },
    disable: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: ''
    },
    typeFile: {
      type: String,
      default: '.json'
    },
    items: {
      type: Array,
      default: function() {
        return [];
      }
    },
    checked: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: function() {
        return [];
      }
    },
    selected: {
      type: Number,
      default: null
    },
    rows: {
      type: Number,
      default: null
    },
    maxRows: {
      type: Number,
      default: null
    },
    regex: {
      type: String,
      default: null
    },
    value: {
      type: String,
      default: null
    },
    showPassIcon: {
      type: Boolean,
      default: false
    },
    initialValue: {
      type: Array,
      default: () => []
    },
    placeholderToTag: {
      type: String,
      default: ''
    },
    deselectText: {
      type: String,
      default: `Can't remove this value`
    },
    allowEmpty: {
      type: Boolean,
      default: false
    },
    autocomplete: {
      type: Boolean,
      default: false
    },
    propagation: {
      type: Boolean,
      default: true
    },
    accept: {
      type: String,
      default: ""
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showPassword: false,
      datalistValue: '',
      selectValue: null
    }
  },
  computed: {
    _type() {
      return this.showPassword ? 'text' : this.type;
    }
  },
  methods: {
    handleInput(e) {
      if(this.propagation) {
        this.$emit('input', e)
      }
    },
    showPasswordClick() {
      this.showPassword = !this.showPassword;
    },
  },
  components: {
    Multiselect
  },
  watch: {
    initialValue(val) {
      this.selectValue = val
    },
    selectValue(newVal) {
      this.$emit('handleAddTagging', newVal)
    }
  }
}
</script>

<template lang="en-EN">
  <div class="wrapper">
    <div v-if="_type === 'text' || _type === 'password' || _type === 'email' || _type === 'number' ">
      <table width="100%">
        <tr>
          <td>
            <label
              :class="[required ? 'required labelText' : 'labelText' ]"
            >
              {{ label }}
            </label>
          </td>
        </tr>
        <tr>
          <td>
            <small class="help-text text-start mb-1">{{ helpText }}</small>
          </td>
        </tr>
        <tr>
          <td>
            <input
              v-model="value"           
              :type="_type"
              :class="[ iconRight ? 'form-control' : 'form-control padd-r']"
              :placeholder="placeholder"
              :disabled="disable"
              :maxlength="maxlength"
              :pattern="regex"
              @input="handleInput"
              :autocomplete="(_type === 'password' && !autocomplete) ? 'new-password' : 'on'"
            >
            <div
              v-if="showPassIcon"
              class="iconLeft iconclick"
            >
              <div
                class="iconInput"
                @click="showPasswordClick"
              >
                <i data-bs-toggle="tooltip" data-bs-placement="right" title="Hide Password" type="button" v-if="showPassword" class="bi bi-eye"></i>
                <i data-bs-toggle="tooltip" data-bs-placement="right" title="Show Password" type="button" v-if="!showPassword" class="bi bi-eye-slash"></i>
              </div>
            </div>
          </td>
        </tr>
        <tr
          v-show="showError"
        >
          <td class="text-start">
            <small
              class="text-danger"
            >{{ showError ? errorMsg: '' }}&nbsp;</small>
          </td>
        </tr>
      </table>
    </div>

    <label
      v-else-if="_type === 'checkbox'"
      :class="[required ? 'required checkbox labelText' : 'checkbox labelText']"
    >
      {{ label }}
      <input
        :name="name"
        type="checkbox"
        :value="value"
      >
      <span class="checkbox-checkmark" />
    </label>

    <div
      v-else-if="_type === 'radio'"
      class="d-flex flex-column"
    >
      <label
        v-for="(item, key) of items"
        :key="key"
        :class="[required ? 'required radio labelText' : 'radio labelText']"
      >
        {{ item.label }}
        <input
          v-model="checked"
          type="radio"
          :value="item.value"
          :disabled="disable"
          @input="handleInput"
        >
        <span class="radio-checkmark" />
      </label>
    </div>

    <div v-else-if="_type === 'file'" class="input-group mb-3">
      <table width="100%">
        <tr>
          <td>
            <label
              :class="[required ? 'required labelText' : 'labelText' ]"
            >
              {{ label }}
            </label>
          </td>
        </tr>
        <tr>
          <td>
            <!-- TODO: allow only csv files| -->
            <input 
              type="file"
              :disabled="disable"
              class="form-control"
              :accept="accept"
              @input="handleInput"
            >
          </td>
        </tr>
        <tr
          v-show="showError"
        >
          <td class="text-start">
            <small
              class="text-danger"
            >{{ showError ? errorMsg: '' }}&nbsp;</small>
          </td>
        </tr>
      </table>
    </div>
    <div v-else-if="_type === 'file_image'" class="input-group mb-3">
      <table width="100%">
        <tr>
          <td>
            <label
              :class="[required ? 'required labelText' : 'labelText' ]"
            >
              {{ label }}
            </label>
          </td>
        </tr>
        <tr>
          <td>
            <input 
              type="file"
              :disabled="disable"
              class="form-control" 
              @input="handleInput"
              accept=".png, .jpeg"
              :multiple="multiple"
            >
          </td>
        </tr>
        <tr
          v-show="showError"
        >
          <td class="text-start">
            <small
              class="text-danger"
            >{{ showError ? errorMsg: '' }}&nbsp;</small>
          </td>
        </tr>
      </table>
    </div>

    <div v-else-if="_type === 'select'" class="w-100">
      <table width="100%"> 
        <tr>
          <td>
            <label
              :class="[required ? 'required labelText' : 'labelText' ]"
            >
              {{ label }}
            </label>    
          </td>
        </tr>
        <tr>
          <td>
            <small class="help-text text-start mb-1">{{ helpText }}</small>
          </td>
        </tr>
        <tr>
          <td>
            <multiselect
              v-model="selectValue"
              :tag-placeholder="placeholderToTag" 
              :placeholder="placeholder" 
              :searchable="true"
              label="name" 
              track-by="name"
              :clear-on-select="false"
              :deselect-label="deselectText" 
              :allow-empty="allowEmpty"
              :options="items"
              :taggable="true" 
              class="shadow-sm"
            />
          </td>
        </tr>
      </table>
    </div>

  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style scoped>

.required::after {
  color: #6040b9;
  content: ' *';
  font-size: 16px;
}

.wrapper {
  width: 100%;
}


.labelText {
  margin: 0;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: grey;
  float: left;
  border-radius: 4px;
}

/*################## text | password | email | number ################*/

.form-control.padd-r {
  padding-left: 20%;
}

.form-control {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: grey;
  box-shadow: inset 0 0 0 1px grey !important;
  background-color: white;
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
  min-height: 40px !important;
}
.form-control:focus {
  box-shadow: 0 0 0 2px blue, inset 0 0 0 1px grey !important;
  background-color: white;
}
.form-control:disabled {
  border-color: grey;
}
.form-control.is-invalid {
  border-color: #8f1e1e;
}

.help-text {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: grey;
}

.iconInput {
  vertical-align: middle;
  float: left;
  margin-left: -1px;
  margin-top: -33px;
  position: relative;
  margin-bottom: -10px;
  z-index: 2;
  margin-right: 1rem;
  display: initial;
}

.iconLeft {
  display: flex;
  width: 100%;
  flex-direction: row-reverse;
  padding-left: 10px;
}

.iconClick{
  cursor: pointer;
}

/*##################radio################*/
.radio {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.radio-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: white;
  border-radius: 50%;
  border: 2px solid black;
}

/* On mouse-over, add a grey background color */
.radio:hover input ~ .radio-checkmark {
  background-color: transparent;
}

/* When the radio button is checked, add a blue background */
.radio input:checked ~ .radio-checkmark {
  background-color: transparent;
  border: 2px solid #522eb3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.radio-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio input:checked ~ .radio-checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radio .radio-checkmark:after {
  top: 3px;
  left: 3px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #522eb3;
}

.radio input:disabled ~ span {
  background-color: grey !important;
}

/*####################checkbox################*/
/* The container */
.checkbox {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkbox-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: white;
  border: 2px solid black;
}

/* On mouse-over, add a grey background color */
.checkbox:hover input ~ .checkbox-checkmark {
  background-color: transparent;
}

/* When the checkbox is checked, add a blue background */
.checkbox input:checked ~ .checkbox-checkmark {
  background-color: transparent;
  border: 2px solid #522eb3;
  background-color: #522eb3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkbox-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox input:checked ~ .checkbox-checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox .checkbox-checkmark:after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* Multiselect component styles */
.multiselect__tags {
  color: grey;
  box-shadow: inset 0 0 0 1px grey !important;
  background-color: white;
  min-height: 40px !important;
}

.multiselect__tags.padd-r {
  padding-left: 20%;
}

.multiselect__element {
  background: #3b82ee;
  color: white;
  padding: 0;
  margin: 0;
}

.multiselect__option,
.multiselect__option--selected,
.multiselect__option--highlight {
  background: #3b82ee;
  color: white;
  opacity: .9;
}

.multiselect__option--selected.multiselect__option--highlight,
.multiselect__option--selected.multiselect__option--highlight::after {
  background: #1e71ee;

}

.multiselect__option::after, 
.multiselect__option--highlight {
  background: #3b82ee;
}

.multiselect__tag {
    background: #3b82ee
}
.multiselect__tag-icon::after {
  color: #fff;
}

.required::after {
  color: #6040b9;
  content: ' *';
  font-size: 16px;
}

.wrapper {
  width: 100%;
}

.labelText {
  margin: 0;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: grey;
  float: left;
  border-radius: 4px;
}
</style>