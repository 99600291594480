<template>
  <div class="d-flex flex-column justify-content-between vh-100">
    <div>
      <rd-navbar :title="$t('Sessions (nav)')" />
      <div class="container bg-light rounded-3 my-3 p-3">
        <div class="row">
          <div class="col-12">
            <h2 class="ms-4 text-start">{{ $t('Session') }}</h2>
          </div>
        </div>
        <div class="row p-3 justify-content-between">
          <!-- Search bar -->
          <div
            class="p-0 m-0 mb-2 col-12 col-lg-3"
          >
            <rd-input
              class="w-100"
              :placeholder="$t('Search session by name')" 
              type="text"
              @input="(e) => this.search = e.target.value"
            />
          </div>

          <!-- buttons -->
          <div class="p-3 m-0 mb-2 col-12 col-lg-6">
            <div class="row justify-content-end gap-1">

              <div class="p-0 m-0 mb-2 col-12 col-lg-5">
                <button
                  class="btn btn-primary w-100" 
                  @click="goToAddSession"
                >
                  <i data-bs-toggle="tooltip" data-bs-placement="right" title="Add Session" type="button" class="bi bi-plus-circle-fill"></i> {{ $t('Add Session') }}
                </button>
              </div>
              
            </div>
          </div>
        </div>
        <div v-if="sessionsLoading">
          <rd-loading /> 
        </div>

        <div v-else>
          <div
            v-if="filteredSessions.length === 0" 
            class="row p-3"
          >
            <h2>
              {{ $t('No data found') }} <i data-bs-toggle="tooltip" data-bs-placement="right" title="No sessions Found" type="button" class="bi bi-exclamation-triangle"></i>
            </h2>
          </div>

          <div v-else class="row p-3 d-flex justify-content-start">
            <div style="overflow-x: auto; width: 100%; max-width: 100%;">
              <div class="w-100">
                <table class="table text-start table-hover" ref="table_resize">
                  <thead class="table-primary" @contextmenu.prevent="resize">
                    <tr>
                      <th scope="col">{{ $t('Session (table)') }}</th>
                      <th scope="col">{{ $t('Date (table)') }}</th>
                      <th scope="col">{{ $t('Trainer (table)') }}</th>
                      <th scope="col">{{ $t('Status (table)') }}</th>
                      <th scope="col">{{ $t('Total Attendance (table)') }}</th>
                      <th scope="col" class="text-center">{{ $t('Actions (table)') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(session, index) in dataTemp" :key="index"
                      class="text-start hoverable"
                      :class="{ 'fill-ods': index % 2 !== 0 }"
                    >
                      <td style="width: 16.66%; word-break: break-all;" scope="row" :header="$t('Session (table)')" class="word-wrap" >{{ session.code }}</td>
                      <td style="width: 16.66%; word-break: break-all;" scope="row" :header="$t('Date (table)')" >
                        <span v-if="!session.allDay" class="fw-normal"> {{formatedDate(session.date)}} {{formatHour(session.startTime)}} to {{formatHour(session.endTime)}}</span>
                        <span v-else class="fw-normal"> {{formatedDate(session.date)}} {{ $t('All Day') }}</span>
                      </td>
                      <td style="width: 16.66%; word-break: break-all;" scope="row" :header="$t('Trainer (table)')" >
                        {{ session.trainer?  `${session.trainerInfo.userFirstname} ${session.trainerInfo.userLastname}` : 'No trainer found'}}
                      </td>
                      <td style="width: 16.66%; word-break: break-all;" scope="row" :header="$t('Status (table)')" >
                        <select
                            class="form-select"
                            aria-label="select availability"
                            v-model="session.state"
                            @change="handleStatusSelected($event, session)"
                          >
                          <option v-for="(item, index) in status" :key="index" :value="item.value">{{ item.name }}</option>
                        </select>
                      </td>
                      <td style="width: 8.33%; word-break: break-all;" scope="row" :header="$t('Total Attendance (table)')" >
                        {{ session.sessionUsers }}
                      </td>
                      <td style="width: 24.99%; word-break: break-all;" :header="$t('Actions (table)')">
                        <div class="container">
                          <div class="row text-center">
                            <div class="col p-0 m-0">
                              <button 
                                class="btn btn-label"
                                @click="goToEditSession($event, session.id)"
                              >
                                <i data-bs-toggle="tooltip" data-bs-placement="right" title="Edit Session" type="button" class="bi bi-pencil-square"></i>
                              </button>

                              <button 
                                class="btn btn-label" 
                                @click="showModal($event, session)"
                              >
                                <i data-bs-toggle="tooltip" data-bs-placement="right" title="Delete Session" type="button" class='bi bi-trash-fill'></i>
                              </button>
                              <button 
                                class="btn btn-label" 
                                @click="goToAddUsers($event, session.id)"
                              >
                                <i data-bs-toggle="tooltip" data-bs-placement="right" title="Add session Users" type="button" class='bi bi-person'></i>
                              </button>
                              <button 
                                class="btn btn-label" 
                                @click="goToFeedbackSession($event, session.id)"
                              >
                                <i data-bs-toggle="tooltip" data-bs-placement="right" title="Session Feedback" type="button" class='bi bi-chat-left-text'></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            
            <rd-paginator :items="filteredSessions" @page-change="handlePageChange" />
          </div>
        </div>
      </div>
    </div>
    <div>
      <rd-footer/>
    </div>
    <rd-confirmation-modal
      v-if="showModalProp"
      modalTitle="Delete session permanently?"
      confirmButton="Delete"
      dismissButton="Dismiss"
      @confirmAction="deleteFile(sessionToDelete)"
      @dismissAction="dismissAction"
    />
  </div>
</template>
<script>
import rdFooter from '@/components/rd-components/rd-footer.vue'
import RdNavbar from '@/components/rd-components/rd-navbar.vue'
import router from '@/router'
import { getSessions, deleteSession, getSessionsState, updateSessionState } from '@/services/sessions/sessions.api.js'
import RdConfirmationModal from '@/components/rd-components/rd-confirmation-modal.vue'
import RdLoading from '@/components/rd-components/rd-loading.vue'
import RdInput from '@/components/rd-components/rd-input.vue'
import RdPaginator from '@/components/rd-components/rd-paginator.vue'

export default {
  data() {
    return {
      test: null,
      sessions: [],
      sessionsLoading: true,
      showModalProp: false,
      sessionToDelete: {},
      search: '',
      hasSpinner: true,
      dataTemp: [],
      counter: 0,
      thElm: null,
      startOffset: null,
      lastWidth: 0,
      status: [],
    }
  },
  components: { 
    rdFooter, 
    RdNavbar,
    RdConfirmationModal,
    RdLoading,
    RdInput ,
    RdPaginator
  },
  computed: {
    filteredSessions() {
      return this.sessions.filter(
          session => session.code.toLowerCase().includes(this.search.trim().toLowerCase())
        )
    },
    user(){
      return this.$store.getters.getUser;
    },
  },
  async mounted() {
    try {
      await this.setSession();
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    formatHour(hour) {
      if (!hour) return ''
      let partsHour = hour.split(":");
      let hours = parseInt(partsHour[0]);
      let minutes = partsHour[1];

      let period = (hours >= 12) ? "PM" : "AM";

      hours = (hours > 12) ? hours - 12 : hours;
      hours = (hours == 0) ? 12 : hours;

      let formatedHour = hours + ":" + minutes + " " + period;

      return formatedHour;
    },
    formatedDate(date) {
      if (!date) {
        return ''
      }
      return date.split('T')[0].replace(/-/g, "/")
    },
    async handleStatusSelected(event, session) {
      session.state = event.target.value;
      try {
        await updateSessionState(session.id, {
          state: session.state
        })
        this.$store.dispatch('dispatchNotification', {
          title: 'Success!',
          message: 'Session updated.',
          type: 'success',
        });
      } catch (error) {
        console.log(error)
        this.$store.dispatch('dispatchNotification', {
          title: 'Error!',
          message: 'Session could not be updated.',
          type: 'error',
        });
      }
    },
    resize() {
      this.$refs.table_resize.style.width = '100%'
      const elements = document.querySelectorAll("table th");
      elements.forEach((el) => {
        el.style.width = 'auto'
      })
      document.querySelectorAll("table tbody tr td").forEach((td, index) => {
        if(index % 6 == 4) {
          td.style.width = '8.33%'
        } else if(index % 6 == 5) {
          td.style.width = '24.99%'
        } else {
          td.style.width = '16.66%'
        }
      })
    },
    async handlePageChange(data) {
      this.dataTemp = data;
    },
    showModal(e, session){
      e.stopPropagation()
      this.showModalProp = true
      this.sessionToDelete = session
    },
    dismissAction() {
      this.sessionToDelete = {}
      this.showModalProp = false
    },
    async deleteFile({ id }) {
      try {
        await deleteSession(id)
        await this.setSession()
      } catch (error) {
        console.log(error)
      } finally {
        this.showModalProp = false
        this.sessionToDelete = {}
      }
    },
    async setSession() {
      try {
        this.sessionsLoading = true
        const { data } = await getSessions();
        const { data:sessionState } = await getSessionsState();
        this.sessions = data
        this.status = sessionState.map(({ id, state }) => (
          { value: id, name: state }
        ));
      } catch (error) {
        console.log(error)
      } finally {
        this.sessionsLoading = false
        await this.$nextTick(() => {
           const elements = document.querySelectorAll("table th");
           elements.forEach((el, index) => {
            el.style.position = "relative";
            el.id = index

            var grip = document.createElement("div");
            grip.innerHTML = "&nbsp;";
            grip.style.borderRight = 'black solid 1px'
            grip.style.top = 0;
            grip.style.right = 0;
            grip.style.bottom = 0;
            grip.style.width = "5px";
            grip.style.position = "absolute";
            grip.style.cursor = "col-resize";
            grip.addEventListener("mousedown", (e) => {
              this.thElm = el;
              this.startOffset = el.offsetWidth - e.pageX;
              this.lastWidth = 0
            });

            el.appendChild(grip);
           })
           document.addEventListener("mousemove", (e) => {
             if (this.thElm) {
               this.lastWidth  = this.thElm.offsetWidth;
               this.thElm.style.width = this.startOffset + e.pageX + "px";
                document.querySelectorAll("table tbody tr td").forEach((td, internalIndex) => {
                  if((internalIndex % 6) == Number(this.thElm.id)) {
                    td.style.width = this.startOffset + e.pageX + "px";
                  } else {
                    td.style.width = td.offsetWidth + "px";
                  }
                })
                this.$refs.table_resize.style.width = this.$refs.table_resize.offsetWidth + ((this.thElm.offsetWidth - this.lastWidth)) + "px"
             }
           });

            document.addEventListener("mouseup", () => {
              this.thElm = undefined;
            });
        });
      }
    },
    goToAddSession() {
      router.push(`/session/add`)
    },
    goToFeedbackSession(e, uuid){
      e.stopPropagation()
      router.push(`/session/feedback/${uuid}`)
    },
    goToAddUsers(e, uuid){
      e.stopPropagation()
      router.push(`/session/users/${uuid}`)
    },
    goToEditSession(e, uuid){
      e.stopPropagation()
      router.push(`/session/edit/${uuid}`)
    }
  },
  
}
</script>
<style lang="scss" scoped>
.hoverable:hover {
  cursor: pointer;
}
.hoverable:hover {
  cursor: pointer;
}
.fill-ods {
  background-color: #f5f5f5;
}
thead {
  border-top: #e0e0e0 solid 1px;
  border-right: #e0e0e0 solid 1px;
  border-left: #e0e0e0 solid 1px;
}
tr {
  border-top: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  border-left: 1px solid #e0e0e0;
}
tbody {
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
}
td {
  min-height: 50px;
}
th {
  min-height: 50px;
  height: 50px;
}
.table-primary {
  tr {
    th {
      vertical-align: middle;
    }
  }
}
.centered {
  text-align: center;
  vertical-align: middle;
}
.hoverable {
  td {
    vertical-align: middle;
  }
}
th {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}
td {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}
</style>