import { getInteractionTypes } from "@/services/interactions/interactions.api";

const profiles = {
  ADMIN: '1'
};

const roles = {
  ADMIN: 1,
  UPLOADER: 2,
  VIEWER: 3,
  VIEWER_DOWNLOADER: 4,
  TRAINER: 5,
}

const ENTITIES_TO_IMPORT = {
  USERS: {
    value: 0,
    label: "Users",
  },
  CATEGORIES: {
    value: 1,
    label: "Categories",
  },
  SUB_CATEGORIES: {
    value: 2,
    label: "Sub Categories",
  },
  SESSIONS: {
    value: 3,
    label: "Sessions",
  },
};

const ATTEMPT_STATUS = {
  PENDING: 'pending',
  APPROVED: 'approved',
  FAILED: 'failed',
  IN_PROGRESS: 'in_progress'
};

const interactionTypes = async () => {
  try {
    let types = {};
    let { data } = await getInteractionTypes();
    data.forEach(({ interactionTypeName, interactionTypeId }) => {
      types[interactionTypeName.toUpperCase()] = interactionTypeId;
    });
    return types;
  } catch (error) {
    console.log(error);
  }
};

const notAllowedFileExtensions = [
  ".exe",
  ".pif",
  ".application",
  ".gadget",
  ".msi",
  ".hta",
  ".htaccess",
  ".jar",
  ".bat",
  ".cmd",
  ".js",
  ".jse",
  ".svg",
  ".reg",
  ".vbs",
  ".vb",
  ".html",
  ".ddl",
  ".tmp",
  ".py",
  ".com",
  ".odt",
  ".ods",
];

const QUESTIONS_TEMPLATE = `
  ID|question|answer|valid|image|multiple
  Q1|¿Pregunta 1?|OP1|1|image.png|true
  Q1|¿Pregunta 1?|OP2|1||
  Q1|¿Pregunta 1?|OP3|1||
  Q1|¿Pregunta 1?|OP4|0||
  Q2|¿Pregunta 2?|OP1|0|image2.png|true
  Q2||OP2|1||
  Q2||OP3|1||
  Q2||OP4|1||
  Q3|¿Pregunta 3?|OP1|0|image3.png|true
  Q3||OP2|1||
  Q3||OP3|1||
  Q3||OP4|0||
  Q4|¿Pregunta 4?|OP1|0||false
  Q4||OP2|0||
  Q4||OP3|0||
  Q4||OP4|1||
  Q5|¿Pregunta 5?|OP1|0||false
  Q5||OP2|0||
  Q5||OP3|0||
  Q5||OP4|1||
  Q6|¿Pregunta 6?|OP1|0||false
  Q6||OP2|0||
  Q6||OP3|0||
  Q6||OP4|1||
`

export {
  profiles,
  roles,
  interactionTypes,
  notAllowedFileExtensions,
  ENTITIES_TO_IMPORT,
  QUESTIONS_TEMPLATE,
  ATTEMPT_STATUS
};