<template>
  <td>
    <input
      type="checkbox"
      :value="parseInt(category.categoryId)"
      v-model="categoryToAdd"
      @change="updateSubcategories"
    />
    {{ category.categoryName }}
  </td>
  <td>
    <div
      v-for="{
        subCategoryName,
        subCategoryId,
      } in category.subcategories"
      :key="subCategoryId"
    >
      <input
        type="checkbox"
        :value="parseInt(subCategoryId)"
        v-model="subcategoriesToAdd"
        @click="emitCheckedEvent($event)"
        :id="`subcategory-${subCategoryId}`"
        class="me-2"
      />
      <label :for="subCategoryName">
        {{ subCategoryName }}
      </label>
    </div>
  </td>
</template>
<script>
export default {
  data() {
    return {
      categoryToAdd: false,
      subcategories: [],
      subcategoriesToAdd: []
    }
  },
  props: {
    category: {
      type: Object,
      default: function() { return {} }
    },
    currentSubcategories: {
      type: Array,
      default: function() { return [] }
    }
  },
  mounted() {
    this.subcategories = this.category.subcategories.map(sub => parseInt(sub.subCategoryId))
    for (const sub of this.subcategories) {
      if (this.currentSubcategories.includes(sub)) {
        this.subcategoriesToAdd.push(sub)
      }
    }
    this.categoryToAdd = this.subcategoriesToAdd.length === this.subcategories.length

  },
  watch: {
    currentSubcategories(newValue) {
      this.subcategoriesToAdd = []
      for (const sub of this.subcategories) {
        if (newValue.includes(sub)) {
          this.subcategoriesToAdd.push(sub)
        }
      }
    },
    subcategoriesToAdd(newValue) {
      this.categoryToAdd = newValue.length === this.subcategories.length
    }
  },
  methods: {
    emitCheckedEvent(e) {
      const data = {
        subcategoryId: parseInt(e.target.value)
      }
      if (this.subcategoriesToAdd.includes(data.subcategoryId)) this.subcategoriesToAdd = this.subcategoriesToAdd.filter(sub => sub !== data.subcategoryId)
      if (!this.subcategoriesToAdd.includes(data.subcategoryId)) {
        this.subcategoriesToAdd.push(data.subcategoryId)
      }
      this.categoryToAdd = this.subcategoriesToAdd.length === Object.values(this.subcategories).length
      this.$emit('addSubcategories', data)
    },
    updateSubcategories() {
      if (this.categoryToAdd) {
        for (const subcategory of this.subcategories) {
          if (!this.subcategoriesToAdd.includes(subcategory)) {
            this.subcategoriesToAdd.push(subcategory)
            this.$emit('addSubcategories', {
              subcategoryId: subcategory
            })
          }
        }
        return
      }
      this.$emit('cleanSubcategories', this.subcategoriesToAdd)
      this.subcategoriesToAdd = []
    }
  },
}
</script>