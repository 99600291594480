<template>
  <div class="container vh-100 d-flex align-items-center justify-content-center">
    <rd-loading />
  </div>
</template>

<script>
import RdLoading from '@/components/rd-components/rd-loading.vue';
import router from '@/router';
import { createUserFromCode } from '@/services/user/user.api.js';
import { mapActions } from 'vuex';
import { getKeycloakLogoutURL } from '../../services/user/user.api';

export default {
  name: 'LoginWithToken',
  components: {
    RdLoading,
  },
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  methods: {
    ...mapActions([
      'loginByToken'
    ]),
    handleRouting() {
      if(this.user.isAdmin || this.user.isUploader || this.user.isTrainer) {
        this.$router.push('/admin');
      } else {
        this.$router.push('/files');
      }
    },
    async handleSetCloakLogoutUrl() {
      try {
        const { data: url } = await getKeycloakLogoutURL();
        this.$store.dispatch('handleKeycloakLogoutURL', url)
      } catch (error) {
        console.log(error)
      }
    },
  },
  async created() {
    try {
      const { code } = this.$route.query;
      if (!code) throw new Error();
      
      const { data } = await createUserFromCode(code);
      if (!data?.main_auth_token) throw new Error();

      const result = await this.loginByToken({ mainAuthToken: data.main_auth_token, refreshToken: data.refresh_token });
      if (result) {
        await this.handleSetCloakLogoutUrl();
        this.handleRouting();
      }
    } catch (_) {
      router.replace('/login');
    }
  },
}
</script>

<style scoped>
</style>
