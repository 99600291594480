<template>
  <img :src="data">
</template>
<script>
export default {
  name: 'RdImageViewer',
  props: {
    data: {
      type: String,
      required: true
    },
  }
}
</script>
<style scoped>
</style>
